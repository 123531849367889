import { useEffect, useState } from "react"

import styled from 'styled-components';
import { NAVBAR_HEIGHT, SECTION_PADDING } from 'components/constants';
import IconNavBarItem from './IconNavBarItem';

import InternalServiceAPI from 'api/User/InternalService/InternalServiceAPI';
// const items = [
//     { text: 'test', image: ''},
//     { text: 'test', image: ''},
//     { text: 'test', image: ''},
//     { text: 'test', image: ''},
// ];
import { useSearchFilter } from "third_party/redux/selectors";
import { useSearchFilterActions } from "third_party/redux/searchFilter/searchFilterActions";

const IconNavBarComponent = (props) => {

    //search filters
    const searchFilters = useSearchFilter()
    const searchFilter = searchFilters.stringFilters.category

    const {FILTER_ON_CATEGORY} = useSearchFilterActions()
  
    const internalApiService = new InternalServiceAPI();

    
    const [servicesAreLoading, setServicesAreLoading] = useState(true)
    const [iconFilters, setIconFilters] = useState([])

    useEffect(() => {
        const loadIconFilters = async () => {
          setServicesAreLoading(true);
    
          // Simulate a 2-second delay
          setTimeout(async () => {
            try {
              const services = await internalApiService.getIconFilters(); // Fetch data asynchronously
              setIconFilters(services);
              setServicesAreLoading(false); // Stop loading after data is fetched

            } catch (error) {
              console.error('Error fetching services:', error);
              setIconFilters([]);
              setServicesAreLoading(false); // Stop loading after data is fetched
            }
          }, 1250); // 2-second delay
        };

        loadIconFilters()
      }, []);

      // const [selectedIconFilter, setSelectedIconFilter] = useState({})
    const handleSelect = (item, index) => {

        console.log("setSelectedIconFilter: ", item)
        FILTER_ON_CATEGORY(item.value)
        // setSelectedIconFilter(item)

    };

    return (
        <Container>
            <DefaultIconContainerBar>
                {iconFilters[0] && (
                    <IconNavBarItem
                    key={0}
                    isSelected={searchFilter === iconFilters[0]?.value}
                    item={iconFilters[0]}
                    onClick={() => handleSelect(iconFilters[0], 0)}
                    />
                )}
            </DefaultIconContainerBar>
            <VerticalLine/>
            <IconContainerBar>
            {iconFilters.slice(1).map((item, index) => (
                <IconNavBarItem key={index + 1} isSelected={searchFilter === item?.value} item={item} onClick={() => handleSelect(item, index + 1)}/>
            ))}
            </IconContainerBar>
        </Container>
        )
}

export default IconNavBarComponent
const Container = styled.div`
position: -webkit-sticky; /* For Safari */
position: sticky;
top: 0px;
display: flex;
flex-direction: row;
max-width:100%;
box-shadow: 0 2px 5px -1px #CCCCCC40;
z-index: 10;
margin-left: 36px;
margin-right: 36px;
background-color: white;
`

const DefaultIconContainerBar = styled.div`
display: flex;
width: auto;
overflow-x: scroll;
flex-wrap:nowrap;
flex-direction: row;
gap: 25px;
background-color: white;
padding: 10px 10px;
// box-shadow: 0 2px 5px -1px #CCCCCC80;

/* Hide scrollbar for Chrome, Safari and Opera */
&::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for Firefox */
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
`
const VerticalLine = styled.div`
  display: flex;
  position: relative;
  width: 1px;
  background-color: #AAA; /* Adjust color as needed */
  height: 60%;
  top: 20%;
  margin: 0 20px; /* Adjust spacing as needed */
`;

const IconContainerBar = styled.div`
display: flex;
width: 100%;
overflow-x: scroll;
flex-wrap:nowrap;
flex-direction: row;
gap: 25px;
background-color: white;
padding: 10px 10px;
// box-shadow: 0 2px 5px -1px #CCCCCC80;

/* Hide scrollbar for Chrome, Safari and Opera */
&::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for Firefox */
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
`
// const IconContainerBar = styled.div`
//     display: flex;
//     width: 100%;
//     overflow-x: auto;  /* Enables horizontal scrolling */
//     white-space: nowrap; /* Prevents wrapping of flex items */
//     gap: 25px;
//     align-items: center; /* Vertically centers the items */
//     background-color: white;
//     box-shadow: 0 2px 5px -1px #CCCCCC80; /* Shadow at the bottom only */
//     padding: 10px; /* Adds padding for spacing */
//     height: auto; /* Ensures the container grows based on its contents */
// `;
