import React from 'react';
import styled from '@emotion/styled';
import PhoneInputContainer from "components/Inputs/PhoneInputContainer";

import { ObjectUserStaging, ObjectUserStagingClass } from 'model/objectUserStaging';


interface SignupPhoneInputProps {
    data?: ObjectUserStaging; // Optional data object
    field?: string; // Optional field name
    onChange: (value: string, field: string) => void; // Required change handler
    placeholder?: string; // Optional placeholder text
  }

  
const SignupPhoneInput = ({ data = new ObjectUserStagingClass(), field ='phone_number' , onChange, placeholder }: SignupPhoneInputProps) => {

    const fieldValue = data.phone_number || '';

    return (
            <PhoneInputContainer
                id={field}
                label=""
                value={fieldValue}
                onChange={(e) => onChange(e.target.value, field)}
                placeholder={placeholder || "Enter Phone Number"}
            />
    )

}

export default SignupPhoneInput
