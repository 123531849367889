import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_REPLACE_ITEMS, CART_TOGGLE_ITEM } from "third_party/redux/actionTypes";
import { useDispatch } from 'react-redux';


//#################################
//#############Actions############
//#################################

//For Add Item to Cart
export const addCartItem = (item) => {
    return {
        type: CART_ADD_ITEM,
        payload : item
    }
}

//For Delete Item to Cart
export const deleteCartItem = (item) => {
    return {
        type: CART_REMOVE_ITEM,
        payload : item
    }
}

//For Delete Item to Cart
export const toggleCartItem = (item) => {
    return {
        type: CART_TOGGLE_ITEM,
        payload : item
    }
}


//For Replace entire Cart to Cart
export const replaceCartItems = (items) => {
    return {
        type: CART_REPLACE_ITEMS,
        payload : items
    }
}





//=============================
// Custom hook for cart actions
//=============================
export const useCartActions = () => {
    const dispatch = useDispatch();

    const CART_ADD = (item) => {
        dispatch(addCartItem(item));
    };

    const CART_DELETE = (item) => {
        dispatch(deleteCartItem(item));
    };

    const CART_TOGGLE = (item) => {
        dispatch(toggleCartItem(item));
    };

    const CART_REPLACE = (items) => {
        dispatch(replaceCartItems(items));
    };

    return {
        CART_ADD,
        CART_DELETE,
        CART_TOGGLE,
        CART_REPLACE
    };
};