import React, { createContext, useContext, useState } from 'react';

// Create a context
const AuthContext = createContext();

// Export a custom hook to access the auth context
export const useAuthContext = () => useContext(AuthContext);

// AuthProvider component that wraps your app and provides the auth state
export const AuthProvider = ({ children }) => {
  
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginType, setLoginType] = useState('');
  const [resetAllContexts, setResetAllContexts] = useState(false);

  const loggedInWithAddress = (address) => {

    // setUser({ address }); useHook is connected to joint
    setIsLoggedIn(true)
    setLoginType('address')
  }

  const loggedInWithCredentials = () => {

    //handle the authentication in UserContext
    setIsLoggedIn(true);
    setLoginType('credentials');
  }

  // Login and logout functions that modify the isLoggedIn state (Convience)
  const logout = () => {
    setIsLoggedIn(false)
    setLoginType('');
  }

  const resetGlobalContext = () =>{
    console.log("resetGlobalContext TRIGGERED")
    logout()
    setResetAllContexts(true)
  }

  return (
    <AuthContext.Provider value={{ loggedInWithAddress,loggedInWithCredentials, isLoggedIn, loginType, logout, resetAllContexts, resetGlobalContext }}>
      {children}
    </AuthContext.Provider>
  );
};