import { FILTER_BY_CATEGORY, FILTER_RESET } from "third_party/redux/actionTypes";
import { useDispatch } from 'react-redux';


//#################################
//#############Actions############
//#################################
//For update category filter
export const updateFilterCategory = (item) => {
    return {
        type: FILTER_BY_CATEGORY,
        payload : item
    }
}

//For update category filter
export const resetFilters = (item) => {
    return {
        type: FILTER_RESET,
        payload : item
    }
}

//=============================
// Custom hook for cart actions
//=============================
export const useSearchFilterActions = () => {
    const dispatch = useDispatch();

    const FILTER_ON_CATEGORY = (item) => {
        dispatch(updateFilterCategory(item));
    };

    const FILTER_RESET = (item) => {
        dispatch(resetFilters(item));
    };

    return {
        FILTER_ON_CATEGORY,
        FILTER_RESET,
    };
};
