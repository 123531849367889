// import styled from 'styled-components';
import styled, { css } from 'styled-components';


const MenuItem = ({ title, description, link, enabled }) => (
  <MenuItemsContainer as={link ? 'a' : 'div'} href={link} $enabled={enabled}>
    <h2>{title}</h2>
    {description && <p>{description}</p>}
  </MenuItemsContainer>
);

export default MenuItem;


// const MenuItemsContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   cursor: ${(props) => (props.enabled ? 'pointer' : 'default')};
//   border-bottom: 1px solid black;
//   flex: 0 0 calc(50% - 50px);

//   &:hover {
//     opacity: ${(props) => (props.enabled ? '0.60' : '1.0')};
//   }

//   h1 {
//     font-size: 2.5em;
//     font-weight: 700;
//     color: black;
//     margin-bottom: 0px;
//   }

//   p {
//     color: #00000060;
//   }

//   @media (max-width: 768px) {
//     flex: 0 0 100%;
//   }
// `;

const MenuItemsContainer = styled.div`
display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
  flex: 0 0 calc(50% - 50px);
  padding-bottom: 1em;

  ${(props) =>
    props.$enabled
      ? css`
          cursor: pointer;

          &:hover {
            opacity: 0.60;
          }

          h1 {
            color: black;
          }

          p {
            color: #00000060;
          }
        `
      : css`
          cursor: default;

          &:hover {
            opacity: 1.0;
          }

          h1 {
            color: #ccc;
          }

          p {
            color: #ccc;
          }
        `}

  h1 {
    font-size: 2.5em;
    font-weight: 700;
    margin-bottom: 0px;
    margin-bottom: 1em;
  }

  p {
    /* Default color, overridden by enabled/disabled styles */
    margin: 0px;
  }

  @media (max-width: 768px) {
    flex: 0 0 100%;
  }
`;




