import React, {useState} from 'react';
import styled from '@emotion/styled';

// import {useState, useEffect, useRef } from "react";
// import { useNavigate, Link } from 'react-router-dom';

import { NAVBAR_HEIGHT } from "components/constants";

//Contexts
import { useScreenContext } from 'context/AppContext/ScreenContext';

//input container
import InputContainer from 'components/Containers/InputContainer';

import SignupPhoneInput from 'components/Form/SignupPhoneInput';
import SignupPhoneAccessCode from 'components/Form/SignupPhoneAccessCode';
import SignupBusinessInfo from 'components/Form/SignupBusinessInfo';

//Styles
import {buttonRoundCloseStyle, buttonSubmitStyle} from 'components/Buttons/ButtonStyles'
import { textFieldStyle } from "components/Inputs/InputStyles";

//API
import SignupServiceAPI from 'api/Business/SignupService/SignupServiceAPI';

//class data
import { ObjectUserStaging, ObjectUserStagingClass } from 'model/objectUserStaging';
import PopUpNotification from 'components/Popups/PopUpNotification';

//Text FIelds
import TextField from '@mui/material/TextField';
import PasswordTextField from "components/Inputs/PasswordTextField";
import PhoneInputContainer from "components/Inputs/PhoneInputContainer";


interface StyledProps {
    gap?: string;
  }
const SignupWithEmail = () => {

//API
const signupService = new SignupServiceAPI();

//Screen Size
const {isMobile, isMedium, isLarge} = useScreenContext()

//Data
const [formData, setFormData] = useState<ObjectUserStaging>({});

//States
const [screen, setScreen] = useState(0)
const [error, setError] = useState<string | null>(null);
const [showNotification, setShowNotification] = useState(false);


//Form Values
const signUpPage: { [key: number]: { page: string; header: string; footer: string; submit: string } } = {
    0: {
        page: "Start",
        header: "Welcome to the Signup Process",
        footer: "Please follow the steps to create your account.",
        submit:"Continue",
    },
    1: {
        page: "Email",
        header: "Enter your email",
        footer: "We'll send a verification link to your email with your access code.",
        submit:"Continue",
    },
    2: {
        page: "Access Code",
        header: "Enter your access code",
        footer: "",
        submit:"Continue",
    },
    3: {
        page: "Info",
        header: "Provide your information",
        footer: "By proceeding, you consent to get calls, WhatsApp or SMS/RCS messages, including by automated dialer, from Uber and its affiliates to the number provided. Text “STOP” to 89203 to opt out.",
        submit:"Create Account",
    },
    4: {
        page: "Success",
        header: "Signup successful!",
        footer: "You can now log in and start using the service.",
        submit:"Continue to Login",
    },
};

const numberOfPages = Object.keys(signUpPage).length;



//handle error popup
const handleClosePopup = () => {
    setError(null);  // Close the popup by clearing the error state
  };

//Handle Back Button Pressed
const handlePrevious = () => {
    setScreen(prevScreen => prevScreen - 1);
    
    console.log('going BACK to ',signUpPage[screen - 1], ' page');
}

//Handle Next Button Pressed
const handleInputChange = (value: string, key: string) => {
    console.log(`Field: ${key}, Value: ${value}`);
    // You can add more logic here, like updating state based on the field name
    // Create a copy of formData as a new instance of ObjectUserStagingClass
    setFormData((prevData) => ({
        ...prevData,
        [key]: value, // Use computed property names to update the specific key
    }));
};
  const handleNext = async() => {
    console.log("Do Something: If success, screen + 1")
    if (screen + 1 == numberOfPages){
        finishSignUpProcess()
        return 
    }
    console.log('going FORWARD to ',signUpPage[screen + 1], ' page');
    setScreen(prevScreen => prevScreen + 1);
  }

  const finishSignUpProcess = async() => {
    console.log("finish signup and login")
    //TODO Add login or something function
  }

  return (
    <>
    <SignUpContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
        <Header>
            <HeaderFlexItem>
                {screen > 0 && screen < numberOfPages - 1 &&(
                    <HeaderButtonBack onClick={handlePrevious}>←</HeaderButtonBack>
                )}
            </HeaderFlexItem>
            <HeaderFlexItem>
                <HeaderLogoContainer>
                    <img src='/images/cta-logo-one.svg' alt="GoghNow" />
                </HeaderLogoContainer>
            </HeaderFlexItem>
            <HeaderFlexItem>

            </HeaderFlexItem>
        </Header>
        <Content>
            
            {signUpPage[screen].page == "Start" &&(
                <>
                    <p>{signUpPage[screen].header}</p>
                    <NextButton onClick={handleNext}>{screen < numberOfPages - 1 ? 'Next' : 'Go to Dashboard'}</NextButton>
                    <FooterText>{signUpPage[screen].footer}</FooterText>
                </>
            )}
            {signUpPage[screen].page == "Email" &&(
                <>
                    <HeaderText>{signUpPage[screen].header}</HeaderText>
                    <TextFieldCustom
                        id="email"
                        label="Enter email"
                        value={formData.email || ''}
                        onChange={(e) => handleInputChange(e.target.value, 'email')}
                        placeholder={"Email"}
                        sx = {textFieldStyle}
                    />
                    <NextButton onClick={handleNext}>{screen < numberOfPages - 1 ? 'Next' : 'Go to Dashboard'}</NextButton>
                    <FooterText>{signUpPage[screen].footer}</FooterText>
                </>
            )}
            {signUpPage[screen].page == "Access Code" &&(
                <>
                    <HeaderText>{signUpPage[screen].header}</HeaderText>
                    <TextFieldCustom
                        id="access_code"
                        label="Enter your access code"
                        value={formData.access_code || ''}
                        onChange={(e) => handleInputChange(e.target.value, 'access_code')}
                        placeholder={"Access Code"}
                        sx = {textFieldStyle}
                    />
                    <NextButton onClick={handleNext}>{screen < numberOfPages - 1 ? 'Next' : 'Go to Dashboard'}</NextButton>
                    <FooterText>{signUpPage[screen].footer}</FooterText>
                </>
            )}
            {signUpPage[screen].page == "Info" &&(
                <>
                    <HeaderText>{signUpPage[screen].header}</HeaderText>
                    <Row>
                        <TextFieldCustom
                            id="first_name"
                            label="First Name"
                            value={formData.first_name || ''}
                            onChange={(e) => handleInputChange(e.target.value, 'first_name')}
                            placeholder={"First Name"}
                            sx = {textFieldStyle}
                        />
                        <TextFieldCustom
                            id="last_name"
                            label="Last Name"
                            value={formData.last_name || ''}
                            onChange={(e) => handleInputChange(e.target.value, 'last_name')}
                            placeholder={"Last Name"}
                            sx = {textFieldStyle}
                        />
                    </Row>
                    <PhoneInputContainer
                        id="phone"
                        label="Phone"
                        value={formData.phone || ''}
                        onChange={(e) => handleInputChange(e.target.value, 'phone')}
                        placeholder={"Enter Phone Number"}
                        sx = {textFieldStyle}
                    />
                    <PasswordTextFieldCustom
                                id="password"
                                label="Password"
                                value={formData.password || ''}
                                onChange={(e) => handleInputChange(e.target.value, 'password')}
                                sx = {textFieldStyle}
                                placeholder={"Password"}
                                
                                // margin="normal"
                                // required
                    />
                    <NextButton onClick={handleNext}>{screen < numberOfPages - 1 ? 'Next' : 'Go to Dashboard'}</NextButton>
                    <FooterText>{signUpPage[screen].footer}</FooterText>
                </>
            )}
            {signUpPage[screen].page == "Success" &&(
                <p>{signUpPage[screen].header}</p>
            )}
        
            
        </Content>

    </SignUpContainer>


            {/* Conditionally render the popup when there is an error */}
            {error && (
            <PopUpNotification
            titleText="Error"
            descriptionText={error}
            buttonText="Close"
            buttonAction={handleClosePopup}  // Close popup on button click
            />
        )}
    </>
  )

}
 export default SignupWithEmail


const SignUpContainer = styled(InputContainer)`

    max-width: 560px;

`

//===============HEADER CONTENT
//Container
const Header = styled.div`
display: flex;
width: 100%;
height: ${NAVBAR_HEIGHT};
`
const HeaderFlexItem = styled.div`
  flex: 1;
  max-width: 33.3%;
  display: flex;
  justify-content: center;
  align-items: center;

    &:nth-of-type(1) {
    justify-content: start;
  }
`

const HeaderText = styled.p`

`

const FooterText = styled.p`
font-size: .75em;
color: #00000040;
`

//Back BUtton
const HeaderButtonBack = styled.button`
${buttonRoundCloseStyle}
`
//HeaderLogoContainer
const HeaderLogoContainer = styled.a`
padding: 0px;
width: 150px;
margin-top: 36px;
margin-bottom: 36px;
max-height: 70px;
font-size: 0px;
display: inline-block;

img {
    display: block;
    width:100%;
}
`

//==============CONTENT
const Content = styled.div`
    width: 100%;
    p{
        color: black;
    }
`

const TextFieldCustom = styled(TextField)`
width: 100%;
flex: 1;
`

const PasswordTextFieldCustom = styled(PasswordTextField)`
width: 100%;
flex: 1;
`
const Row = styled.div<StyledProps>`
display: flex;
flex-direction: row;
width: 100%;
gap: 2; /* Use the gap prop here */
`


//==============SUBMIT
const NextButton = styled.button`
${buttonSubmitStyle}
margin-top: 10px;
width: 100%;
margin-top: 20px;
`


