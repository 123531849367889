
import React, { createContext, useState, useContext, useEffect } from 'react';

//ADDED
import config from 'config'
import { Loader } from '@googlemaps/js-api-loader';




// useEffect(() => {
//   GoogleMapsFunctions.loadGoogleMapsApi().then(() => {
//     console.log('Google Maps API loaded successfully');
//     setIsApiLoaded(true);
//   }).catch(error => {
//     console.error('Failed to load Google Maps API', error);
//   });
// }, []);

// const [isApiLoaded, setIsApiLoaded] = useState(false);
// const [searchResults, setSearchResults] = useState([]);
// //   const [selectedPlace, setSelectedPlace] = useState(null);

class GoogleMapsFunctions{

  // Replace with your actual API key import if necessary
static apiKey = config.googlePlacesApiKey;
static mapApiJs = 'https://maps.googleapis.com/maps/api/js';
static geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';
  // const [isApiLoaded, setIsApiLoaded] = useState(false);
// const [searchResults, setSearchResults] = useState([]);
//   const [selectedPlace, setSelectedPlace] = useState(null);

  static isApiLoaded = false;

  static predictedResults = []; // Initialize static property

  // static loadGoogleMapsApi = () => {
  //   const loader = new Loader({
  //     apiKey: apiKey,
  //     version: 'weekly',
  //     libraries: ['places'],
  //   });
  
  //   return loader.load();
  // };

  static loadGoogleMapsApi = async () => {
    if (!this.isApiLoaded) {
      const loader = new Loader({
        apiKey: this.apiKey,
        version: 'weekly',
        libraries: ['places'],
      });

      try {
        await loader.load();
        this.isApiLoaded = true; // Set the flag to true once the API is loaded
      } catch (error) {
        console.error('Failed to load Google Maps API', error);
        throw error; // Make sure to handle or re-throw the error as needed
      }
    }
  };


  //==================================================================
  // FUNCTIONS INTO THE JOINT
  //=============================================================
  // static getAddressFromPlace = (place) => {
  static extractAddress = (place) => {
    const address = {
      streetNumber: '',
      route: '',
      locality: '',
      adminArea1: '',
      postalCode: '',
      country: '',
      lat:'',
      lng:'',
    };
  
    if (!Array.isArray(place?.address_components)) {
      return address;
    }
  
    place.address_components.forEach((component) => {
      const types = component.types;
      const item_value = component.short_name;
      if (types.includes('street_number')) {
        address.streetNumber = item_value;
      }
      if (types.includes('route')) {
        address.route = item_value;
      }
      if (types.includes('locality')) {
        address.locality = item_value;
      }
      if (types.includes('administrative_area_level_1')) {
        address.adminArea1 = item_value;
      }
      if (types.includes('postal_code')) {
        address.postalCode = item_value;
      }
      if (types.includes('country')) {
        address.country = item_value;
      }
    });
  
    if (place.geometry && place.geometry.location) {
      address.lat = place.geometry.location.lat();
      address.lng = place.geometry.location.lng();
    }
  
    return address;
  };
  
  //KEEP ALL OF THESE BELOW *JUST CREATED*
  //========================================

  //get place from address
  static getPlaceFromAddress = async ({address}) => {
    console.log("FUNCTION: getPlaceFromAddress: ",address);
    if(address) {
      try{
        console.log("...Waiting for response from returnPlaceFromAddress");
        const place = await this.returnPlaceFromAddress({address})
        console.log("place being returned is: ",place);
        return place;
      }
      catch(error){
        console.error('Error fetching place details:', error);
        throw error;
      }
    }
    else{
      console.log("ERROR");
      throw new Error('no results found');
    }
  }

  //get place from coordinates
  static getPlaceFromCoordinates = async ({ lat, lng }) => {
    if (lat && lng) {
      try {
        const result = await this.reverseGeocode({ lat, lng });
        return result;
      } catch (error) {
        console.error('Error during reverse geocoding:', error);
        throw error;
      }
    } else {
      console.error('No valid address or coordinates provided');
      throw new Error('No valid address or coordinates provided');
    }
  };

  //reverseGeocode
  static reverseGeocode = ({ lat,lng }) => {
    const url = `${this.geocodeJson}?key=${this.apiKey}&latlng=${lat},${lng}`;
    return fetch(url)
        .then(response => response.json())
        .then(location => {
            const place = location.results[0];
            const _address = this.extractAddress(place);
            return _address;
        })
        .catch(error => {
            console.error('Error during reverse geocoding:', error);
            throw error;
        });
  };

  //getPlace from address or coordinates
  static getPlaceFromAddressOrCoordinates = async ({ address, lat, lng }) => {
    if (address) {
      try{
      const place = await this.returnPlaceFromAddress({ address });
      return place
      }
      catch(error){
        console.error('Error during address lookup:', error);
        throw error;
      }
    } else if (lat && lng) {
      try{
      const result = await this.reverseGeocode({ lat, lng });
        return result
      }
      catch(error){
        console.error('Error during reverse geocoding:', error);
        throw error;
      }
    } else {
      console.error('No valid address or coordinates provided');
      throw new Error('No valid address or coordinates provided');
    }
  };
  
  
  
  // export const returnPlaceFromAddress = (address, onPlaceSelected) => {
  //   const service = new window.google.maps.places.AutocompleteService();
  //   service.getPlacePredictions({ input: address }, (predictions, status) => {
  //     if (status !== window.google.maps.places.PlacesServiceStatus.OK || !predictions || predictions.length === 0) {
  //       console.error('Autocomplete service failed or no predictions found');
  //       return;
  //     }
  //     const firstPrediction = predictions[0];
  //     const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
  //     placesService.getDetails({ placeId: firstPrediction.place_id }, (place, status) => {
  //       if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
  //         console.error('Place details request failed');
  //         return;
  //       }
  //       const addressObject = extractAddress(place);
  //       console.log("onPlaceSelected",onPlaceSelected)
  
  //       if (onPlaceSelected) {
  //         onPlaceSelected(addressObject);
  //       }
  //     });
  //   });
  // };
  





  //if there is a callback function 'onPlaceSelected' then it goes to the function
  // static returnPlaceFromAddress = async ({ address }) => {
  //   // Ensure the Google Maps API is loaded
  //   console.log('returnPlaceFromAddress!')
  //   if (!this.isApiLoaded) {
  //     console.log("API IS NOT LOADED!")
  //     try {
  //       console.log("LOADING API")
  //       await this.loadGoogleMapsApi();
  //     } catch (error) {
  //       console.error('Failed to load Google Maps API', error);
  //       return null;
  //     }
  //   }
  
  //   console.log("CHECKING IF GOOGLE MAPS API IS AVAILABLE")
  //   // Check if Google Maps API is available
  //   if (!window.google || !window.google.maps || !window.google.maps.places) {
  //     console.error('Google Maps API is not loaded yet');
  //     return null;
  //   }
    
  //   console.log("NEW PROMISEß")
  //   return new Promise((resolve, reject) => {
  //     const service = new window.google.maps.places.AutocompleteService();
  //     console.log("service created")
  //     service.getPlacePredictions({ input: address }, (predictions, status) => {
  //       if (status !== window.google.maps.places.PlacesServiceStatus.OK || !predictions || predictions.length === 0) {
  //         console.error('Autocomplete service failed or no predictions found');
  //         return reject('No predictions found');
  //       }
        
  //       const firstPrediction = predictions[0];
  //       const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
  //       placesService.getDetails({ placeId: firstPrediction.place_id }, (place, status) => {
  //         if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
  //           console.error('Place details request failed');
  //           return reject('Place details request failed');
  //         }
  
  //         const addressObject = this.extractAddress(place);
  //         resolve(addressObject); // Return the address object
  //       });
  //     });
  //   });
  // }
  
  static returnPlaceFromAddress = async ({ address }) => {
    console.log('returnPlaceFromAddress!');
    
    // Ensure the Google Maps API is loaded
    if (!this.isApiLoaded) {
      console.log("API is not loaded!");
      try {
        console.log("Loading API...");
        await this.loadGoogleMapsApi();
      } catch (error) {
        console.error('Failed to load Google Maps API', error);
        return null;
      }
    }
  
    // Check if Google Maps API is available
    console.log("Checking if Google Maps API is available...");
    if (!window.google || !window.google.maps || !window.google.maps.places) {
      console.error('Google Maps API is not loaded yet');
      return null;
    }
    console.log('Address here is: ',address)
    return new Promise((resolve, reject) => {
      const service = new window.google.maps.places.AutocompleteService();
      console.log("AutocompleteService created");
  
      //Validate input
      if (!address || address.trim() === '') {
        console.error('No address provided to AutocompleteService');
        return reject('No address provided');
      }

      service.getPlacePredictions({ input: address }, (predictions, status) => {
        console.log("getPlacePredictions called, status:", status);
        console.log("Predictions:", predictions);
  
        if (status !== window.google.maps.places.PlacesServiceStatus.OK || !predictions || predictions.length === 0) {
          console.error('Autocomplete service failed or no predictions found, status:', status);
          return reject('No predictions found');
        }
  
        const firstPrediction = predictions[0];
        console.log("First Prediction:", firstPrediction);
        
        const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
        console.log("Fetching place details for:", firstPrediction.place_id);
  
        placesService.getDetails({ placeId: firstPrediction.place_id }, (place, status) => {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
            console.error('Place details request failed, status:', status);
            return reject('Place details request failed');
          }
  
          const addressObject = this.extractAddress(place);
          console.log("Resolved address object:", addressObject);
          resolve(addressObject); // Resolve with the address object
        });
      });
    });
  }
  







  // static returnPlaceFromAddress = async({address}, onPlaceSelectedHandler) => {
  
  //   if (!this.isApiLoaded) {
  //     try {
  //       await this.loadGoogleMapsApi();
  //     } catch (error) {
  //       console.error('Failed to load Google Maps API', error);
  //       return;
  //     }
  //   }
  
  
  //   if (!window.google || !window.google.maps || !window.google.maps.places) {
  //     console.error('Google Maps API is not loaded yet');
  //     return;
  //   }
  //   const service = new window.google.maps.places.AutocompleteService();
  //   service.getPlacePredictions({ input: address }, (predictions, status) => {
  //     if (status !== window.google.maps.places.PlacesServiceStatus.OK || !predictions || predictions.length === 0) {
  //       console.error('Autocomplete service failed or no predictions found');
  //       return;
  //     }
  //     const firstPrediction = predictions[0];
  //     const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
  //     placesService.getDetails({ placeId: firstPrediction.place_id }, (place, status) => {
  //       if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
  //         console.error('Place details request failed');
  //         return;
  //       }
  //       const addressObject = this.extractAddress(place);
  //       if (onPlaceSelectedHandler) {
  //         onPlaceSelectedHandler(addressObject);
  //       }
  //     });
  //   });
  // };
  
  static addressFromPrediction = (prediction) => {
    const line1 = prediction.line1 || ''
    const line2 = prediction.line2 || ''
  
    return line1 + ',' + line2
  }





  //=============================================
  //==============END OF FUNCTIONS================

  static formatPlaceDetails = (placeDetailsList) => {
    return placeDetailsList.map((placeDetails) => {
      const { structured_formatting } = placeDetails;
      if (structured_formatting) {
        return {
          line1: structured_formatting.main_text,
          line2: structured_formatting.secondary_text,
        };
      } else {
        return { line1: placeDetails.description, line2: null };
      }
    });
  };

  //Using fetchPredictions
  //  const predictions = await ClassName.fetchPredictions(inputValue);


  static fetchPredictions = (inputValue) => {
    if (!this.isApiLoaded) {
      console.error('Google Maps API is not loaded yet');
      return [];
    }
  
    const autocompleteService = new window.google.maps.places.AutocompleteService();
  
    return new Promise((resolve, reject) => {
      autocompleteService.getPlacePredictions({ input: inputValue }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const formattedPredictions = this.formatPlaceDetails(predictions);
          this.predictedResults = formattedPredictions
          resolve(formattedPredictions); // Return the formatted predictions
        } else {
          this.predictedResults = []
          resolve([]); // Return an empty array if no predictions found

        }
      });
    });
  };

  static getPredictedResults = () => {
    return this.predictedResults; // Access the current value of predictedResults
  }
  
  // static fetchPredictions = (inputValue) => {
  //   if (!isApiLoaded) {
  //     console.error('Google Maps API is not loaded yet');
  //     return;
  //   }
  //   const autocompleteService = new window.google.maps.places.AutocompleteService();
    
  //   autocompleteService.getPlacePredictions({ input: inputValue }, (predictions, status) => {
  //     if (status === window.google.maps.places.PlacesServiceStatus.OK) {
  //       const formattedPredictions = formatPlaceDetails(predictions);
  //       setSearchResults(formattedPredictions);
  //     } else {
  //       setSearchResults([]);
  //     }
  //     console.log('searchPredictions:', searchResults);
  //   });
  // };


}

export default GoogleMapsFunctions