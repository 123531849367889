import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InputContainer from "components/Inputs/InputContainer"

const TextInputContainer = ({ id, label, value, onChange, placeholder }) => {
    return (
        <InputContainer>
            {label && <Label htmlFor={id}>{label}</Label>}
            <Input
                type="text"
                id={id}
                value={value || ""}
                onChange={onChange}
                placeholder={placeholder}
            />
        </InputContainer>
    );
};

TextInputContainer.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.string,//.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
};

export default TextInputContainer;

// const InputContainer = styled.div`
//     margin-bottom: 15px;
// `;

const Label = styled.label`
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #CCCCCC;
`;

const Input = styled.input`
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
`;