export class BaseModel {
    protected _data: Record<string, any> = {};
    
    constructor(data: Record<string, any> = {}) {
      this.update(data); // Initialize with data
    }
    
    // Allow dynamic property access
    [key: string]: any;
  
    // GETTER (like __getattr__)
    getAttr(key: string): any {
      if (!(key in this)) {
        console.warn(`Attribute '${key}' not found in 'BaseModel' object; returning undefined.`);
      }
      return this[key]; // Access property directly
    }
    
    // SETTER (like __setattr__)
    setAttr(key: string, value: any): void {
      this[key] = value; // Set property directly
    }
    
    // UPDATE METHOD
    update(data: Partial<Record<string, any>>): void {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          this.setAttr(key, data[key]);
        }
      }
    }
    
    // ITERATOR
    *[Symbol.iterator]() {
      for (const value of Object.values(this)) { // Iterate over instance properties
        yield value;
      }
    }
    
    // SERIALIZE TO DICTIONARY (to_dict equivalent)
    toDict(): Record<string, any> {
      const serializedData: Record<string, any> = {};
      
      for (const key of Object.keys(this)) {
        const value = this[key]; // Access property directly

        if (value instanceof BaseModel) {
          serializedData[key] = value.toDict();
        } else if (Array.isArray(value)) {
          serializedData[key] = value.map(item => item instanceof BaseModel ? item.toDict() : item);
        } else if (value instanceof Date) {
            serializedData[key] = value//value.toISOString(); // Serialize Date to ISO string      
        } else if (typeof value === 'object' && value !== null) {
          serializedData[key] = this.serializeObject(value);
        } else {
          serializedData[key] = value;
        }
      }

      
      return serializedData;
    }
    
    // Helper to serialize nested objects
    private serializeObject(obj: Record<string, any>): Record<string, any> {
      const serialized: Record<string, any> = {};
      for (const [key, value] of Object.entries(obj)) {
        serialized[key] = value instanceof BaseModel ? value.toDict() : value;
      }
      return serialized;
    }
  }
  
  
  
// export class BaseModel {
//     protected _data: Record<string, any> = {};
  
//     constructor(data: Record<string, any> = {}) {
//       this._data = data;
//     }
  
//     // GETTER (like __getattr__)
//     getAttr(key: string): any {
//       if (!(key in this._data)) {
//         console.warn(`Attribute '${key}' not found in 'BaseModel' object; returning undefined.`);
//       }
//       return this._data[key];
//     }
  
//     // SETTER (like __setattr__)
//     setAttr(key: string, value: any): void {
//       this._data[key] = value;
//     }

//     // UPDATE METHOD
//     update(data: Partial<Record<string, any>>): void {
//         for (const key in data) {
//             if (data.hasOwnProperty(key)) {
//                 this.setAttr(key, data[key]);
//             }
//         }
//     }
  
//     // ITERATOR
//     *[Symbol.iterator]() {
//       for (const value of Object.values(this._data)) {
//         yield value;
//       }
//     }
  
//     // SERIALIZE TO DICTIONARY (to_dict equivalent)
//     toDict(): Record<string, any> {
//       const serializedData: Record<string, any> = {};
      
//       for (const [key, value] of Object.entries(this._data)) {
//         if (value instanceof BaseModel) {
//           serializedData[key] = value.toDict();
//         } else if (Array.isArray(value)) {
//           serializedData[key] = value.map(item => item instanceof BaseModel ? item.toDict() : item);
//         } else if (typeof value === 'object' && value !== null) {
//           serializedData[key] = this.serializeObject(value);
//         } else {
//           serializedData[key] = value;
//         }
//       }
      
//       return serializedData;
//     }
  
//     // Helper to serialize nested objects
//     private serializeObject(obj: Record<string, any>): Record<string, any> {
//       const serialized: Record<string, any> = {};
//       for (const [key, value] of Object.entries(obj)) {
//         serialized[key] = value instanceof BaseModel ? value.toDict() : value;
//       }
//       return serialized;
//     }
//   }
  