
import SearchFunctions from "utility/searchFunctions";
import { ObjectSearchCriteriaClass } from "./objectSearchCriteria";
import { BaseModel } from "model/base";




export interface objectSearch {
  current: ObjectSearchCriteriaClass;  // This should use ObjectSearchCriteriaClass
  temp: ObjectSearchCriteriaClass;     // Same here
  results: any[];                      // Define the results type properly, using any[] for now
}

export class ObjectSearchClass extends BaseModel implements objectSearch {
  current: ObjectSearchCriteriaClass;  // Correct class reference here
  temp: ObjectSearchCriteriaClass;
  results: any[];

  constructor(data: Partial<objectSearch> = {}) {
    super(data); // Call the constructor of BaseModel with 'data'
    this.current = data.current || new ObjectSearchCriteriaClass();
    this.temp = data.temp || new ObjectSearchCriteriaClass();
    this.results = data.results || [];  // Default empty array for results
  }
}