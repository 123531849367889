import { useDispatch } from "react-redux";
import {AUTH_LOGIN_GUEST, AUTH_LOGIN_USER, AUTH_LOGOUT} from "third_party/redux/actionTypes";


export const authLoginGuest = (user_type) => {
    return {
        type:AUTH_LOGIN_GUEST,
        action: user_type
    }
}

export const authLoginUser = (idandtoken) => {
    return {
        type:AUTH_LOGIN_GUEST,
        action: idandtoken
    }
}

export const authLogout = () => {
    return {
        type:AUTH_LOGOUT,
    }
}


//=============================
// Custom hook for cart actions
//=============================
export const useAuthActions = () => {
    const dispatch = useDispatch();

    const AUTH_LOGIN_GUEST = (item) => {
        dispatch(authLoginGuest(item));
    };

    const AUTH_LOGIN_USER = (idandtoken) => {
        dispatch(authLoginUser(idandtoken));
    };

    const AUTH_LOGOUT = () => {
        dispatch(authLogout());
    };

    return {
        AUTH_LOGIN_GUEST,
        AUTH_LOGIN_USER,
        AUTH_LOGOUT,
    };
};