import styled from 'styled-components';
import { useState, useRef } from "react"

import TextInputContainer from 'components/Inputs/TextInputContainer';


import TextField from '@mui/material/TextField';

const SignUpComponent = ({handleSubmit, isSubmitting, status, ...props}) => {

    const [error, setError] = useState(false);
    const [data , setData] = useState({email: ''});
    const formRef = useRef(null)

    const handleInputChange = (e, newValue) => {
        const { id, value } = e.target;
        const newValueOrEvent = newValue !== undefined ? newValue : value;

        setData((prevState) => ({
                ...prevState,
                [id]: newValueOrEvent,
            }));
    }

    const onSubmit = (event) => {
        setError(false); // Set error state to true
        event.preventDefault();
        const isFormValid = formRef.current.checkValidity();

        if (isFormValid) {
        // Do something when the form is valid
        // console.log('Form is valid! Submitting...');
        handleSubmit(data);

        } else {
        // Do something when the form is not valid
        console.log('Form is not valid. Please check your inputs.');
        setError(true); // Set error state to true

        }
    };


    // console.log("CHECKOUTLOCATION Info: ", user)
    return (
        // <InternalForm>
        <FlexContainer {...props}>
        <Container>
                        <form id="FormID" ref={formRef} onSubmit={onSubmit}>
                        <UpdatedTextField
                            id='email'
                            label="Email"
                            type="email"
                            variant="outlined"
                            value={data.email}
                            onChange={handleInputChange}
                            // placeholder='example@goghnow.com'
                            error={error}
                            helperText={error ? "Please enter a valid email address" : ""}
                            fullWidth
                            size="small"
                            margin="normal"
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        </form>

           
            <button onClick={onSubmit} type="submit">→</button>
        </Container>
        {isSubmitting && <p>submitting...</p>}
        {!isSubmitting && <p>{status}</p>}
        </FlexContainer>

     );
    };
export default SignUpComponent;

const FlexContainer = styled.div`
display: flex;
flex-direction: column;
// border: solid 1px orange;
margin: 15px;
// padding: 10px 30px;
p{
color: #00000060;
margin: 0px;
}
`

const Container = styled.div`
display: flex;
flex-direction: row;
width: auto;
width: 100%;
align-items: center;
justify-content: center;

// display: flex;
// flex-direction: column;
// flex-grow:1;
// justify-content: center;
`

const UpdatedTextField = styled(TextField)`
    width: 100%;
    // background-color: red;
`

// const InternalForm = styled.div`
// display: flex;
// flex-direction: row;
// border: solid 1px orange;
// width: auto;
// margin: 15px;
// padding: 10px 30px;
// width: 100%;
// align-items: center;
// justify-content: center;
// `