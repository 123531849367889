import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

// import config from 'config'

import GoogleMapsFunctions from 'utility/googleMapsFunctions';


//SHOULD BE LOCATION SHIT LATER
import SearchFunctions from 'utility/searchFunctions';

export const GoogleMapsAddressInput = forwardRef(({
  // onSearchSubmit,
  onPlaceSelected,
  onFocus,
  onChange,
  onBlur,
  value,
  className,
  disableAutoComplete = false,
  placeholder = "Enter event address",
  id = 'search',
  required = false,
  ...props

},ref) => {

  //OR JUST EXPORT FROM THE CONTEXT
//loadGoogleMapsApi = useGoogleMapsAddressContext()
  //added
  const internalSearchInputRef = useRef(null);
  // Use external ref if provided, else fallback to internal ref
  useImperativeHandle(ref, () => internalSearchInputRef.current);


  const [searchAddress, setSearchAddress] = useState(value || '');

  const [isFocused, setIsFocused] = useState(false); //new state to track focus

  const clearButtonRef = useRef(null);

  useEffect(() => {

    const onChangeAddress = (autocomplete) => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        console.log("No details available for input: '" + place.name + "'");
        return;
      }
      const addressObject = GoogleMapsFunctions.extractAddress(place);
      const formattedAddress = SearchFunctions.getLine1FromPlace(addressObject) + SearchFunctions.getLine2FromPlace(addressObject);
      setSearchAddress(formattedAddress);
      if (onPlaceSelected) {
        onPlaceSelected(addressObject);
      }
    };


    const initAutocomplete = () => {
      if (!internalSearchInputRef.current || disableAutoComplete) return;

      const autocomplete = new window.google.maps.places.Autocomplete(internalSearchInputRef.current);
      autocomplete.setFields(['address_component', 'geometry']);
      autocomplete.addListener('place_changed', () => onChangeAddress(autocomplete));
    };

    GoogleMapsFunctions.loadGoogleMapsApi()
      .then(initAutocomplete)
      .catch(error => {
        console.error('Failed to load Google Maps API:', error);
      });
  }, [disableAutoComplete]);

  useEffect(() => {
    setSearchAddress(value);
  }, [value]);


  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchAddress(inputValue);
    console.log("input: ",inputValue)
    // GoogleMapsFunctions.fetchPredictions(inputValue) UNDO
    console.log("onChange: ",onChange)
    onChange && onChange(inputValue);
  };

  const handleClear = (e) => {
    e.preventDefault();
    console.log("input cleared")
    setSearchAddress('');
    if (internalSearchInputRef.current) {
      internalSearchInputRef.current.focus();
    }
    // GoogleMapsFunctions.fetchPredictions('') UNDO
    onChange && onChange(e);
  };


  const handleFocus = (e) => {
    setIsFocused(true);
    if (onFocus) {
      onFocus(e); // Call external onFocus handler
    }
  };

  const handleBlur = (e) => {
      // If the blur event is not triggered by clicking on the clear button
      if (clearButtonRef.current && !clearButtonRef.current.contains(e.relatedTarget)) {
        setIsFocused(false);
      }
      if (onBlur) {
        onBlur(e);
      }
    };


  return (
    <>
      <Wrapper>
        <SearchInput
        className={`input-please-work ${className}`}
          ref={internalSearchInputRef}
          value={searchAddress || ''}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          type="text"
          id={id}
          placeholder={placeholder}
          required={required}
          autoComplete={disableAutoComplete ? "off" : "on"}
          {...props}
        />
        {searchAddress && isFocused && (
          <ClearButton ref={clearButtonRef} onClick={handleClear}>×</ClearButton>
        )}
      </Wrapper>

      
    </>
  );
});

export default GoogleMapsAddressInput;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;

`;

const SearchInput = styled.input`
    display: flex;
    flex: 1 1;
    height: 60px;
    border-radius: 20px;
    font-size: 18px;
    width: 100%;
    border: 1px solid transparent;
    padding-left: 25px;
    padding-right: 40px;
    margin-right: 5px;
    outline:none;
    background-color: transparent;
    color: black;

    /* Ensure text doesn't overflow behind clear button */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    /* Keep cursor visible */
    caret-color: black;


   input::-webkit-search-clear-button {
  -webkit-appearance: none;
  appearance: none;
  color: #CCC; /* Attempt to change the clear button color */
  }
    /* Firefox */
  input::-moz-search-clear-button {
    color: #CCC; /* Limited support */
  }
    `


    const ClearButton = styled.button`
    position: absolute;
    right: 10px;
    border: none;
    background: none;
    font-size: 20px;
    color: #CCC; /* Custom clear button color */
    cursor: pointer;
  
    &:focus {
      outline: none;
      background-color: #AAA;
    }
          &:hover {
      outline: none;
      background-color: #AAA;
      border-radius: 50%;
      color: white;
    }
  `;





    //ADDED , LIKELY DON"T NEED
    // const reverseGeocode = ({ latitude: lat, longitude: lng}) => {
    //     const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
    //     searchInput.current.value = "Getting your location...";
    //     fetch(url)
    //         .then(response => response.json())
    //         .then(location => {
    //           const place = location.results[0];
    //           const _address = extractAddress(place);
    //           setSearchAddress(_address);
    //           searchInput.current.value = _address.plain();
    //         })
    //   }
    
      //ADDED, LIKELY DON"T NEED
    //   const findMyLocation = () => {
    //     if (navigator.geolocation) {
    //       navigator.geolocation.getCurrentPosition(position => {
    //         reverseGeocode(position.coords)
    //       })
    //     }
    //   }
