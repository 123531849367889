// selectors.js

import { useSelector } from 'react-redux';

// Custom hook to select cart state
export const useCart = () => {
    return useSelector((state) => state.cart);
    //JSON.stringify(cart)
};

// Custom hook to select user signup state
export const useSignup = () => {
    return useSelector((state) => state.signup);
    //JSON.stringify(signup)
};

// Custom hook to use search state
export const useSearch = () => {
    return useSelector((state) => state.search);
    //JSON.stringify(signup)
};

// Custom hook to use search state
export const useSearchFilter = () => {
    return useSelector((state) => state.searchFilter);
    //JSON.stringify(signup)
};

// Custom hook to use search state
export const useUser = () => {
    return useSelector((state) => state.user);
    //JSON.stringify(signup)
};

// Custom hook to use search state
export const useAuth = () => {
    return useSelector((state) => state.auth);
    //JSON.stringify(signup)
};