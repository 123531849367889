// import { useState } from "react";

import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import "./Calendar.css"

export const MyDatePicker = ({selected, setSelected}) => {
  
  const handleSelect = (date) => {
    console.log("selected date: ", date)
    if (!date) {
      // If the same date is clicked again, do nothing
      return;
    }
    setSelected(date);
  };
  return <DayPicker mode="single" selected={selected} onSelect={handleSelect} />;
}

export default MyDatePicker