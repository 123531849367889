import React, { createContext, useState, useContext, useEffect } from 'react';
// import { useCart } from './CartContext';
import { useAuthContext } from './AppContext/AuthContext';
import GoogleMapsFunctions from 'utility/googleMapsFunctions';

const AppNavBarContext = createContext();

export const useAppNavBarContext = () => useContext(AppNavBarContext);
// export const WidgetManagerContext = createContext();


export const AppNavBarManagerProvider = ({ children}) => {

    const { resetAllContexts} = useAuthContext();
   
    //NEED
    const [isInteractiveSearchBarVisible, setIsInteractiveSearchBarVisible] = useState(true);
    

    const [isCompactSearchBarVisible, setIsCompactSearchBarVisible] = useState(false);
    const [canToggleBasedOnScroll, setCanToggleBasedOnScroll] = useState(true);
    const [showOverlay, setShowOverlay] = useState(false);

    
    // const toggleInteractiveSearchBar = () => {
    //     console.log("searchBarBeingToggled")
    //     setIsInteractiveSearchBarVisible(prevState => !prevState);
    //     setIsCompactSearchBarVisible(prevState => !prevState);
    // }



  //END OF SCROLL FUNCTION HANDLER

     //Reset Context
    const resetContext = () => {
        closeExpandedSearchEditMode();
        closeCartMenu();
    }


    useEffect(() => {
    if (resetAllContexts) {
        resetContext();
    }
    }, [resetAllContexts]);


    const scrollToOpenInteractiveSearch = () => {

        setIsInteractiveSearchBarVisible(true);
        setIsCompactSearchBarVisible(false);
    }

    const scrollToCondenseInteractiveSearch = () => {
        
        setIsInteractiveSearchBarVisible(false);
        setIsCompactSearchBarVisible(true);
        closeSearchInputsViews();
    }

    const openExpandedSearch = ({ location = false, when = false, duration = false } = {}) => {
        
        setCanToggleBasedOnScroll(false)
        setShowOverlay(true)
        //
        setIsInteractiveSearchBarVisible(true);
        setIsCompactSearchBarVisible(false);

        if (location){
            toggleLocationSearch();
            return;
        }
        if (when){
            toggleWhenSearch();
            return;
        }

        if (duration){
            toggleDurationSearch();
            return;
        }

    }
    const closeExpandedSearchEditMode = () => {
        setCanToggleBasedOnScroll(true) //this is new
        setShowOverlay(false)
        //
        setIsInteractiveSearchBarVisible(false);
        setIsCompactSearchBarVisible(true);
        closeSearchInputsViews();
    }



    //SEARCH INPUT STATES
    const [isLocationSearchVisible, setIsLocationSearchVisible] = useState(false);
    const [isWhenSearchVisible, setIsWhenSearchVisible] = useState(false);
    const [isDurationSearchVisible, setIsDurationSearchVisible] = useState(false);

    const toggleLocationSearch = () => {
        setIsLocationSearchVisible(prevState => !prevState);
        setIsWhenSearchVisible(false)
        setIsDurationSearchVisible(false)
        // console.log("toggleLocationSearch: ")
    }
    //below is a special case for address bar
    const setIsLocationSearchVisibleToVisible = () => {
        setIsLocationSearchVisible(true);
        setIsWhenSearchVisible(false)
        setIsDurationSearchVisible(false)
    }

    const toggleWhenSearch = () => { 
        setIsLocationSearchVisible(false)
        setIsWhenSearchVisible(prevState => !prevState);
        setIsDurationSearchVisible(false)
        // console.log("toggleWhenSearch: ",isWhenSearchVisible)

    }
    const toggleDurationSearch = () => {
        setIsLocationSearchVisible(false)
        setIsWhenSearchVisible(false)
        setIsDurationSearchVisible(prevState => !prevState);
        // console.log("toggleDurationSearch")

    }

    const closeSearchInputsViews = () => {
        setIsLocationSearchVisible(false);
        setIsWhenSearchVisible(false);
        setIsDurationSearchVisible(false);

        //CLEAR THE SEARCH CRITERIA JOINT
    }

    //CART MENU STATES
    const [isCartMenuOpen, setIsCartMenuOpen] = useState(false);
    const toggleCartMenu = () => {
        setIsCartMenuOpen(prevState => !prevState);
        console.log('isCartMenuOpen', isCartMenuOpen);
    };
    const closeCartMenu = () => setIsCartMenuOpen(false);


    // const { cartItems } = useCart();
    const [isAnySearchInputVisible, setIsAnySearchInputVisible] = useState(false);
    useEffect(() => {
        setIsAnySearchInputVisible(isLocationSearchVisible || isWhenSearchVisible || isDurationSearchVisible);
      }, [isLocationSearchVisible, isWhenSearchVisible, isDurationSearchVisible]);



    //Search Location
    const [searchLocationInput, setSearchLocationInput] = useState('')
    const [searchLocationPredictions, setSearchLocationPredictions] = useState([])

    const updateSearchLocationPredictions = (search) => {
        setSearchLocationInput(search)
        GoogleMapsFunctions.fetchPredictions(search)
        .then(predictions => {
          console.log('Predictions:', predictions);
          setSearchLocationPredictions(predictions)
        })
        .catch(error => {
          console.error('Error fetching predictions:', error);
          setSearchLocationPredictions([])
        });
    }

    return (
        <AppNavBarContext.Provider
            value={{ 
                //SEARCH ITEMS HERE
                isInteractiveSearchBarVisible,
                isCompactSearchBarVisible,
                openExpandedSearch,
                closeExpandedSearchEditMode,
                // handleScroll,
                //CART MENU STATES
                isCartMenuOpen,
                toggleCartMenu,
                closeCartMenu,
                //SEARCH INPUT STATES
                setIsLocationSearchVisibleToVisible,
                isLocationSearchVisible,
                isWhenSearchVisible,
                isDurationSearchVisible,
                toggleLocationSearch,
                toggleWhenSearch,
                toggleDurationSearch,
                closeSearchInputsViews,
                setCanToggleBasedOnScroll,
                canToggleBasedOnScroll,
                scrollToOpenInteractiveSearch,
                scrollToCondenseInteractiveSearch,
                showOverlay,
                setShowOverlay,
                isAnySearchInputVisible,
                updateSearchLocationPredictions,
                searchLocationPredictions
             }}
        >
            {children}
        </AppNavBarContext.Provider>
    );
};