import {USER_UPDATE_ITEMS, USER_SAVE_NEW} from "third_party/redux/actionTypes";
import { useDispatch } from "react-redux";






export const updateUserItems = (user) => {
    return {
        type: USER_UPDATE_ITEMS,
        payload: user
    }
}
export const saveNewUser = (user) => {
    return {
        type: USER_SAVE_NEW,
        payload: user
    }
}

//=============================
// Custom hook for search actions
//=============================
export const useUserActions = () => {
    const dispatch = useDispatch();

const USER_UPDATE = (items) =>{
    dispatch(updateUserItems(items))
}

const USER_REPLACE = (items) =>{
    dispatch(saveNewUser(items))
}

return {
    USER_UPDATE,
    USER_REPLACE
}


}
