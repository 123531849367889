import React, { useState } from 'react';
import styled from 'styled-components';
import FAQItem from './FAQItem'; // Make sure to adjust the path as necessary



const FAQManager = ({ questionsAndAnswers, title,...props }) => {
    const [openIndex, setOpenIndex] = useState(null);
  
    const handleClick = (index) => {
      setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <FAQContainer {...props}>

          <FAQHeader>{title}</FAQHeader>
          {questionsAndAnswers.map((faq, index) => (
            <FAQItem
              key={index}
              question={faq.question}
              answer={faq.answer}
              isOpen={openIndex === index}
              onClick={() => handleClick(index)}
            />
          ))}
        </FAQContainer>
      );
    };

export default FAQManager;

const FAQContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 15px;
  margin: 0 auto;
  padding: 100px 36px;
  background-color: transparent;
//   border: 1px solid #ddd;
//   border-radius: 5px;
`;

const FAQHeader = styled.h2`
color: black;
font-weight: 700;
font-size: 45px;
line-height: 90%;
// margin-bottom: 0px;
margin-top: 0px;
`