// src/components/Overlay.js
import React from 'react';
import ReactDOM from 'react-dom';

import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';




const OverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000060;
  display: ${props => (props.$isVisible ? 'flex' : 'none')};
  z-index: ${props => (props.$zIndex)};
`;

const Overlay = ({ isVisible, onClose , zIndex= 1000}) => {
    return (
        <>
        <OverlayWrapper
            onClick={onClose} $isVisible={isVisible} $zIndex = {zIndex}>
        </OverlayWrapper>,
        </>
    );
};

export default Overlay;

