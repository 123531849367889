import { ObjectUserStagingClass } from "model/objectUserStaging";
import { SIGNUP_UPDATE_ITEMS} from "third_party/redux/actionTypes";


//#################################
//#############Initial States############
//#################################

const userStaging = new ObjectUserStagingClass().toDict();


//#################################
//#############REDUCERS############
//#################################
//The reducer function handles state changes based on the action type. It takes the current state and an action as arguments and returns a new state.
const signupReducer = (state = userStaging, action : any) => {

    switch(action.type){

        case SIGNUP_UPDATE_ITEMS:
            return { ...state, ...action.payload };

        default:
            return state

    }

}


export default signupReducer