import React, {useState} from 'react';
import styled from '@emotion/styled';

// import {useState, useEffect, useRef } from "react";
// import { useNavigate, Link } from 'react-router-dom';

import { NAVBAR_HEIGHT } from "components/constants";

import { useScreenContext } from 'context/AppContext/ScreenContext';
import InputContainer from 'components/Containers/InputContainer';

import SignupPhoneInput from 'components/Form/SignupPhoneInput';
import SignupPhoneAccessCode from 'components/Form/SignupPhoneAccessCode';
import SignupBusinessInfo from 'components/Form/SignupBusinessInfo';
import {buttonRoundCloseStyle, buttonSubmitStyle} from 'components/Buttons/ButtonStyles'

import SignupServiceAPI from 'api/Business/SignupService/SignupServiceAPI';
//class data
import { ObjectUserStaging, ObjectUserStagingClass } from 'model/objectUserStaging';
import PopUpNotification from 'components/Popups/PopUpNotification';


    interface ContentProps {
        $isMobile: boolean;
        $isMedium: boolean;
        $isLarge: boolean;
    }

    interface SignupProps {}
// const Signup = (props) => {
const Signup: React.FC<SignupProps> = () => {


    //Sign Up Serices Bro!
    const signupService = new SignupServiceAPI();



    //Screen Size
    const {isMobile, isMedium, isLarge} = useScreenContext()


    const [formData, setFormData] = useState<ObjectUserStaging>(new ObjectUserStagingClass());

    const [screen, setScreen] = useState(0)

    const [error, setError] = useState<string | null>(null);
    const [showNotification, setShowNotification] = useState(false);




    const handleInputChange = (value:any, field: string) => {
        // Your logic to update state
        console.log(`Value: ${value}, Field: ${field}`);

        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));

        console.log('formData: ',formData)

    };
    const handlePrevious = () => {
        setScreen(prevScreen => prevScreen - 1);
        console.log('Previous', screen - 1);
    }

    const handleNext = async() => {
        try{
            let success = false;
            if(screen == 0){
                //GET ACCESS CODE
                success = await getAccessCode(formData.phone_number || '')
            }
            else if(screen == 1){
                //VALIDATE ACCESS CODE
                success = await confirmAccessCode(formData.phone_number || '', formData.access_code || '')

            }else if (screen == 2){
                //SEND Account Data to Server
                success = await saveAccountInfo(
                    formData.phone_number || '',
                    formData.access_code || '',
                    formData.invite_code || '',
                    formData.password || '',
                    formData.first_name || '',
                    formData.last_name || '',
                    formData.email || '')
            }
            else if(screen == 3){
                console.log("exit and navigate to dashboard baby!")
                //LOGIN FUNCTION
                success = true
            }

            // If all API calls are successful, increment the screen
            if(success){
                setScreen(prevScreen => prevScreen + 1);
            }

        }
        catch(error){
            console.log("ERROR WAS THROWN! ", error)
            
            //DISPLAY POPUP HERE
            setError(error as string)
        }

    }


    //**************************************
    //API FUNCTIONS
    //************************************** 
    const getAccessCode = async (phoneNumber: string) => {
        try {
            const result = await signupService.getAccessCode(phoneNumber)
            console.log('getAccessCode Result:', result);
            return true;
        }
        catch (error){
            console.log('getAccessCode Error: ', error)
            throw error;
        }
    }

    const confirmAccessCode = async (phoneNumber: string, accessCode: string) => {
        try {
            const result = await signupService.confirmAccessCode(formData.phone_number || '', formData.access_code || '');
            console.log('confirmAccessCode Result:', result);
            return true;
        }
        catch (error){
            console.log('confirmAccessCode Error: ', error)
            throw error;
        }
    }

    const saveAccountInfo = async (phone_number: string, access_code: string, invite_code: string, password: string, first_name: string, last_name: string, email: string ) => {
        try {
            const result = await signupService.saveAccountInfo(
                phone_number || '',
                access_code || '',
                invite_code || '',
                password || '',
                first_name || '',
                last_name || '',
                email || '')
            console.log('saveAccountInfo Result:', result);
            return true
        }
        catch (error){
            console.log('saveAccountInfo Error: ', error)
            throw error
        }
    }


    const handleClosePopup = () => {
        setError(null);  // Close the popup by clearing the error state
      };



    return (
    <>

        <Container>
            <Content $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>


                <InputContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>

                    <HeaderContainer>
                        <FlexItem>
                        {screen > 0 && (
                            <ButtonClose onClick={handlePrevious}>←</ButtonClose>
                        )}
                        </FlexItem>
                        <FlexItem>
                            <LogoContainer>
                                <img src='/images/cta-logo-one.svg' alt="GoghNow" />
                            </LogoContainer>
                        </FlexItem>
                        <FlexItem>

                        </FlexItem>
                    </HeaderContainer>
                    <FormContent>
                        {/* STEP 1 */}
                            {screen == 0 && (
                                <>
                                    <SubText><p>Phone</p></SubText>
                                    <MainText><h4>Add your phone number</h4></MainText>
                                    <SignupPhoneInput // Use the interface for type safety
                                        data={formData}
                                        onChange={handleInputChange}
                                        placeholder="Enter Phone Number" // Provide placeholder prop
                                    />
                                </>
                            )}
                        {/* STEP 2 */}
                            {screen == 1 && (
                            <>
                                <SubText><p>Access Code</p></SubText>
                                <MainText><h4>Enter your access code</h4></MainText>
                                <SignupPhoneAccessCode
                                    data={formData}
                                    onChange={handleInputChange}
                                    placeholder='Access Code'
                                />
                            </>
                            )}

                        {/* STEP 3 */}
                        {screen == 2 && (
                            <>
                                <SubText><p>Account Info</p></SubText>
                                <MainText><h4>Enter your account information</h4></MainText>
                                <SignupBusinessInfo
                                    data={formData}
                                    onChange={handleInputChange}
                                />
                            </>
                        )}

                    </FormContent>

                    {screen < 4 && (

                        <NextButton onClick={handleNext}>
                            {screen < 3 ? 'Next' : 'Go to Dashboard'}
                        </NextButton>
                    )}
                    

                </InputContainer>


                <BgImage/>
                <BgImageOverlay/>
            </Content>
        </Container>

        {/* Conditionally render the popup when there is an error */}
        {error && (
            <PopUpNotification
            titleText="Error"
            descriptionText={error}
            buttonText="Close"
            buttonAction={handleClosePopup}  // Close popup on button click
            />
        )}
    </>
    );
};


export default Signup;

const ButtonClose = styled.button`
${buttonRoundCloseStyle}
`
const NextButton = styled.button`
${buttonSubmitStyle}
margin-top: 10px;
width: 100%;
margin-top: 20px;
`

const FormContent = styled.form`
width: 100%;

`
const SubText = styled.div`
justify-content: start;
display: flex;
width: 100%;
p{
    color: #DDD;
    margin-bottom: 0px;
}
`
const MainText = styled.div`
display: flex;
width: 100%;
h4{
color: black;
margin-top: 0px;
}
`
const Container = styled.section`
overflow: hidden;
display: flex;
flex-direction: column;
text-align: left;
min-height: 100vh;
min-height: 100dvh;
`;

const Content = styled.div<ContentProps>`
width: 100%;
position: relative;
min-height: 100vh;
min-height: 100dvh;
box-sizing: border-box;
display: flex;
padding: ${props => {
    if (props.$isMobile) return '0px';
    if (props.$isMedium) return '100px 40px';
    if (props.$isLarge) return '100px 40px';
    return 'px'; // Default to large if none match
}};
justify-content: ${props => {
    if (props.$isMobile) return 'none';
    if (props.$isMedium) return 'center';
    if (props.$isLarge) return 'center';
    return 'center'; // Default to large if none match
}}; 
// justify-content: center;
align-items: center;
flex-direction: column;
height: 100%;
`;

const BgImage = styled.div`
background-image: url("/images/web-gradient-100.jpg");
height: 100%;
background-position: top;
background-size: cover;
background-repeat: no-repeat;
position: fixed;
top: 0;
right: 0;
left: 0;
z-index: -1;
`;
const BgImageOverlay = styled.div`
height: 100%;
opacity: 0.5;
background-color: white;
background-position: top;
background-size: cover;
background-repeat: no-repeat;
position: fixed;
top: 0;
right: 0;
left: 0;
z-index: -1;
`;






const LogoContainer = styled.a`
padding: 0px;
width: 150px;
margin-top: 36px;
margin-bottom: 36px;
max-height: 70px;
font-size: 0px;
display: inline-block;

img {
    display: block;
    width:100%;
}
`
const HeaderContainer = styled.div`
display: flex;
width: 100%;
height: ${NAVBAR_HEIGHT};
`

const FlexItem = styled.div`
  flex: 1;
  max-width: 33.3%;
  display: flex;
  justify-content: center;
  align-items: center;

    &:nth-of-type(1) {
    justify-content: start;
  }
`;