import styled from "styled-components"
import { useCart } from "third_party/redux/selectors"
import SearchFunctions from "utility/searchFunctions"

const CartButton = ({onClick}) => {
    const cart = useCart()
    const countCartItems = SearchFunctions.countCartItems(cart); // Safely check the length or default to 0
return (
    <Container>
        <Button onClick={onClick}>
            <IconContainer>
                <img src="/images/cart.svg" alt="Cart" />
            </IconContainer>
        </Button>
        <Indicator>{countCartItems}</Indicator>
    </Container>
)


}
export default CartButton;
const Container = styled.div`
display: block;
position: relative;
height: auto;
width: auto;
`
const Button = styled.div`

    display: flex;
    padding: 2.5px 2.5px;
    background-color: #FBAE6260;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    color: white;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;

    &:hover {
        background-color: #FBAE62;
   }
`

const IconContainer = styled.div`
width: 40px;
height: 40px;
align-items: center;
justify-content: center;
display: flex;

img {
    display: flex;
    width:65%;
    height: 65%;
    color: black;
}
`

const Indicator = styled.div`
display: flex;
justify-content: center;
align-items: center;
background-color: red;
color: white;
position: absolute;
top: 0px;
right: calc(0px - 10px);
aspect-ratio: 1/1;
height: 20px;
width: 20px;
border-radius: 75%;
padding: 2px 4px;
`