

import AxiosService from './ThirdParty/AxiosConfig';

// Function for GET requests
export const get = async (route, params = {}) => {
    const url = route;
    console.log("ROUTE: ", url)
    try {
        const response = await AxiosService.get(url, { params });
        console.log('response is: ',response)
        return response.data;
    } catch (error) {
        console.error("TEST HOW BABY: ",error)
        throw error.response?.data?.message || error.message;;
    }
};

// Function for POST requests
export const post = async (route, params = {}) => {
    const url = route;
    try {
        const response = await AxiosService.post(url, params);
        return response.data;
    } catch (error) {
        throw error.response?.data?.message || error.message;;
    }
};

// Function for PUT requests
export const put = async (route, params = {}) => {
    const url = route;
    try {
        const response = await AxiosService.put(url, params);
        return response.data;
    } catch (error) {
        throw error.response?.data?.message || error.message;;
    }
};
