import styled from 'styled-components';
import { useState } from "react";

import TextField from '@mui/material/TextField';
import { Slider, Typography, Box } from '@mui/material';

const CheckoutDetails = ( { data, handleInputChange}) => {
  // budget: [2000, 3000]
  const [error, setError] = useState(false);

  // Ensure budget and details have default values if not provided
  const budget = data.budget || 0;//[2000, 3000];
  const details = data.details || '';
  const promo_code = data.promo_code || '';
  // const [data, setData] = useState({
  //   budget: [2000, 3000],  // Initialize budget as an array
  //   details: '',
  // });

  // const handleInputChange = (e, newValue) => {
  //   const id = e.target.id || 'budget';  // Default to 'budget' if e.target.id is undefined
  //   const value = newValue ?? e.target.value;

  //   setData((prevData) => ({
  //     ...prevData,
  //     [id]: value,
  //   }));
  // };

  // console.log("CHECKOUTLOCATION Duration 2: ", duration);
  return (
    <Container>
      <ContentContainer>
        <DateContainer>
          <DetailsSection>
          <SpaceEmpty/>
          <Typography gutterBottom>
              Budget range ${budget}

              {/* Budget range (${budget[0]} - ${budget[1]}) */}
            </Typography>
            <Slider
              id="budget" // Set id to budget
              value={budget}
              onChange={(e, newValue) => handleInputChange({ target: { id: 'budget' } }, newValue)}// Pass newValue to handleInputChange
              // valueLabelDisplay="auto"
              min={0}
              max={5000}
              step={25}
              // aria-labelledby="range-slider"
              sx={{ marginBottom: 2 }}
            />
            {/* <Typography>
              ${data.budget[0]} - ${data.budget[1]}
            </Typography> */}


            <SpaceEmpty/>


            <TextField
              id="details"
              label="Details"
              type="text"
              variant="outlined"
              value={data.details || ''}
              onChange={handleInputChange}
              fullWidth
              size="small"
              margin="normal"
              multiline
              required
              rows={4} // You can adjust the number of rows as needed
              InputLabelProps={{
                shrink: true,
            }}
            />

            <TextField
              id="promo_code"
              label="Promo Code"
              type="text"
              variant="outlined"
              value={data.promo_code || ''}
              onChange={handleInputChange}
              fullWidth
              size="small"
              margin="normal"
              InputLabelProps={{
                shrink: true,
            }}
            />

          </DetailsSection>
        </DateContainer>
      </ContentContainer>
    </Container>
  );
}

export default CheckoutDetails;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  padding: 7.5px 0px;
  display: flex;
  width: 100%;
  gap: 10px;
  cursor: pointer;
  align-items: start;
  color: black;
`;

const DateContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  cursor: pointer;
  align-items: center;
  color: black;
`;

const DetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  p {
    color: #00000060;
    margin: 0px;
  }
  h4 {
    margin: 0px;
  }

  @media (max-width: 768px) {
    flex: 0 0 100%;
  }
`;

const InputContainer = styled.div`
  margin: 0px 0px 15px 0px;
`;

const SpaceLight = styled.div`
display:flex;
width:100%;
height: 1px;
background-color: #00000060;
margin: 15px 0px;
`

const SpaceEmpty = styled.div`
display:flex;
width:100%;
height: 1px;
// background-color: #000000;
margin: 7.5px 0px;
`