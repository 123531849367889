import styled from 'styled-components';
import { NAVBAR_HEIGHT, SECTION_PADDING } from 'components/constants';
import { useState, Component } from "react"

import EventServiceImageContainer from "./EventServiceImageContainer"


const EventServiceItem = ({item, addToCart, removeFromCart, cartItems, toggleCartItem}) => {

    const {title, description, id} = item
    const isSelected = cartItems.some(cartItem => cartItem.id === id);

    // const images = item.images
    const toggleCartItemSelection = (e,item) => {

        e.stopPropagation();
        toggleCartItem(item)
    }
    return (
        <Container $isSelected={isSelected} onClick={(e) => toggleCartItemSelection(e,item)}>
            <EventServiceImageContainer item={item} addToCart={addToCart} removeFromCart={removeFromCart} toggleCartItem={toggleCartItem} cartItems={cartItems} isSeleted={isSelected}/>
            <TextContainer>
                <Title>
                    {title}
                </Title>
                <Description>
                    {description}
                </Description>
            </TextContainer>
        </Container>
    )
}
export default EventServiceItem;

 

const Container = styled.div`
display: flex;
flex-direction: column;
width: 100%;
overflow-x: hidden;
`
const TextContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 5em;
justify-content: start;
align-items: start;
`

const Title = styled.p`
font-size: 16px;
font-weight: 600;
text-align: left;
color: black;
margin: 0px;
`
const Description = styled.p`
font-size: 12px;
font-weight: 100;
color: #00000060;
margin: 0px;
text-align: left;
`










