import { textFieldStyle } from "components/Inputs/InputStyles";

import React from 'react';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import PasswordTextField from "components/Inputs/PasswordTextField";

import { ObjectUserStaging, ObjectUserStagingClass } from 'model/objectUserStaging';



interface SignupBusinessInfoProps {
    data?: ObjectUserStaging; // Optional data object
    onChange: (value: string, field: string) => void; // Required change handler
    gap?: string; // Optional placeholder text
  }

  interface StyledProps {
    gap?: string;
  }

const SignupBusinessInfo: React.FC<SignupBusinessInfoProps> = ({data = new ObjectUserStagingClass() , onChange, gap = '10px' }) => {

    return (
        <>
            <Container gap={gap}>


                <Row gap={gap}>
                    <TextFieldCustom
                        id="first_name"
                        label="First Name"
                        value={data.first_name || ''}
                        onChange={(e) => onChange(e.target.value, 'first_name')}
                        placeholder={"First Name"}
                        sx = {textFieldStyle}
                    />
                    <TextFieldCustom
                        id="last_name"
                        label="Last Name"
                        value={data.last_name || ''}
                        onChange={(e) => onChange(e.target.value, 'last_name')}
                        placeholder={"Last Name"}
                        sx = {textFieldStyle}
                    />
                </Row>
                <TextFieldCustom
                    id="email"
                    label="Email"
                    value={data.email || ''}
                    onChange={(e) => onChange(e.target.value, 'email')}
                    placeholder={"Email"}
                    sx = {textFieldStyle}

                />
                <PasswordTextField
                            id="password"
                            label="Password"
                            value={data.password || ''}
                            onChange={(e) => onChange(e.target.value, 'password')}
                            sx = {textFieldStyle}
                            placeholder={"Password"}
                            
                            // margin="normal"
                            // required
                        />


            </Container>
        </>
    )

}

export default SignupBusinessInfo

const Container = styled.div<StyledProps>`
margin-top: ${props => props.gap};
display: flex;
flex-direction: column;
gap: ${props => props.gap}; /* Use the gap prop here */
`
const Row = styled.div<StyledProps>`
display: flex;
flex-direction: row;
width: 100%;
gap: ${props => props.gap}; /* Use the gap prop here */
`

const TextFieldCustom = styled(TextField)`
width: 100%;
flex: 1;
`