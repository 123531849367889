// ScreenContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const ScreenContext = createContext();

// Export a custom hook to access the auth context
const useScreenContext = () => useContext(ScreenContext);

const ScreenProvider = ({ children }) => {
  //768
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 450);
  const [isMedium, setIsMedium] = useState(window.innerWidth > 450 && window.innerWidth <= 1024);
  const [isLarge, setIsLarge] = useState(window.innerWidth > 1024);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 450);
    setIsMedium(window.innerWidth > 450 && window.innerWidth <= 1024);
    setIsLarge(window.innerWidth > 1024);
  };
  // const handleResize = () => {
  //   console.log("TRYING TO UPDATE SIZING")
  //   const newWidth = window.innerWidth;
    
  //   // No need to compare current state, just set based on window width
  //   setIsMobile(newWidth <= 450);
  //   setIsMedium(newWidth > 450 && newWidth <= 1024);
  //   setIsLarge(newWidth > 1024);
  // };

  // useEffect(() => {
  //   console.log("WINDOW IS RESIZING")
  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Trigger the initial resize check to ensure state is set correctly on mount
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <ScreenContext.Provider value={{ isMobile, isMedium, isLarge }}>
      {children}
    </ScreenContext.Provider>
  );
};


export { ScreenProvider, useScreenContext};
