// RequestContext.js
import React, { createContext, useContext, useState } from 'react';
import {serviceSignupAPI} from './BusinessServiceAPI' //change email signup

const BusinessServiceAPIContext = createContext();

export const useBusinessServiceContext = () => {
  return useContext(BusinessServiceAPIContext);
};

export const BusinessServiceAPIProvider = ({ children }) => {

  const [serviceSignupResult, setServiceSignupResult] = useState(); // Updated to an object to manage multiple errors

//USER
const serviceSignup = async (data,) => {
  console.log("submitting servicesSignup: ",data)
  const result = await serviceSignupAPI(data);
  setServiceSignupResult(result) //set result  here, i dont need to save
  return result;
};


  return (
    <BusinessServiceAPIContext.Provider
      value={{
        serviceSignupResult, serviceSignup }}
    >
      {children}
    </BusinessServiceAPIContext.Provider>
  );
};
