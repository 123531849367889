import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; /*Switch is now Routes*/
// import Home from "./pages/home/Home"

//User
import Home from 'pages/home/Home';
//User App
import UserApp from 'apps/UserApp';
import Book from "./pages/book/Book"

//Provider
import Provider from "./pages/provider/Provider"

//SignUp/Login
import Signup from "./pages/signup/Signup"
import SignupWithEmail from 'apps/newSignUpComponents/SignUpMaster'; //NEW

import Login from "./pages/login/Login"

//Business
// import Business from './pages/business/Business';
import Business from 'pages/business/Business';
import BusinessApp from 'apps/BusinessApp';

//Careers
import BizDevRep from 'pages/careers/biz-dev-rep-I';

//Embedded Test
import Embedded from "./embedded/Embedded"

import {AppProvider} from './context/AppProvider'
 
import './App.css';

import { SkeletonTheme } from 'react-loading-skeleton';


function App() {
  
  return (
    <div className="App">
      <SkeletonTheme baseColor="#CCC" highlightColor='#EEE'>
        <AppProvider>
        {/* <AuthProvider>
          <UserProvider> */}
            <Router>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/s" element={<UserApp />} />

                <Route path="/Provider" element={<Provider />} />
                <Route path="/SignUp" element={<SignupWithEmail />} />
                <Route path="/Login" element={<Login />} />
                <Route path="/Book" element ={<Book />} />
                <Route path="/Embedded" element ={<Embedded />} />
                <Route path="/Business" element ={<Business />} />
                <Route path="/Business/app" element ={<BusinessApp />} />


                {/* Career Paths */}
                <Route path="/Careers/biz-dev-rep-I" element={<BizDevRep />} /> {/* Add this route */}

              </Routes>
            </Router>
          {/* </UserProvider>
        </AuthProvider> */}
        </AppProvider>
      </SkeletonTheme>
    </div>
  );
}

export default App;
