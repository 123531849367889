import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_REPLACE_ITEMS, CART_TOGGLE_ITEM} from "third_party/redux/actionTypes";


//#################################
//#############Initial States############
//#################################

const cart = []; //UPDATED THIS WITH THE REQUESTS


//#################################
//#############REDUCERS############
//#################################
//The reducer function handles state changes based on the action type. It takes the current state and an action as arguments and returns a new state.
const cartReducer = (state = cart, action) => {

    //Used in ADD and Remove
    const product = action.payload;


    switch(action.type){

        case CART_ADD_ITEM: {
            const exist = state.find((x) => x.id === product.id);
            const maxQty = product.qtyAvailable || 0; // Updated from unitsAvailable

            if (exist) {
                const newQty = exist.qty + 1; // Updated from unitQuantity

                if (newQty > maxQty) {
                    return state; // Prevent adding more than available quantity
                } else {
                    return state.map((x) =>
                        x.id === product.id ? { ...x, qty: newQty } : x
                    );
                }
            } else {
                return [
                    ...state,
                    {
                        ...product,
                        qty: 1, // Initial qty
                    }
                ];
            }
        }
        break

        case CART_REMOVE_ITEM: {
            const exist = state.find((x) => x.id === product.id);

            if (exist) {
                if (exist.qty === 1) {
                    return state.filter((x) => x.id !== product.id);
                } else {
                    return state.map((x) =>
                        x.id === product.id ? { ...x, qty: x.qty - 1 } : x
                    );
                }
            }
            return state;
        }
            break

        case CART_TOGGLE_ITEM: {
            const exist = state.find((x) => x.id === product.id);
        
            if (exist) {
                // If the item exists, remove it
                return state.filter((x) => x.id !== product.id);
            } else {
                // If the item does not exist, add it with qty 1
                return [
                    ...state,
                    {
                        ...product,
                        qty: 1, // Initial qty
                    }
                ];
            }
        }
            break

        case CART_REPLACE_ITEMS:
            return action.payload; // Replace the entire cart with the new array

        default:
            return state;
            break;
    }

}

export default cartReducer