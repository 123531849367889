import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import HotspotPopup from './HotspotPopup'; // Import the popup component

const Hotspot = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
  border: 1.5px solid #ccc;
  box-shadow: 0 0 8px 3px yellow;
  animation: pulse 2s infinite;
  transition: background-color 0.3s;

  &:hover {
    background-color: yellow;
  }
`;

const HotspotOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(128, 128, 128, 0.5);
  z-index: 9;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
`;

const InteractiveImage = ({ src, alt, initialHotspots }) => {
  const [hotspots, setHotspots] = useState(initialHotspots);
  const [selectedHotspot, setSelectedHotspot] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef(null);

  const updateHotspotsPosition = () => {
    if (!imageRef.current) return;

    const imgWidth = imageRef.current.offsetWidth;
    const imgHeight = imageRef.current.offsetHeight;

    const updatedHotspots = initialHotspots.map((hotspot) => ({
      ...hotspot,
      calculatedX: hotspot.x * imgWidth,
      calculatedY: hotspot.y * imgHeight,
    }));

    setHotspots(updatedHotspots);
  };

  // useEffect(() => {
  //   updateHotspotsPosition();
  //   window.addEventListener('resize', updateHotspotsPosition);
  //   return () => window.removeEventListener('resize', updateHotspotsPosition);
  // }, [initialHotspots]);

  useEffect(() => {
    if (imageLoaded) {
      updateHotspotsPosition();
    }
    window.addEventListener('resize', updateHotspotsPosition);
    return () => window.removeEventListener('resize', updateHotspotsPosition);
  }, [initialHotspots, imageLoaded]);


  const handleImageLoad = () => {
    setImageLoaded(true);
    updateHotspotsPosition();
  };

  const handleHotspotClick = (hotspot, event) => {
    // const rect = event.currentTarget.getBoundingClientRect();

    const imageRect = imageRef.current.getBoundingClientRect();

    setSelectedHotspot({
      ...hotspot,
      globalX: imageRect.left + hotspot.calculatedX,
      globalY: imageRect.top + hotspot.calculatedY,
    });
  };

  const closePopup = () => setSelectedHotspot(null);

  return (
    <ImageContainer ref={imageRef}>
      <img src={src} alt={alt} onLoad={handleImageLoad} />
      {hotspots.map((hotspot) => (
        <Hotspot
          key={hotspot.title}
          style={{
            left: `${hotspot.calculatedX}px`,
            top: `${hotspot.calculatedY}px`,
          }}
          onClick={(event) => handleHotspotClick(hotspot, event)}
        />
      ))}
      {selectedHotspot && (
        <>
          <HotspotOverlay onClick={closePopup} />
          <HotspotPopup hotspot={selectedHotspot} />
        </>
      )}
    </ImageContainer>
  );
};

export default InteractiveImage;
