import React, { forwardRef, useState } from 'react';
import styled, {css, keyframes} from "styled-components";
// import styled from '@emotion/styled'
import Slider from '@mui/material/Slider';

// const marks = Array.from({ length: 23 }, (_, index) => ({
//   value: index * 0.5,
//   label: `${Math.floor(index / 2) + 1}${index % 2 === 0 ? ':00' : ':30'}`,
// }));

import { useSearch } from 'third_party/redux/selectors';
import SearchFunctions from 'utility/searchFunctions';
import { useSearchActions } from 'third_party/redux/search/searchActions';

const DurationInput = ({isOpen, ...props}) => {


  // const [duration, setDuration] = useState(1);
  const searchCriteria = useSearch().current
  const tempSearchCriteria = useSearch().temp
  const {SEARCH_DURATION} = useSearchActions()


  const handleChange = (event, newValue) => {

    console.log('VALUE: ',newValue)
    SEARCH_DURATION(newValue)

    // setDuration(newValue);
  };

  const dateWithDurationAdded = SearchFunctions.addMinutesToDate(tempSearchCriteria.date,tempSearchCriteria.duration)

    return (
        <>
            <Container $isOpen={isOpen} {...props}>
                {/* <h1>Duration</h1> */}
                <TitleContainer>

                <h1>{SearchFunctions.displayDuration(tempSearchCriteria.duration)}</h1>
                </TitleContainer>
                <SearchElementP>{(SearchFunctions.displayMonthDayYearTime(dateWithDurationAdded))}</SearchElementP>

                <SliderContainer>
                <Slider
                  value={tempSearchCriteria.duration}
                  min={30}
                  max={12 * 60}
                  step={30}
                  // marks={marks}
                  // valueLabelDisplay="auto"
                  onChange={handleChange}
                />
                </SliderContainer>
            </Container>
        </>
    )
}
 export default DurationInput
const Container = styled.div`
    color: black;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 75%;
    height: auto;
    background-color: white;
    border-radius: 25px;
    transform: ${(props) => (props.$isOpen ? 'translateX(0)' : 'translateX(100vw)')};  // Update the transform property
    transition: transform 0.3s ease-in-out;

    ${(props) =>
        props.$isOpen
          ? css`
              animation: ${fadeIn} 0.5s forwards;
              display:flex;
            `
          : css`
              animation: ${fadeOut} 0.3s forwards;
              animation-delay: 0s; /* No delay for fade out */
              display: none;
              opacity:0;
            `}  
`

// KEYFRAMES
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const TitleContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

h1{
  margin: 0px 0px;
  color: black;
  // font-size: 6em;
}
p{
  margin:0px 0px;
  color: #00000060;
}
`
const SliderContainer = styled.div`
display: flex;
width: 50%;
height: auto;
align-items: center;
justify-content: center;
color: black;
`

const SearchElementP = styled.p`
color: #00000080;
font-size: .875em;
font-weight: 500;
margin: 0px;
`