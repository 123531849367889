//Hemlet
import { Helmet } from 'react-helmet';


import BusinessAppContent from './BusinessAppComponents/ContentSections/BusinessAppContent';
import NavBarLogin from 'pages/business/header/NavBarLogin';
// import { HomeURLProvider } from "context/URL/HomeURLContext";
import { BusinessURLProvider } from 'context/URL/BusinessURLContext';

import { AppNavBarManagerProvider } from "context/AppNavBarContext";
import { useAuth } from "third_party/redux/selectors";

import { PageContainer } from "components/Page/Elements";

const BusinessApp = (props) => {

    const { isLoggedIn } = useAuth()

//GeneralSignUpMenuProvider provides context for a popup menu that goes over Homeontent
    return (
            <>
                <Helmet>
                    <title>GoghNow | Explore Event Services Near You</title>
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="GoghNow for Business | Earn More " />
                    <meta name="description" content="GoghNow is a one-stop-shop for event services. Earn more from your spaces" />
                    <meta property="og:image" content="images/cta-logo-one.png" />
                </Helmet>
                <BusinessURLProvider>  {/*updates url and gets data from url*/}
                <AppNavBarManagerProvider>

                        <PageContainer className="page-container">
                          <NavBarLogin/>
                            {/* {isLoggedIn ? ( */}
                            <BusinessAppContent />
                            {/* ) : ( */}
                                console.log('not logged in')
                            {/* )} */}
                        </PageContainer>
                </AppNavBarManagerProvider>
                </BusinessURLProvider>
            </>
    );
};


export default BusinessApp;