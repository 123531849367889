import styled from 'styled-components';


const CheckoutServicesItem = ({ item }) => {

    return (
        <Container>
            <ItemInfoContainer>
              <ImageSection>
                <img src={item.images[0].image} alt={item.images[0].alt} />
              </ImageSection>
              <DetailsSection>
                  <h1>{item.title}</h1>
                  {/* {item.description && <p>{item.description}</p>} */}
              </DetailsSection>
            </ItemInfoContainer>
            <PriceComponent>
              <p>$0.00</p>
            </PriceComponent>
        </Container>
        );
    };

export default CheckoutServicesItem;

const Container = styled.div`
padding: 7.5px 0px;
display: flex;
flex-direction: row;
width: 100%;
gap: 10px;
// border-bottom: 1px solid #00000060;
// &:hover{
//     background-color: #00000010;
// }
`


const ItemInfoContainer = styled.div`
display:flex;
flex-direction: row;
flex: 1 1 calc(75% - 10px);
align-items: center;
justify-content: between;
gap: 15px;
`

const PriceComponent = styled.div`
display: flex;
flex: 1 1 calc(25% - 10px);
justify-content: end;
// cursor: pointer;

p{
    // text-decoration: bold;
    color: black;
}
`


const ImageSection = styled.div`
display: flex;
aspect-ratio: 1 / 1;
height: 50px;
background-color: #CCC;

img {
    display: block;
    width: 100%;  /* Fill container horizontally */
    height: 100%;  /* Fill container vertically (important change) */
    object-fit: cover;  /* Cover the container while maintaining aspect ratio */
    object-position: center; /* Center the image within the container */
    background-color: #00000040;
}
`
const DetailsSection = styled.div`
display: flex;
  flex-direction: column;
  width: 100%;
//   flex: 0 0 calc(50% - 50px);
  padding-bottom: 1em;


  h1 {
    color: #00000060;
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 0px;
  }

  p {
    /* Default color, overridden by enabled/disabled styles */
    color:#00000060;
    margin: 0px;
  }

  @media (max-width: 768px) {
    flex: 0 0 100%;
  }

`;



//   ${(props) =>
//     props.enabled
//       ? css`
//           cursor: pointer;

//           &:hover {
//             opacity: 0.60;
//           }

//           h1 {
//             color: black;
//           }

//           p {
//             color: #00000060;
//           }
//         `
//       : css`
//           cursor: default;

//           &:hover {
//             opacity: 1.0;
//           }

//           h1 {
//             color: #ccc;
//           }

//           p {
//             color: #ccc;
//           }
//         `}
