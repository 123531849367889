
import SearchFunctions from "utility/searchFunctions";
import { BaseModel } from "model/base";








export interface objectSearchCriteria {
    location: {};
    date: Date;
    duration: number
}

export class ObjectSearchCriteriaClass extends BaseModel implements objectSearchCriteria {

    location: {};
    date: Date;
    duration: number;

    constructor(data: Partial<objectSearchCriteria> = {}){
        super(data); // Call the constructor of BaseModel with 'data'
        this.location = data.location || {};
        this.date = data.date || SearchFunctions.getTimeNow();
        this.duration = data.duration || 120;
    }
}