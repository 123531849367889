import styled from "styled-components";
import { useState } from "react"


const ProviderApp = (props) => {

return (
<Container>
    <h1>COMING SOON</h1>
</Container>
)

}
export default ProviderApp;


const Container = styled.section`
overflow: hidden;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
min-height: 100vh;
min-height: 100dvh;
background-color: black;

.h1 {
    color: white;
}
`;