import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const PopupContainer = styled.div`
  display: flex;
  position: fixed;
  padding: 10px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  z-index: 1000;
  transition: opacity 0.2s ease-in-out;
  max-width: 50vw;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
`;

const ImageContainer = styled.div`
  flex: 0 1 calc(25% - 15px);
  max-width: 25%;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
`;

const PopupImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  display: ${(props) => (props.src ? 'block' : 'none')};
`;

const ContentContainer = styled.div`
  flex: 0 1 calc(75% - 15px);
  max-width: 75%;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
`;

const PopupTitle = styled.h4`
color: black;`;

const PopupDescription = styled.p`
  color: #00000080;
`;

const PositionPopupWithCSS = (hotspot, popup) => {
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  const popupRect = popup.getBoundingClientRect();

  let leftPosition, topPosition;

  if (hotspot.globalX < viewportWidth / 2) {
    leftPosition = hotspot.globalX + 35; // 10px padding + 25px width
  } else {
    leftPosition = hotspot.globalX - popupRect.width - 10;
  }

  if (hotspot.globalY < viewportHeight / 2) {
    topPosition = hotspot.globalY + 35; // 10px padding + 25px height
  } else {
    topPosition = hotspot.globalY - popupRect.height - 10;
  }

  leftPosition = Math.max(10, Math.min(leftPosition, viewportWidth - popupRect.width - 10));
  topPosition = Math.max(10, Math.min(topPosition, viewportHeight - popupRect.height - 10));

  return {
    left: `${leftPosition}px`,
    top: `${topPosition}px`,
    opacity: 1,
  };
};

const HoverImagePopup = ({ hotspot }) => {
    
  const popupRef = useRef(null);
  const [style, setStyle] = useState({});

  useEffect(() => {
    if (hotspot && popupRef.current) {
      const popupStyle = PositionPopupWithCSS(hotspot, popupRef.current);
      setStyle(popupStyle);
    }
  }, [hotspot]);

  if (!hotspot) {
    return null;
  }

  return ReactDOM.createPortal(
    <PopupContainer ref={popupRef} style={style}>
      <ImageContainer>
        <PopupImage src={hotspot.image} alt="Popup Image" />
      </ImageContainer>
      <ContentContainer>
        <PopupTitle>{hotspot.title}</PopupTitle>
        <PopupDescription>{hotspot.description}</PopupDescription>
      </ContentContainer>
    </PopupContainer>,
    document.body // Render the popup at the root of the DOM
  );
};

export default HoverImagePopup;
