import styled from 'styled-components';
import { useState } from "react"

import TextInputContainer from 'components/Inputs/TextInputContainer';
import PhoneInputContainer from 'components/Inputs/PhoneInputContainer';


import TextField from '@mui/material/TextField';

const CheckoutUserInfo = ({user, handleInputChange}) => {

    const [error, setError] = useState(false);

    // console.log("CHECKOUTLOCATION Info: ", user)
    // Local state initialized with user object values
    const [localUser, setLocalUser] = useState({
        name: user.name || '',
        email: user.email || '',
        phone: user.phone || ''
    });

    // Update local state and trigger parent handler on input change
    const handleLocalChange = (e) => {
        const { id, value } = e.target;

        // Update local state
        const updatedUser = { ...localUser, [id]: value };
        setLocalUser(updatedUser);

        // Call parent handler to update the global state
        handleInputChange(e, value);  // you can pass updatedUser if needed
    };

    console.group("CheckoutUserInfo.user",user)
    console.log("CheckoutUserInfo.localUser",localUser)
    console.groupEnd()

    return (
        <Container>
            <ContentContainer>
                <DateContainer>
                    <DetailsSection>

                            <TextField
                                id = "name"
                                label="Name"
                                type="name"
                                variant="outlined"
                                value={localUser.name}
                                onChange={handleLocalChange}
                                error={error}
                                helperText={error ? "This field is required" : ""}
                                fullWidth
                                size="small"
                                margin="normal"
                                required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        <TextField
                            id='email'
                            label="Email"
                            type="email"
                            variant="outlined"
                            value={localUser.email}
                            onChange={handleLocalChange}
                            error={error}
                            helperText={error ? "Please enter a valid email address" : ""}
                            fullWidth
                            size="small"
                            margin="normal"
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                            <TextField
                                id="phone"
                                label="Phone"
                                type="tel"
                                variant="outlined"
                                value={localUser.phone}
                                onChange={handleLocalChange}
                                fullWidth
                                size="small"
                                margin="normal"
                                required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        
                    </DetailsSection>
                </DateContainer>
            </ContentContainer>

        </Container>
        );

}

export default CheckoutUserInfo;

const Container = styled.div`
display: flex;
flex-direction: column;`


const ContentContainer = styled.div`
padding: 7.5px 0px;
display: flex;
width: 100%;
gap: 10px;
// border-bottom: 1px solid black;
cursor: pointer;
align-items: start;
color: black;

// &:hover{
//     background-color: #00000010;
// }
`

const DateContainer = styled.div`
display: flex;
width: 100%;
gap: 10px;
cursor: pointer;
align-items: center;
color: black;

// padding: 7.5px 0px;
// border-bottom: 1px solid black;
// &:hover{
//     background-color: #00000010;
// }
`
const DetailsSection = styled.div`
display: flex;
  flex-direction: column;
  width: 100%;
//   flex: 0 0 calc(50% - 50px);
  // padding-bottom: 1em;
  justify-content: center;




  p {
    /* Default color, overridden by enabled/disabled styles */
    color:#00000060;
    margin: 0px;
  }
    h4{
    margin: 0px;
    }

  @media (max-width: 768px) {
    flex: 0 0 100%;
  }

`;

const InputContainer = styled.div`
margin: 0px 0px 15px 0px;
`