// import axios, { AxiosError } from 'axios';
// // import getConfig from 'api/config';
// import { getConfig } from 'api/APIServices';

// const AxiosService = axios.create({
//     baseURL: getConfig(),
//     timeout: 10000,
// });

// export default AxiosService

import axios from 'axios';

// Define the configuration directly here
const config = {
    production: {
        apiBaseUrl: "http://appspot.goghnow/api/",
    },
    development: {
        apiBaseUrl: "http://127.0.0.1:8082/user/",
    },
};

const getConfig = () => {
    const env = process.env.NODE_ENV || 'development';
    return config[env];
};

// Create an instance of axios with base URL
const AxiosService = axios.create({
    baseURL: getConfig().apiBaseUrl,
    timeout: 10000,
});

export default AxiosService;