// const API_BASE_URL = 'https://api.example.com';
import { get, post, put } from 'api/APIServices'; // Assuming your `apiCalls.js` file is in the same directory

//CUSTOM TO SERVICES
import ApiRoute from 'api/Business/ApiRoutes';

//class
import { ObjectUserStaging } from 'model/objectUserStaging';

//TYPE ALIAS JOINT
type SignUpBoolResult = boolean;
type AccessCodeResult = string;
type TempAuthResult = string;
type SignupResult = string; //TODO: Update string to object

//URLS
let urlSignUpValidateInviteCode = "signup/invitecode/validate";
let urlSignUpGetAccessCode =  "signup/access_code/get";///signup with the phone number
let urlSignUpConfirmAccessCode =  "signup/access_code/confirm";///send access code back to server
let urlSignUpSaveCreatedAccount = "signup/save";

let getProfile:(id: string) => "profile/get/${id}";

//API LINKS
class SignupServiceAPI {

    validateInviteCode = async(inviteCode:string): Promise<SignUpBoolResult> =>{
        try {
          const url = urlSignUpValidateInviteCode
          const params =  { invite_code: inviteCode }
          const response = await get(url,params);
          return response.data //.success; // Assuming the API returns a JSON with a 'success' boolean
        } catch (error) {
          console.error('Error validating invite code:', error);
          throw error; // Re-throw the error for handling in the calling component
        }
      }
    
    getAccessCode = async(phoneNumber:string): Promise<AccessCodeResult> => {
        try {
          const url = urlSignUpGetAccessCode
          const params = { phone_number: phoneNumber }
          console.log("getAccessCodeHELP: ",url)
          const response = await get(url, params);
          return response.data //.access_code; // Assuming the API returns a JSON with an 'access_code' string
        } catch (error) {
          console.error('Error getting access code:', error);
          throw error;
        }
      }
    
    confirmAccessCode = async(phoneNumber:string, accessCode:string): Promise<TempAuthResult> => {
        try {
          const url = urlSignUpConfirmAccessCode
          const params = { phone_number: phoneNumber, access_code: accessCode }
          const response = await get(url, params);
          return response.data //.token; // Assuming the API returns a JSON with a 'token' string
        } catch (error) {
          console.error('Error confirming access code:', error);
          throw error;
        }
      }
    
    saveAccountInfo = async(phoneNumber:string, accessCode:string, inviteCode:string, password:string, firstName:string, lastName:string, email:string): Promise<SignupResult> => {
        try {

          const url = urlSignUpSaveCreatedAccount
          const params = {
            phone_number: phoneNumber,
            access_code: accessCode,
            invite_code: inviteCode,
            password: password,
            first_name: firstName,
            last_name: lastName,
            email: email,
          }
          const response = await post(url, params);
          return response.data //.profile; // Assuming the API returns a JSON with a 'profile' object
        } catch (error) {
          console.error('Error saving account info:', error);
          throw error;
        }
      }


    }
    
    export default SignupServiceAPI;
