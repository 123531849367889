import styled from 'styled-components';
import { NAVBAR_HEIGHT, SECTION_PADDING } from 'components/constants';



const PackageItem = ({item, index}) => {


    return (
        <Container>
            <ImageContainer>
                <img src={item.image} alt={item.alt} />
            </ImageContainer>
            <Description>
                {item.text}
            </Description>
        </Container>
    )
}
export default PackageItem;


const Container = styled.div`
display: flex;
flex-direction: column;
width: 100%;
`

const ImageContainer =  styled.div`
    width: 100%; /* Fills available space within the grid item */
    aspect-ratio: 1 / 1; /* Sets aspect ratio to 1:1 (consider browser support) */
    overflow: hidden; /* Hides overflowing content (optional) */

    img {
        display: block;
        width:100%;
        height:100%;
        background-color: #00000040;
    }
`


const Title = styled.h2`
text-align: left;
`
const Description = styled.p`
font-size: 12px;
font-weight: 100;
color: #AAAAAA;
`