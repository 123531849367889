
import styled from 'styled-components';
import CheckoutServicesItem from "./checkoutServicesComponents/checkoutServicesItem";
import { NAVBAR_HEIGHT, SECTION_PADDING } from "components/constants";
// import { ActionButton } from 'components/standard';
//Context


const CheckoutServices = ({items, onSelect}) => {
    

    // console.log('CHeckoutServices: ', items)
    return (
        <Container>
            {/* <CartPanel> */}
                <BodyPanel>
                    {/* <PanelTitle>Checkout</PanelTitle> */}
                    <PanelDescription>Event services to be fulfilled</PanelDescription>
                    <CartMenuItemsContainer>
                        {items.map((item, index) => (
                        <CheckoutServicesItem key={index} item={item} />
                        ))}
                    </CartMenuItemsContainer>
                </BodyPanel>
                {/* <FooterPanel>
                    <CheckOutButton onClick={submitItems}>({countCartItems()}) Checkout</CheckOutButton> 
                </FooterPanel> */}
            {/* </CartPanel> */}
        </Container>
        );
        // ({countCartItems()})
}

export default CheckoutServices;

const Container = styled.div`
display: flex;
flex-direction: column;
width: calc(100% - 25px * 2);
// margin: 25px;
`
const PanelTitle = styled.h1`
    color: black;
    margin-top: 0px;
    margin-bottom: 0px;
`
const PanelDescription = styled.p`
    color: #000000;
    margin-top: 0px;
    margin-bottom: 0px;
`
const BodyPanel = styled.div`
display: flex;
flex-direction: column;
height: auto;
// min-height: calc(100% - ${NAVBAR_HEIGHT});
width: 100%;
overflow-y: scroll;
// flex-wrap: wrap;
flex-grow:1;
gap: 0px;
`

const CartMenuItemsContainer = styled.div`
display: flex;
// padding: 25px 0px;
width: 100%;
flex-wrap: wrap;
gap: 0px;

button {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    font-weight: 600;
    font-size: 1.5em;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
        background-color: #CCCCCC;
    }
}
`

// const FooterPanel = styled.div`
// display: flex;
// justify-content: center;
// align-items: center;
// height: ${NAVBAR_HEIGHT};
// // background-color: #CCC;
// `

// const CheckOutButton = styled(ActionButton)`
// width: 80% !important;
// padding: 0px !important;
// margin: 0px !important;
// justify-content: center;
// `

