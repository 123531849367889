import React from 'react';
import styled from '@emotion/styled';

// Use capitalized names for styled components
const OrSeparator = ({className}) => {
    return (
        <SeparatorDIV className={className}>
            <HrCustom />
            <SpanCustom>OR</SpanCustom>
            <HrCustom />
        </SeparatorDIV>
    );
};

export default OrSeparator;

const SeparatorDIV = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const HrCustom = styled.hr`
    flex-grow: 1;
    border: none;
    border-top: 1px solid #ccc;
    margin: 0;
`;

const SpanCustom = styled.span`
    padding: 0 10px;
    white-space: nowrap;
    color: #ccc;
`;
