//React
import React, {useEffect, useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

//Redux

//Components
import {PageContentContainer} from "components/Page/Elements"

const BusinessAppContent = (props) => {

    //navigate
    const navigate = useNavigate();
    const [initialLoad, setInitialLoad] = useState(true); // Track if it's the first load

    //refs
    const pageContentRef = useRef(null);  // Ref for PageContentContainer

    return (
    <>
    <PageContentContainer ref={pageContentRef}>
        <h1>this is busienss App</h1>
    </PageContentContainer>
    </>
    )

}
export default BusinessAppContent;