import { BaseModel } from "./base";


export interface ObjectUserStaging {
    id?: string;
    phone_number?: string;
    category?: string;
    invite_code?: string;
    access_code?: string;
    expiration?: Date;
    status?: string;
    status_date?: Date;
    firebase_user_id?: string;

    first_name?: string;
    last_name?: string;
    email?: string;
    password?: string;

    [key: string]: any; // Or a more specific type if known

  }
  
export class ObjectUserStagingClass extends BaseModel implements ObjectUserStaging {
    id?: string;
    phone_number?: string;
    category?: string;
    invite_code?: string;
    access_code?: string;
    expiration?: Date;
    status?: string;
    status_date?: Date;
    firebase_user_id?: string;

    first_name?: string;
    last_name?: string;
    email?: string;
    password?: string;

    [key: string]: any; // Or a more specific type if known

    constructor(data: Partial<ObjectUserStaging> = {}) {
      super(data); // Call the constructor of BaseModel with 'data'
      this.id = data.id;
      this.phone_number = data.phone_number;
      this.category = data.category;
      this.invite_code = data.invite_code;
      this.access_code = data.access_code;
      this.expiration = data.expiration ? new Date(data.expiration) : undefined;
      this.status = data.status;
      this.status_date = data.status_date ? new Date(data.status_date) : undefined;
      this.firebase_user_id = data.firebase_user_id;

      this.first_name = data.first_name;
      this.last_name = data.last_name;
      this.email = data.email;
      this.password = data.password;
    }
  }
  