// InputStyles.js
export const textFieldStyle = {
    '& .MuiInputBase-root': {
        height: '60px', // Set the height of the input base
    },
    '& .MuiOutlinedInput-root': {
        '& input': {
            height: '100%', // Ensures the input text is centered
            padding: '0px 14px', // Adjust padding as necessary
            boxSizing: 'border-box', // Makes sure padding doesn't increase the height
        },
        '& fieldset': {
            borderColor: 'default', // Default border color
        },
        '&:hover fieldset': {
            borderColor: '#CCC', // Border color on hover
        },
        '&.Mui-focused fieldset': {
            borderColor: '#FBAE62', // Orange color when focused
            boxShadow: '0 0 10px rgba(251, 174, 98, 0.5)',
        },
    },
    '& .MuiInputLabel-root': {
        '&.Mui-focused': {
            color: '#FBAE62', // Change label color to orange when focused
            boxShadow: '0 0 10px rgba(251, 174, 98, 0.5)',
        },
    },
};