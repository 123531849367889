// RequestContext.js
import React, { createContext, useContext, useState } from 'react';
import {emailSignupAPI} from './UserServiceAPI'

const UserServiceAPIContext = createContext();

export const useUserServiceContext = () => {
  return useContext(UserServiceAPIContext);
};

export const UserServiceAPIProvider = ({ children }) => {

  const [emailSignupResult, setEmailSignupResult] = useState(); // Updated to an object to manage multiple errors

//USER
const emailSignup = async (data) => {
  console.log("submitting emailSignup: ",data)
  const result = await emailSignupAPI(data);
  setEmailSignupResult(result) //set result  here, i dont need to save
  return result;
};


  return (
    <UserServiceAPIContext.Provider
      value={{
        emailSignupResult, emailSignup }}
    >
      {children}
    </UserServiceAPIContext.Provider>
  );
};












    // if (result.data){
        // setData((prevData) => ({ ...prevData, createRequest: data }));
    // }
    // else{
        // setError((prevError) => ({ ...prevError, createRequest: error }));
    // }