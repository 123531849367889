import React from 'react';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import { textFieldStyle } from 'components/Inputs/InputStyles';
import PhoneInputContainer from 'components/Inputs/PhoneInputContainer';

import { ObjectUserStaging, ObjectUserStagingClass } from 'model/objectUserStaging';


interface SignupPhoneAccessCodeProps {
    data? : ObjectUserStaging; // Optional data object (consider refining if data structure is known)
    field? : string; // Optional field name (default to 'access_code')
    onChange: (value: string, field: string) => void; // Required change handler
    placeholder?: string; // Optional placeholder text
  }

  
const SignupPhoneAccessCode: React.FC<SignupPhoneAccessCodeProps> = ({data = new ObjectUserStagingClass(), field = 'access_code' , onChange, placeholder }) => {

    const fieldValue = data[field] || '';

    return (
            <TextFieldCustom
                id={field}
                label="Access Code"
                value={fieldValue}
                onChange={(e) => onChange(e.target.value, field)}
                placeholder={placeholder || "Access Code"}
                sx={textFieldStyle}
            />
    )

}

export default SignupPhoneAccessCode

const TextFieldCustom = styled(TextField)`
width: 100%;
`