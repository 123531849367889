import { toJSON } from 'api/APIFunctions';//fromJSON
import { businessServiceSignUpRoute } from './BusinessServiceRoutes';



//USER
export const serviceSignupAPI = async(data) => {

    console.log('SUBMIT serviceSignupAPI: ', data)

    const item = {
        'company':data?.company || '',
        'firstname':data?.firstname || '',
        'lastname':data?.lastname || '',
        'email': data?.email || '',
        'phone':data?.phone || '',
        'detail':data?.details || '',
        'address':data?.address || '',
        'lat':data?.lat || '',
        'lng':data?.lng || '',
        'created': new Date(),
        }
    
    const JSONdata = toJSON(item)
    console.log('JSON: ', JSONdata)
    
    try {
        const response = await fetch(businessServiceSignUpRoute, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSONdata,
          });

        // Check response headers and status
        console.log('Response Headers: ', response.headers);
        console.log('Content-Type: ', response.headers.get('Content-Type'));
        console.log('Response Status: ', response.status);

          //FAILED
          if(!response.ok){
            const errorMessage = await response.text();
            throw new Error(errorMessage || 'Request failed');
          }

        //SUCCESS
        console.log("Success")

        // Log the full response text
        const responseText = await response.text();
        console.log('Full Response Text:', responseText);
        
        // Check if the response is actually JSON
        try {
        const data = JSON.parse(responseText);
        console.log('Data from PHP:', data);
        return { data, error: null };
        } catch (parseError) {
            console.error('Error parsing JSON:', parseError);
            throw new Error('Invalid JSON response');
        }     
    }
    catch (error) {
        //CAUGHT ERROR
        return {data: null, error};
    }
}