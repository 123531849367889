import { 
            SEARCH_RESET_CRITERIA,
            SEARCH_UPDATE_CRITERIA,
            SEARCH_UPDATE_TEMP_CRITERIA,
            SEARCH_SET_LOCATION,
            SEARCH_SET_DATE,
            SEARCH_SET_TIME, 
            SEARCH_SET_DURATION,
            SEARCH_UPDATE_RESULTS,
        } from "third_party/redux/actionTypes";

import { useDispatch } from 'react-redux';

//#################################
//########Search Actions########
//#################################

// Action to reset search search critera

//-----------
//SEARCH
//-----------
export const resetSearchCriteria = () => ({
    type: SEARCH_RESET_CRITERIA,
});

//UPDATES SEARCH CRITERIA WITH ITEM IN Parenthesis
export const updateSearchCriteria = (searchCriteria) => ({
    type: SEARCH_UPDATE_CRITERIA,
    payload: searchCriteria,
});

//UPDATES TEMP SEARCH CRITERIA WITH ITEM IN Parenthesis
export const updateSearchCriteriaTemp = (tempSearchCriteria) => ({
    type: SEARCH_UPDATE_TEMP_CRITERIA,
    payload: tempSearchCriteria
});

//-----------
//TEMP
//-----------

// Action to update search location (likely a google location)
export const updateSearchLocation = (location) => ({
    type: SEARCH_SET_LOCATION,
    payload: location,
});

// Action to update search location (likely a google location)
export const updateSearchDate = (date) => ({
    type: SEARCH_SET_DATE,
    payload: date,
});

// Action to update search time (likely a google location)
export const updateSearchTime = (time) => ({
    type: SEARCH_SET_TIME,
    payload: time,
});


// Action to update search duration
export const updateSearchDuration = (duration) => ({
    type: SEARCH_SET_DURATION,
    payload: duration,
});


//----------------
//RESULT
//----------------

// Action to set search results
export const updateSearchResults = (results) => ({
    type: SEARCH_UPDATE_RESULTS,
    payload: results,
});







//=============================
// Custom hook for search actions
//=============================
export const useSearchActions = () => {
    const dispatch = useDispatch();

    const SEARCH_RESET = (item) => {
        dispatch(resetSearchCriteria(item));
    };

    const SEARCH_UPDATE = (searchCriteria) => {
        dispatch(updateSearchCriteria(searchCriteria));
    };

    const SEARCH_UPDATE_TEMP = (searchCriteria) => {
        dispatch(updateSearchCriteriaTemp(searchCriteria));
    };

    const SEARCH_LOCATION = (location) => {
        dispatch(updateSearchLocation(location));
    };

    const SEARCH_DATE = (date) => {
        dispatch(updateSearchDate(date));
    };

    const SEARCH_TIME = (time) => {
        dispatch(updateSearchTime(time));
    };

    const SEARCH_DURATION = (duration) => {
        dispatch(updateSearchDuration(duration));
    };

    const SEARCH_RESULTS_UPDATE = (results) => {
        dispatch(updateSearchResults(results));
    }

    return {
        SEARCH_RESET,
        SEARCH_UPDATE,
        SEARCH_UPDATE_TEMP,
        SEARCH_LOCATION,
        SEARCH_DATE,
        SEARCH_TIME,
        SEARCH_DURATION,
        SEARCH_RESULTS_UPDATE
    };
};