import React from 'react';
import styled from '@emotion/styled';
// import { useScreenContext } from 'context/AppContext/ScreenContext';

const Panel = ({ children, ...props }) => {

    return (
        <CTA {...props}>
            {children}
        </CTA>
    );
};

export default Panel;

const CTA = styled.div`
    width: ${props => {
        if (props.$isMobile) return '100vw';
        if (props.$isMedium) return '350px';
        if (props.$isLarge) return '350px';
        return '350px'; // Default to large if none match
    }}; 
    height: ${props => {
        if (props.$isMobile) return '100vh';
        if (props.$isMedium) return 'auto';
        if (props.$isLarge) return 'auto';
        return 'auto'; // Default to large if none match
    }}; 
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-right: auto;
    margin-left: auto;
    transition-timing-function: ease-out;
    transition: opacity 0.2s;
    display: flex;
    background-color: white;
    padding: 50px 50px;
    border-radius: 5px;
    border: solid 1px #CCC;
    overflow-y: scroll;
`;