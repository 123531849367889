//Helmet
import { Helmet } from 'react-helmet';

//Providers

//Common Components
import { PageContainer } from "components/Page/Elements";
import {PageContentContainer} from "components/Page/Elements"
// import styled from "styled-components";
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {mobileDisplayContentVertical,mobileDisplayContentHorizontal, mobileSizeDisplayNone, mobileSizeDisplayBlock, mobileSizeDisplayFlex} from 'components/emotionStyles'

import {useState, useEffect, useRef } from "react";
import { useNavigate, Link, useLocation } from 'react-router-dom';

//this is to get access to popup menu
import GeneralSignUpMenu, { useGeneralSignUpMenuContext } from "components/SignUpMenu/GeneralSignUpMenu";

import './css/home-styles.css'; // Assuming your styles are defined here
import config from 'config'
import InteractiveImage from "components/InteractiveImage/InteractiveImage";
import FAQManager from "components/FAQComponents/FAQManager";
import {AddressSearchComponent} from "components/Inputs/AddressSearchComponent"

// import InputContainer from "components/Inputs/InputContainer"
import TextField from '@mui/material/TextField';

import { NAVBAR_HEIGHT } from "components/constants";
import { CALLTOACTION, SECTION } from "components/standard";

//JUST ADDED 
import NavBarLogout from "./header/NavBarLogout"

//NEW
import { useUserActions } from 'third_party/redux/user/userAction';
import { useAuthActions } from 'third_party/redux/auth/authActions';
import { useSearchActions } from 'third_party/redux/search/searchActions';
import { useGuestLoginWithPlace } from 'third_party/redux/convenientFunctions';

import GoogleMapsFunctions from 'utility/googleMapsFunctions';
import { useUserServiceContext } from 'api/User/UserService/UserServiceAPIContext';

import SignUpComponent from './components/SignUpComponent/SignUpComponent';

import CheckoutSuccess from 'pages/book/checkoutcomponents/checkoutSuccess';

import { useScreenContext } from 'context/AppContext/ScreenContext';
import Signup from 'pages/signup/Signup';

//VARIABLES

const imageHotspots = [
    { x: 2150/4096, y: 750/4096, title: "Balloon Garland", description: "A festive balloon garland that brightens any event, installed by its creator to ensure a perfect setting for celebrations", image: "/images/cta-logo-one.png" },
    { x: 1750/4096, y: 2380/4096, title: "DJ", description: "A professional DJ setup tailored to your event, with the provider ensuring a seamless musical experience from start to finish.", image: "/images/cta-logo-one.png" },
    { x: 650/4096, y: 2150/4096, title: "Painting", description: "A striking painting that adds artistic flair to your space, delivered and positioned by the artist for maximum impact", image: "/images/cta-logo-one.png" },
    { x: 2600/4096, y: 2660/4096, title: "Floral Arrangement", description: "Elegant floral arrangements that transform your event, meticulously arranged and set up by the florist.", image: "/images/cta-logo-one.png" },
    { x: 3400/4096, y: 2100/4096, title: "Selfie Wall", description: "An engaging selfie wall designed for memorable moments, curated and installed to enhance your event's social media appeal.", image: "/images/cta-logo-one.png" },
  ];

  const questionsAndAnswers = [
    { question: 'What is your return policy?', answer: 'Our return policy is ...' },
    { question: 'How long does shipping take?', answer: 'Shipping takes ...' },
    { question: 'Where are you located?', answer: 'We are located in ...' },
    // Add more questions and answers as needed
  ];



  const events = [
    'Charlotte, NC', 'Washington, DC', 'Chicago, IL', 'New York City, NY',
    'Miami, FL', 'Boston, MA', 'Atlanta, GA', 'Los Angeles, CA','Raleigh, NC','Richmond, VA','Charleston, SC'
  ];

//ELEMENT
const Home = (props) => {

      //contentRef
      const contentRef = useRef();

    //navigation
    const navigate = useNavigate();
    const location = useLocation();
    
    const scrollToTop =() =>{
        console.log("this is working?")
        setTimeout(() => contentRef.current?.scrollTo({ top: 0, behavior: 'smooth' }), 10);
    } 

  

    //Context
    //NEW USE & AUTH
    const {USER_UPDATE} = useUserActions()
    const {AUTH_LOGIN_GUEST} = useAuthActions()
    const {SEARCH_UPDATE} = useSearchActions()
    const loginAsGuestWithPlace = useGuestLoginWithPlace()

    const {isMenuOpen, toggleMenu, closeMenu} = useGeneralSignUpMenuContext()
    const {emailSignup} = useUserServiceContext()
    //-Context: Google Maps

    //VARIABLES
    const searchInput = useRef();
    const [isFocused, setIsFocused] = useState(false);

    
    const {isMobile, isMedium, isLarge} = useScreenContext()


  
      
      //KEEP THIS BROTHER

      const handleProviderJoinClick = () => {
        navigate('/Provider'); //Animate the Join Now
      };

// Function to handle search submit
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchSubmit = async (address) => {

    console.log("Get Place From Address: ", address)
    try{
        const place = await GoogleMapsFunctions.getPlaceFromAddress({address:address});
        if (place){
            console.log('handleSearchSubmit (place returned is):', place);
            // LOGIN_AS_GUEST_WITH_PLACE(place)
            loginAsGuestWithPlace(place)
        }
        else {
            //do nothing
            console.log("no place was found")
        }
    }
    catch(error){
        console.error('Error fetching place details:', error);
    }
  };

  // Function to handle place selection
  const handlePlaceSelected = (place) => {
    console.log('Place Was Selected:', place);
    // loginAsGuestWithSelectedLocation(place)
    // LOGIN_AS_GUEST_WITH_PLACE(place)
    loginAsGuestWithPlace(place)
};

// const LOGIN_AS_GUEST_WITH_PLACE = (place) => {
//     const userUpdate = {lat:place.lat, lng:place.lng}
//     USER_UPDATE(userUpdate)
//     SEARCH_UPDATE({location:place})
//     AUTH_LOGIN_GUEST() //logs in
// }


//ALL SHIT
const [error, setError] = useState(false);
const formRef = useRef(null)
const [data, setData] = useState({})
const [showSuccessPopup, setShowSuccessPopup] = useState(false)

const [isSubmitting, setIsSubmitting] = useState(false)
const [status, setStatus] = useState('')


    const onSignupSubmit = async(data) => {


        setIsSubmitting(true)        
        console.log('Form is  valid. and we should submit: ', data);

        try{
            //data is being submitted
            const result = await emailSignup(data);
            console.log('submit email:', result);
            setShowSuccessPopup(true);
            setIsSubmitting(false);        
            setStatus('success');
        }
        catch (error){
            setIsSubmitting(false)        
            setStatus('error: ',error)

            console.error('Request failed:', error);
            // Show popup or any other error handling logic
            //error.message is how you access it
        }

    };

    const submitEmail = async () => {
        try{
            //data is being submitted
            const result = await emailSignup(data)
            console.log('submit email:', result);
            setShowSuccessPopup(true);
        }
        catch (error){
            console.error('Request failed:', error);
            // Show popup or any other error handling logic
            //error.message is how you access it
        }
      };

      const navigateToBusinessPage = () => {
        navigate('/Business');
      }

      const navigateToProviderPage = () => {
        navigate('/Provider');
      }

      const inputChange = (inputValue) => {
        console.log("new input: ",inputValue)
        GoogleMapsFunctions.fetchPredictions(inputValue)
        .then(predictions => {
          console.log('Predictions:', predictions);
        })
        .catch(error => {
          console.error('Error fetching predictions:', error);
        });
      }
    
return (
    <>
    <PageContainer className="page-container">
    <Helmet>
        <title>GoghNow | Explore Event Services Near You</title>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="GoghNow | The Future of Events" />
        <meta name="description" content="GoghNow is a one-stop-shop for event services" />
        <meta property="og:image" content="images/cta-logo-one.png" />
    </Helmet>
    <NavBarLogout handleNavigateHome={scrollToTop}/>
    <PageContentContainer className="content" ref={contentRef}>

        <HeroSection>
        <HeroSectionOverlay>
            <CTA>
                <HeroDescription>LOCAL PEOPLE, LOCAL SERVICES</HeroDescription>
                <HeroText>Discover Event Services Near You</HeroText>
                <AddressSearchComponent onSearchSubmit={handleSearchSubmit} onPlaceSelected={handlePlaceSelected} onChange={inputChange}/>
                {/* <ActionButton>Sign In</ActionButton> */}
            </CTA>
        </HeroSectionOverlay>
        </HeroSection>



        <OccasionsSection>
            <OccasionsHeroText>GoghNow for every occasion</OccasionsHeroText>
            <OccasionsContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                <Occasions>
                    <OccasionsImageContainer>
                        <img src='/images/Home/celebrate.png' alt="provider" />
                    </OccasionsImageContainer>
                    <OccasionsContentContainer>
                        <OccasionsTitle>Create Moments That Last</OccasionsTitle>
                        <OccasionsDescription>From intimate gatherings to grand celebrations, find everything you need.</OccasionsDescription>
                    </OccasionsContentContainer>
                </Occasions>
                <Occasions>
                    <OccasionsImageContainer>
                        <img src='/images/Home/discover.png' alt="provider" />
                    </OccasionsImageContainer>
                    <OccasionsContentContainer>
                        <OccasionsTitle>Instant Access, Endless Options</OccasionsTitle>
                        <OccasionsDescription>Forget endless calls and emails. Explore local services at your fingertips.</OccasionsDescription>
                    </OccasionsContentContainer>
                </Occasions>
                <Occasions>
                    <OccasionsImageContainer>
                        <img src='/images/Home/plan.png' alt="provider" />
                    </OccasionsImageContainer>
                    <OccasionsContentContainer>
                        <OccasionsTitle>Gather Effortlessly, Celebrate More</OccasionsTitle>
                        <OccasionsDescription> Easily plan ahead or book on-the-go.</OccasionsDescription>
                    </OccasionsContentContainer>
                </Occasions>
                <Occasions>
                    <OccasionsImageContainer>
                        <img src='/images/Home/select.png' alt="provider" />
                    </OccasionsImageContainer>
                    <OccasionsContentContainer>
                        <OccasionsTitle>Dream Big, Stress Less</OccasionsTitle>
                        <OccasionsDescription>Browse local services and secure the essentials to bring your event to life.</OccasionsDescription>
                    </OccasionsContentContainer>
                </Occasions>

            </OccasionsContainer>
        </OccasionsSection>





        {/* <OfferingsSection>
            <OfferingContainer>
                <Offering>
                    {(isLarge) && 
                        <OfferingSubHeader>Become a User</OfferingSubHeader>
                    }
                    <OfferingImageContainer>
                        <img src='/images/Home/uimage1.png' alt="provider" />
                    </OfferingImageContainer>
                    <OfferingContentContainer>
                            {(isMobile || isMedium) && 
                                <OfferingSubHeader>Become a User</OfferingSubHeader>
                            }
                        <OfferingDescription>simply select the services you need to make your next event unique.</OfferingDescription>
                        <OfferingLink onClick={scrollToTop}>Try it today →</OfferingLink>
                    </OfferingContentContainer>
                </Offering>
                <Offering>
                    {(isLarge) && 
                        <OfferingSubHeader>Become a Provider</OfferingSubHeader>
                     }
                    <OfferingImageContainer>
                        <img src='/images/Home/pimage1.png' alt="provider" />
                    </OfferingImageContainer>
                    <OfferingContentContainer>
                        {(isMobile || isMedium) && 
                            <OfferingSubHeader>Become a Providers</OfferingSubHeader>
                        }
                        <OfferingDescription>Offer unique services or repurpose old products directly to customers.</OfferingDescription>
                        <OfferingNoLink>Coming Soon</OfferingNoLink>
                    </OfferingContentContainer>

                </Offering>
                <Offering>
                    {(isLarge) && 
                        <OfferingSubHeader>GoghNow for Businesses</OfferingSubHeader>
                    }   
                    <OfferingImageContainer>
                        <img src='/images/Home/image1.png' alt="provider" />
                    </OfferingImageContainer>

                    <OfferingContentContainer>
                        {(isMobile || isMedium) && 
                         <OfferingSubHeader>GoghNow for Businesses</OfferingSubHeader>
                        }
                        <OfferingDescription>Simplify work events, and/or offer event services to your clients!</OfferingDescription>
                        <OfferingLink onClick={navigateToBusinessPage}>Earn as a business →</OfferingLink>
                    </OfferingContentContainer>

                </Offering>
            </OfferingContainer>
        </OfferingsSection> */}





        {/* <GoghNowSection>
            <GoghNowContainer>
                <GoghNowContent>
                    <DesktopContent>
                        <GoghNowImageContainer>
                            <InteractiveImage src='/images/Home/goghnow-example.jpeg' alt="user" initialHotspots={imageHotspots} />
                        </GoghNowImageContainer>
                    </DesktopContent>
                </GoghNowContent>
                <GoghNowContent>
                    <GoghNowHeader>Everything you imagined, in one place</GoghNowHeader>
                    <GoghNowSubHeader>Your favorite local providers.</GoghNowSubHeader>
                    <MobileContent>
                        <GoghNowImageContainer>
                            <InteractiveImage src='/images/Home/goghnow-example.jpeg' alt="user" initialHotspots={imageHotspots} />
                        </GoghNowImageContainer>
                    </MobileContent>
                    <GoghNowDescription>book a unique musician to set the mood, decor to add to the experience, and/or a photographer to capture it all!</GoghNowDescription>
                </GoghNowContent>
            </GoghNowContainer>
        </GoghNowSection> */}

        <GoghNowSection>
            <GoghNowContainerODD>
                <GoghNowContent>
                    <GoghNowHeaderType>PROVIDERS</GoghNowHeaderType>
                    <GoghNowHeader>Sell what you love, when you want</GoghNowHeader>
                    <GoghNowDescription>Provide event services, or rent the event products/decor that you own. Join our thriving marketplace.</GoghNowDescription>
                    <OfferingLink onClick={navigateToProviderPage}>Get started →</OfferingLink>
                    <MobileContent>
                        <GoghNowContentExpanded>
                            <ProviderImageContainer>
                                <img src='/images/Home/pimage1.png' alt="provider" />
                            </ProviderImageContainer>
                            {/* <QuarterImageContainer>
                                <ImageContainer src='/images/Home/provider5.png'  alt="provider"/>
                                <ImageContainer src='/images/Home/provider2.png'  alt="provider"/>
                                <ImageContainer src='/images/Home/provider3.png'  alt="provider"/>
                                <ImageContainer src='/images/Home/provider4.png'  alt="provider"/>
                            </QuarterImageContainer> */}
                        </GoghNowContentExpanded>
                    </MobileContent>
                    {/* <GoghNowDescription>Join our thriving marketplace and connect with users looking for unique experiences.  Offer what you love — art exhibits, balloons, décor, music, and more — all on your terms.</GoghNowDescription> */}
                </GoghNowContent>
                <DesktopContent>
                    <GoghNowContent>
                        <ProviderImageContainer>
                            <img src='/images/Home/pimage1.png' alt="provider" />
                        </ProviderImageContainer>
                        {/* <QuarterImageContainer>
                            <ImageContainer src='/images/Home/provider1.png'  alt="provider"/>
                            <ImageContainer src='/images/Home/provider2.png'  alt="provider"/>
                            <ImageContainer src='/images/Home/provider3.png'  alt="provider"/>
                            <ImageContainer src='/images/Home/provider5.png'  alt="provider"/>
                        </QuarterImageContainer> */}
                    </GoghNowContent>
                </DesktopContent>

            </GoghNowContainerODD>
        </GoghNowSection>

        <GoghNowSection>
            <GoghNowContainer>
                <DesktopContent>
                    <GoghNowContent>
                        <BusinessImageContainer>
                            <ImageContainer src='/images/Home/image1.png'  alt="business"/>
                        </BusinessImageContainer>

                    </GoghNowContent>
                </DesktopContent>
                <GoghNowContent>
                    <GoghNowHeaderType>GOGHNOW FOR BUSINESS</GoghNowHeaderType>
                    <GoghNowHeader>Earn more from your event spaces</GoghNowHeader>
                    <GoghNowDescription>Become a one-stop-shop for the event services your clients need or quickly spin up your own events—or both.</GoghNowDescription>
                    <OfferingLink onClick={navigateToBusinessPage}>Get started →</OfferingLink>
                    <MobileContent>
                        <BusinessImageContainer>
                            <ImageContainer src='/images/Home/image1.png'  alt="business"/>
                        </BusinessImageContainer>
                    </MobileContent>

                </GoghNowContent>
            </GoghNowContainer>
        </GoghNowSection>


        <CitiesSection>
            <CitiesHeroText>We're expanding</CitiesHeroText>
            <CitiesSubHeroText>...and we're already in cities all over the US:</CitiesSubHeroText>
            <CitiesContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                {events.map((event, index) => (
                    <GridItem key={index}>
                    {event}
                    </GridItem>
                ))}
            </CitiesContainer>
        </CitiesSection>
        
        <UpdatesSection>
            <UpdatesContainer>
                <Updates>
                    <GoghNowHeader>Stay Updated</GoghNowHeader>
                    <GoghNowDescription>Be the first to get updates, and exclusive offers!</GoghNowDescription>

                            <CustomSignUpComponent handleSubmit={onSignupSubmit} isSubmitting={isSubmitting} status={status}/>
                        
                    
                </Updates>
            </UpdatesContainer>
        </UpdatesSection>


        {/* <section>
            <FAQManager questionsAndAnswers={questionsAndAnswers} title = {'Frequently Asked Questions'} />
        </section> */}
        <GeneralSignUpMenu isOpen={isMenuOpen} onClose={closeMenu} items = {[]}/>

        {/* need to make the success joint reuseable. the error one is prob cleaner. */}
        {/* {(showSuccessPopup) && 
            <CheckoutSuccess/>
        } */}

    </PageContentContainer>
    </PageContainer>

    </>
    )
}

//            {isMenuOpen && <GeneralSignUpMenu isOpen={isMenuOpen}/>} //this slides in

    //LOAD SCRI

    
const MobileContent = styled.div`
    ${mobileSizeDisplayFlex};
`
const DesktopContent = styled.div`
    ${mobileSizeDisplayNone};
`

const HeroSection = styled.div`
text-align: center;
height: 65vh;
width: 100vw;
background-image: url("/images/login-background.png");
background-size: cover; /* Cover the entire section */
background-position: center; /* Center the background image */
`;

const HeroSectionOverlay = styled(SECTION)`
text-align: center;
min-height: 65vh;
background-color: #FBAE6290; /*#AAAAAA90;*/
`;



//Container joint
const CTA = styled(CALLTOACTION)`
// padding-bottom: calc(${NAVBAR_HEIGHT} + ${NAVBAR_HEIGHT});
gap: 10px;

p, h1{
margin: 0px;
}
`;

const HeroText = styled.h1 `
color: white;
//   font-size: 4em;
//   font-weight: 600;
  margin-top: 0px;
  line-height: 95%;
  text-shadow: 2px 2px 2px #00000060; /* Creates a white shadow */
`
const HeroDescription = styled.p`
color: #000000;
// font-size : 16px;
margin: 0 0 24px;
line-height: 1.5em;
letter-spacing: 1.5px;
max-width: 400px;
margin-bottom: 25px;
`

//SECTION 1
const OfferingsSection = styled(SECTION)`
display: flex;
flex-direction: row;
text-align: center;
width: 100%;
justify-content: center;
background-color: #FBAE62;
padding: 15px 36px;
`


const OfferingContainer = styled.div`

${mobileDisplayContentVertical};
display: flex;
max-width: 100%;
gap: 5px;
justify-content: space-around;
padding: 25px 0px;

@media (max-width: 769px) {
    gap: 5px;
    // padding: 20px 0px;
}
`

const Offering = styled.div`
${mobileDisplayContentHorizontal};

display: flex;
flex: 1 1 calc(33.3% - 25px);
flex-direction: column;
justify-content: center;
align-items: center;
// box-shadow: 1px 1px 3px #00000060; /* Creates a white shadow */
padding: 10px;
@media (max-width: 769px) {
    // gap: 20px;
    padding: 20px 0px;
}
`

const OfferingImageContainer = styled.div`
margin-bottom: 0px;

width: 50%;
aspect-ratio: 1 / 1;
font-size: 0px;
display: inline-block;

img {
    display: block;
    width:100%;
}

@media (max-width: 769px) {
    flex-grow: 0;
    width: 50%;
}
`

const OfferingContentContainer = styled.div`
display: flex;
flex-direction:column;
width: 100%;
 @media (max-width: 769px) {
    text-align: left;
    padding-left: 10px;
    flex-grow: 1;

        p{
    margin: 0px;
    }
}
`
const OfferingSubHeader = styled.h3`
color: #000000;
// font-size : 14px;
font-weight: 700;
// margin: 0 0 24px;
// line-height: 1.5em;
letter-spacing: 1.0px;
margin-bottom: 5px;
`
const OfferingHeader = styled.h2`
color:black;
font-weight: 700;
// font-size: 45px;
line-height: 90%;
margin-bottom: 0px;
margin-top: 0px;
`
const OfferingDescription = styled.p`
color:black;
font-size: 18px;
margin-top: 9px;
`

//This was the link error
const OfferingLink = styled.div`
color: #FFFFFF;
font-weight: 700;
font-size: 22px;
cursor: pointer;
background-color: #FBAE6290;
padding: 15px;
border-radius: 10px;

&:hover{
color: #FFFFFF;
background-color: #FBAE62;
}
`

const OfferingNoLink = styled.div`
color: #00000050;/*#FBAE62;*/
font-weight: 700;
font-size: 22px;
cursor: pointer;
`
//SECTION 2
//===================================
const OccasionsSection = styled(SECTION)`
// overflow: hidden;
display: flex;
flex-direction: column;
text-align: center;
// min-height: 100vh;
width: 100%;
justify-content: center;
// align-items: center;
background-color: #F5F5F5;
// padding: 15px 36px;
`

const OccasionsHeroText = styled.h2 `
  color: #3F3F3F;
  font-size: 3em;
  font-weight: 600;
  margin-top: 0px;
  line-height: 95%;
//   text-shadow: 2px 2px 2px #00000060; /* Creates a white shadow */
padding: 0px 0px;
`

const OccasionsContainer = styled.div`

${mobileDisplayContentVertical};
display: grid;
grid-template-columns:${props => {
    if (props.$isMobile) return 'repeat(2, 1fr)';
    if (props.$isMedium) return 'repeat(2, 1fr)';
    if (props.$isLarge) return 'repeat(4, 1fr)';
    return 'repeat(4, 1fr)'; // Default to large if none match
  }};
grid-auto-rows: 1fr;
width: 100%;
gap: 15px;
justify-content: space-around;
padding: 15px 0px;



@media (max-width: 769px) {
    gap: 0px;
    padding: 20px 0px;
}

@media (max-width: 450px) {
    gap: 0px;
    padding: 0px 0px;
}
`

const Occasions = styled.div`
${mobileDisplayContentVertical};

display: flex;
flex: 1 1 calc(33.3% - 25px);
flex-direction: column;
justify-content: center;
align-items: center;
background-color: #EEE;
border: 1px solid #CCC;
padding: 15px;

@media (max-width: 769px) {
    // gap: 20px;
    padding: 20px 0px;
}
`

const OccasionsImageContainer = styled.div`
margin-bottom: 0px;

width: 50%;
aspect-ratio: 1 / 1;
font-size: 0px;
display: inline-block;

img {
    display: block;
    width:100%;
}

@media (max-width: 769px) {
    flex-grow: 1;
    width: 50%;
    padding-left: 5px;
    padding-right:5px;
}
`

const OccasionsContentContainer = styled.div`
display: flex;
flex-direction:column;
width: 100%;
padding-left: 15px;
padding-right: 15px;
 @media (max-width: 769px) {
    text-align: center;
    padding-left: 5px;
    padding-right:5px;
    flex-grow: 1;

        p{
    margin: 0px;
    }
}
`

const OccasionsTitle = styled.h3`
color:#3F3F3F;
// font-size: 18px;
margin: 0px;
font-weight: 600;

`
const OccasionsDescription = styled.h4`
color:#3F3F3F;
padding-top: 10px;

// font-size: 18px;
font-weight: 200;
margin: 0px;

`

//===================================
//SECTION CITIES

const CitiesSection = styled(SECTION)`
// overflow: hidden;
display: flex;
flex-direction: column;
text-align: left;
// min-height: 100vh;
width: 100%;
// justify-content: start;
align-items: start;
background-color: #F5F5F5;
`

const CitiesHeroText = styled.h2 `

`

const CitiesSubHeroText = styled.h3 `
  color: black;
  font-size: 2.0em;
  font-weight: 200;
  margin-top: 0px;
  line-height: 100%;
//   text-shadow: 2px 2px 2px #00000060; /* Creates a white shadow */
padding: 0px 0px;
`

const CitiesContainer = styled.div`

${mobileDisplayContentVertical};
display: grid;
// grid-template-columns: repeat(4, 1fr);
grid-template-columns:${props => {
    if (props.$isMobile) return 'repeat(2, 1fr)';
    if (props.$isMedium) return 'repeat(3, 1fr)';
    if (props.$isLarge) return 'repeat(4, 1fr)';
    return 'repeat(4, 1fr)'; // Default to large if none match
  }};
grid-auto-rows: 1fr;
max-width: 100%;
width: 100%;
height: auto;
gap: 25px;
justify-content: space-around;
padding: 75px 0px;
// padding-right: 108px;
// padding-left: 108px;

`

const GridItem = styled.div`
    background-color: transparent;
    color: black;
    padding: 15px;
    text-align: left;
    border: 1px solid #EEE;
    border-radius: 8px;
    background-color: #EEEEEE;
  `
//===================================
//SECTION 3
const GoghNowSection = styled.div`
display: flex;
flex-direction: row;
text-align: center;
width: 100%;
justify-content: center;
align-items: center;
background-color: white;
`

const GoghNowContainer = styled.div`
${mobileDisplayContentVertical};

display: flex;
width:100%;
padding: 25px 20px;
justify-content: space-around;
background-color: #CCCCCC40;
min-height: 50%;
`

const GoghNowContainerODD = styled.div`

${mobileDisplayContentVertical};

display: flex;
width:100%;
padding: 25px 20px;
justify-content: space-around;
background-color: transparent;
min-height: 50%;
`

const GoghNowContent = styled.div`
display: flex;
flex: 0 0 calc(50% - 25px);
flex-direction: column;
justify-content: center;
align-items: start;
padding: 36px 36px;
gap: 30px;

@media (max-width: 769px) {
    margin: 0px;
    padding-bottom: 10px;
}
`

const GoghNowContentMobile = styled.div`
display: flex;
flex: 0 0 calc(50% - 25px);
flex-direction: column;
justify-content: center;
align-items: center;
padding: 36px 36px;
// gap: 20px;

@media (max-width: 769px) {
    margin: 0px;
    padding-bottom: 10px;
}
`

const GoghNowContentExpanded = styled.div`

${mobileSizeDisplayBlock};

display: flex;
flex: 0 0 calc(100% - 25px);
flex-direction: column;
justify-content: center;
align-items: center;
padding: 36px 0px;
gap: 10px;
@media (max-width: 769px) {
    margin: 0px;
    padding-bottom: 10px;
}
`

const GoghNowHeader = styled.h2`


padding: 0px 0px;
text-align: left;
width: 100%;
margin: 0px;
`

const GoghNowSubHeader = styled.h3`
color:black;
font-weight: 200;
margin-bottom: 0px;
margin-top: 0px;
padding-bottom: 0px;
text-align: left;
width: 100%;

`

const GoghNowSubHeaderMobile = styled.h3`

${mobileSizeDisplayBlock};

color:black;
font-weight: 700;
margin-bottom: 0px;
margin-top: 0px;
padding-bottom: 0px;
text-align: left;

`


const ProviderImageContainer = styled.div`
margin-bottom: 0px;

width: 100%;
aspect-ratio: 1 / 1;
font-size: 0px;
display: inline-block;

img {
    display: block;
    width:100%;
}

@media (max-width: 769px) {
    flex-grow: 0;
    width: 100%;
}
`

const BusinessImageContainer = styled.div`
margin-bottom: 0px;

width: 100%;
aspect-ratio: 1 / 1;
font-size: 0px;
display: inline-block;

img {
    display: block;
    width:100%;
}

@media (max-width: 769px) {
    flex-grow: 0;
    width: 100%;
}
`


// const GoghNowHeaderMobile = styled.h2`

// ${mobileSizeDisplayBlock};
// display: none;
// color:black;
// font-weight: 700;
// // font-size: 45px;
// line-height: 90%;
// margin-bottom: 0px;
// margin-top: 0px;
// text-align: left;
// `
const GoghNowDescription = styled.h3`
color: black;
// font-size: 18px;
margin: 0px;
text-align: left;
// font-size: 2.0em;
font-weight: 200;
`

const GoghNowHeaderType = styled.h3`
color: #AAAAAA;
text-align: left;
width: 100%;
margin: 0px;
padding: 0px;
`

const GoghNowImageContainer = styled.div`

// margin-top: 50px;
margin-bottom: 0px;

width: 75%;
max-height: 100%;
font-size: 0px;
display: display;
justify-content: center;

img {
    display: block;
    width:100%;
}
`

const QuarterImageContainer = styled.div `

// margin-top: 50px;
margin-bottom: 0px;

display:flex;
height: 100%;
width: 100%;
flex-wrap: wrap;
gap: 0x;
flex: 0 0 calc(25% - 0px);

img {
    width: 50%;
    height:50%;
    position: relative;
}
`



//NEW ELEMENTS
const ActionButton = styled.button`
flex-direction: row;
flex-wrap: nowrap;
display: flex;
align-items: center;
justify-content:start;
width: auto;
padding: 0px 50px;
border-radius: 25px;
border: 1px solid #CCC;
background-color: #FBAE62;
color: white;
cursor: pointer;
// margin-top: 25px;
height: 50px;

&:hover{
    background-color: #FBAE6260;
}
`


const ActionContainer = styled.div `
display:flex;
width: 100%;
gap: 25px;
align-items: end;
justify-content: center;
flex-wrap: wrap;
`


//SignUp Container
//SECTION 1

const CustomSignUpComponent = styled(SignUpComponent)`
width: 650px;
background-color: transparent;
padding: 0px;
`
const UpdatesSection = styled.div`
// overflow: hidden;
display: flex;
// position: relative;
flex-direction: row;
text-align: center;
// min-height: 100vh;
width: 100%;
justify-content: center;
align-items: center;
background-color: #3F3F3F90;
padding: 50px 36px;
`

const UpdatesContainer = styled.div`
display: flex;
// max-width: 1184px;
padding: 50px 0px;
justify-content: center;
width: 100%;
align-items: center;
text-align: center;
h2, p {
text-align: center;
}
`

const Updates = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
// background-color: #FBAE6220;
padding-bottom: 36px;
max-width:650px;

button {
    // font-weight: bold;
    color: black;
    background-color: #FBAE6260;
    width: auto !important;
    height: 50px !important;
    padding: 0px 15px;
    border-radius: 25px;
    margin: 10px;
    // margin-left: 25px;
    // margin-right: 25px;

    font-size: 18px;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover{
        background-color: #FBAE62;
        color: white;
    }
}
`


//General Elements
const ImageContainer = styled.img`
position: relative;
width: 100%;
height: auto;
`;


export default Home;