import styled from 'styled-components';


import SearchFunctions from 'utility/searchFunctions';

const CheckoutLocation = ({item, onSelect}) => {
    
    const handleEditClick = (item) =>{
        console.log("EIDINT THIS JOINT")
    }

    // console.log("CHECKOUTLOCATION Location: ", item)
    return (
        <Container>
            <ContentContainer>
                <LocationContainer>
                    <ImageSection></ImageSection>
                    <DetailsSection>
                        <h1>{ SearchFunctions.getLine1FromPlace(item)}</h1>
                        <p>{SearchFunctions.getLine2FromPlace(item)}</p>
                    </DetailsSection>
                </LocationContainer>
                {/* <EditComponent onClick={() => handleEditClick(item)}>
                <p>Edit</p>
                </EditComponent> */}
            </ContentContainer>

        </Container>
        );

}

export default CheckoutLocation;

const Container = styled.div`
display: flex;
flex-direction: column;`

const EditComponent = styled.div`
display: flex;
width: auto;
height: auto;

p{
    text-decoration: underline;
    color: black;
}
`

const ContentContainer = styled.div`
padding: 7.5px 0px;
display: flex;
width: 100%;
gap: 10px;
// border-bottom: 1px solid black;
// cursor: pointer;
align-items: center;
color: black;

// &:hover{
//     background-color: #00000010;
// }
`

const LocationContainer = styled.div`
padding: 7.5px 0px;
display: flex;
width: 100%;
gap: 10px;
// border-bottom: 1px solid black;
// cursor: pointer;
align-items: center;
color: black;

// &:hover{
//     background-color: #00000010;
// }
`

const ImageSection = styled.div`
display: flex;
aspect-ratio: 1 / 1;
height: 35px;
// background-color: #CCC;
background-image: url('/images/pin.png');
background-size: cover; /* or contain */
background-position: center;
// background-color: #CCC;
`
const DetailsSection = styled.div`
display: flex;
  flex-direction: column;
  width: 100%;
//   flex: 0 0 calc(50% - 50px);
  // padding-bottom: 1em;
  justify-content: center;


  h1 {
    color:black;
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 0px;
  }

  p {
    /* Default color, overridden by enabled/disabled styles */
    color:#00000060;
    margin: 0px;
  }

  @media (max-width: 768px) {
    flex: 0 0 100%;
  }

`;