import { 
    SEARCH_UPDATE_CRITERIA,
    SEARCH_UPDATE_TEMP_CRITERIA,
    SEARCH_RESET_CRITERIA,
    SEARCH_SET_LOCATION,
    SEARCH_SET_DATE,
    SEARCH_SET_TIME, 
    SEARCH_SET_DURATION,
    SEARCH_UPDATE_RESULTS,
} from "third_party/redux/actionTypes";


import SearchFunctions from "utility/searchFunctions";
import { ObjectSearchClass } from "model/SearchObjects/objectSearch";


//#################################
//#############Initial States############
//#################################
const searchInitialState = new ObjectSearchClass().toDict()
// const searchInitialState = {
//     current: {
//       location: {},
//       date: SearchFunctions.getTimeNow(),
//       duration: 120,
//     },
//     temp: {
//       location: {},
//       date: SearchFunctions.getTimeNow(),
//       duration: 120,
//     },
//     results: [],
//   };


//#################################
//#############REDUCERS############
//#################################

const searchReducer = (state = searchInitialState, action) => {

    // console.log("searchReducer | ", state)
    switch (action.type) {

        //-----------
        //SEARCH
        //-----------

        case SEARCH_RESET_CRITERIA:{
            return searchInitialState
        }
        break

        case  SEARCH_UPDATE_CRITERIA: {
            // const currentState = {...state.current}
            // const data = action.payload

            // const updatedCurrent = new ObjectSearchCriteriaClass(action.payload)
                // Create a shallow copy of current state
                const currentState = { ...state.current };

                // Merge action.payload with the current state
                const updatedCurrent = {
                    ...currentState, // Spread the current state
                    ...action.payload // Merge in the new values from the payload
                };
            return {
                ...state, // Keep the rest of the state intact
                current: updatedCurrent, // Merged value for current
                temp: updatedCurrent // Set temp to be the same as the updated current
            }

        }
        break

        case  SEARCH_UPDATE_TEMP_CRITERIA: {
            // const updatedTemp = new ObjectSearchCriteriaClass(action.payload)
            const tempState = {...state.temp}
            const updatedTemp = {
                ...tempState,
                ...action.payload
            }
            return {
                ...state,
                temp: updatedTemp,
            }

        }
        break

        //-----------
        //TEMP
        //-----------
        case SEARCH_SET_LOCATION:{
            return {
                ...state,
                temp:{
                    ...state.temp, // Spread the existing tempSearchCriteria
                    location: action.payload
                }                
            }
        }
        break

        case SEARCH_SET_DATE: {
            return {
                ...state,
                temp:{
                    ...state.temp, // Spread the existing tempSearchCriteria
                    date: SearchFunctions.updateDateComponentWithDate(state.temp.date, action.payload),
                }                
            }
        }
        break

        case SEARCH_SET_TIME:{
            return {
                ...state,
                temp:{
                    ...state.temp, // Spread the existing tempSearchCriteria
                    date: SearchFunctions.updateDateComponentWithTime(state.temp.date, action.payload),
                }                
            }
        }
        break

        case SEARCH_SET_DURATION: {
            return {
                ...state,
                temp:{
                    ...state.temp, // Spread the existing tempSearchCriteria
                    duration: action.payload,
                }                
            }
        }
        break
        //-----------
        //RESULS
        //-----------

        case SEARCH_UPDATE_RESULTS:{
            return {
                ...state,
                results:action.payload              
            }
        }
        break

        default:
            return state;
            break

    }
}

export default searchReducer
