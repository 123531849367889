import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// import { useAuthContext } from 'context/AppContext/AuthContext';
import { useAuth } from 'third_party/redux/selectors';
//UPDATED ITEMS
import { useUserActions } from 'third_party/redux/user/userAction';
import { useSearchActions } from 'third_party/redux/search/searchActions';
import { useSearch} from 'third_party/redux/selectors';
import SearchFunctions from 'utility/searchFunctions';

import GoogleMapsFunctions from 'utility/googleMapsFunctions';
import { useGuestLoginWithPlace } from 'third_party/redux/convenientFunctions';

const BusinessURLContext = createContext();
export const useBusinessURLContext = () => useContext(BusinessURLContext) 

export const BusinessURLProvider = ({ children }) => {



    const urlLocation = useLocation();
  const navigate = useNavigate();

  //NEW
  const {USER_UPDATE} = useUserActions()
  const {SEARCH_UPDATE} = useSearchActions()
  const searchCriteria = useSearch().current //THIS SHOULD BE REFERRED TO CURRENT
  const loginAsGuestWithPlace = useGuestLoginWithPlace()
  //OLD
  // const { isLoggedIn, loggedInWithAddress } = useAuthContext();
  const {isLoggedIn} = useAuth()
  

  //is this initially loading the app
  const [isInitialLoad, setIsInitialLoad] = useState(true);










// Read the URL and update searchCriteria
//==================================================
// useEffect(() => {
//   const fetchPlace = async () => {
//       let place = searchCriteria.location;
//       let date = SearchFunctions.getISOStringFromDate(searchCriteria.date);
//       let duration = searchCriteria.duration;

//       // potential address joints
//       const params = new URLSearchParams(urlLocation.search);
      
//       // Check if date or duration exists
//       date = SearchFunctions.getISOStringFromDate(params.get('date')) || date;
//       duration = params.get('duration') || duration;
      
//       // Getting the location information
//       const address = params.get('address');
//       const lat = params.get('lat');
//       const lng = params.get('lng');

//       console.group('Address:', address);
//       console.log('Lat:', lat);
//       console.log('Lng:', lng);
//       console.log('Duration:', duration);
//       console.log('Date:', date);
//       console.groupEnd();

//       if (address || (lat && lng)) {
//           console.log("THERE IS ADDRESS OR LAT AND LNG");

//           try {
//               console.log("WE IN FETCHPLACE");
//               const returnedPlace = await GoogleMapsFunctions.getPlaceFromAddressOrCoordinates({ address, lat, lng }); // Wait for the promise to resolve
//               console.log("RETURNED PLACE IS:", returnedPlace);
              
//               // Update user with location if it's found
//               if (returnedPlace) {
//                   place = returnedPlace;
//                   const userUpdate = { lat: place.lat, lng: place.lng };
//                   USER_UPDATE(userUpdate);
//                   loginAsGuestWithPlace(place);
//               }
//           } catch (error) {
//               console.log('Error fetching place:', error);
//           }
//       }

//       // After fetching the place, update search criteria
//       const updatedSearch = SearchFunctions.createDictionaryFromSearchCriteria({ place, date, duration });
//       console.log("updatedSearch with:", updatedSearch);
//       SEARCH_UPDATE(updatedSearch);
//   };

//   if (isInitialLoad) {
//       console.group("IS INITIAL LOAD");
//       fetchPlace().then(() => {
//           // Set initial load to false only after fetchPlace is done
//           console.log("setIsInitialLoad is FALSE");
//           setIsInitialLoad(false);
//           console.groupEnd();
//       });
//   }

// }, []); // Add dependencies as necessary




        //=============================
        //==============================
        // UPDATE URL from search
    //     useEffect(() => {

    //         console.group("INITIAL LOAD IT: ",isInitialLoad)
    //         console.log("useEffect: update URL")
    //         if(!isInitialLoad){
    //                 console.log("WE SHOULD BE HERE")
    //                 console.log("searchCriteria: ",searchCriteria)
    //                 const searchParams = searchParamsBasedOnDictionary(searchCriteria)
    //                 console.log("PARAMS GETTING UPDATED WITH: ",searchParams)
    //                 navigate({ search: searchParams }, { replace: true }); // Update URL with combined parameters

    //         }
    //         console.groupEnd("groupEND")
    // }, [searchCriteria,isInitialLoad]);

    // const searchParamsBasedOnDictionary = (paramsDict) =>{
    //   const newParams = new URLSearchParams();
    //     if(paramsDict.location){
    //       if (paramsDict.location && Object.keys(paramsDict.location).length > 0) {
    //         newParams.set('address', JSON.stringify(SearchFunctions.getAddressFromPlace(paramsDict.location)));
    //       }
    //    }
    //     if (paramsDict.date) {
    //       newParams.set('date', paramsDict.date.toISOString());
    //     }
    //     if (paramsDict.duration) {
    //       newParams.set('duration', paramsDict.duration.toString());
    //     }
    //     console.log("NEWPARAMS: ",newParams)
    //     return newParams.toString()
    // }
    
      //================================
      //=================================

    return (
        <BusinessURLContext.Provider value={{  }}>
        {children}
        </BusinessURLContext.Provider>
    );

}