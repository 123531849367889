import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import HoverImagePopup from './HoverImagePopup';


import { useScreenContext } from 'context/AppContext/ScreenContext';

const HoverImage = ({isHighlighted ,data, onMouseEnter, onMouseLeave}) => {

    const {isMobile, isMedium, isLarge} = useScreenContext()
    const imageRef = useRef(null);


    // const isHighlighted = data?.title === highlightedItem?.title;


    return (
        <Container
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            // onClick={() => calculateHotspotPosition(data)}
            ref={imageRef}

        >
            <ImageContainer $isHighlighted={isHighlighted}>
                <img src={data?.image} alt={data?.alt}/>
                <GradientOverlay $isHighlighted={isHighlighted}></GradientOverlay>
            </ImageContainer>
            <Content $isHighlighted={isHighlighted}>
                <Title $isHighlighted={isHighlighted} $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>{data?.title}</Title>
            </Content>

        </Container>
    )
}

export default HoverImage

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border: solid 1px rgba(255,255,255,.3)
`

const ImageContainer = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
`

const GradientOverlay = styled.div`
display: flex;
position: absolute;
top: 0px;
left: 0px;
width: 100%;
height: 100%;
justify-content: center:
align-items: center;
pointer-events: none; /* Ensure this overlay does not interfere with interactions */
background: ${({ $isHighlighted }) => ($isHighlighted ? 'linear-gradient(to bottom, rgba(0, 0, 0, .3) 0%, #00000075 100%)' : 'rgba(0, 0, 0, .75)')};

`


const Content = styled.div`
display: flex;
position: absolute;
width: 100%;
height: 100%;
justify-content: center;
align-items: ${({ $isHighlighted }) => ($isHighlighted ? 'center' : 'center')};

padding: 0px 10px;
`

const Title = styled.h3`
    font-weight: ${({ $isHighlighted }) => ($isHighlighted ? '900' : '100')};
    color: #FFFFFF60;
    color: ${({ $isHighlighted }) => ($isHighlighted ? '#FFFFFF' : '#FFFFFF80')};
    // line-height: 90%;
    text-align: center;
`





const HotspotOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(128, 128, 128,0.5);
  z-index: 9;
`;