export const toJSON = (obj: any) => {
  return JSON.stringify(obj, (key, value) => {
    // Convert dates to ISO string format
    if (value instanceof Date) {
      return value.toISOString();
    }
    // Return the value as is for other types
    return value;
  });
};

export const fromJSON = (jsonString: any) => {
  try {
    return JSON.parse(jsonString, (key, value) => {
      // Convert ISO string dates back to Date objects
      if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/)) {
        return new Date(value);
      }
      // Return the value as is for other types
      return value;
    });
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return null;
  }
};

// Utility function to extract error messages
export const extractErrorMessage = (error: unknown): string => {
  console.error('LOGGING THE ERROR AS: ',error)
  if (error instanceof Error) {
    return error.message || 'An unknown error occurred';
  } else if (typeof error === 'string') {
    return error;
  } else if (error && typeof error === 'object' && 'message' in error) {
    return (error as any).message || 'An unknown error occurred';
  } else {
    return 'An unknown error occurred';
  }
};
