import styled from "styled-components";

import ProviderApp from "./ProviderApp";
import ProviderLanding from "./ProviderLanding";
import { PageContainer } from "components/Page/Elements";

import { useAuth } from "third_party/redux/selectors";

const Provider = (props) => {

    const { isLoggedIn } = useAuth() //UPDATE TO PROVIDER

    return (
    <>
        <PageContainer className="page-container">
            {/* {isLoggedIn ? (
                <ProviderApp />
            ) : ( */}
                <ProviderLanding />
            {/* )} */}
        </PageContainer>
    </>
    );
};


export default Provider;