import styled from 'styled-components';
import { NAVBAR_HEIGHT, SECTION_PADDING } from 'components/constants';
import PackageItem from './PackageItem'


const Package = ({title, description, items}) => {


    return (
        <PackageContainer>
            {/* <GoghNowContainer> */}
                <Title>{title}</Title>
                <ItemsContainer>
                    {items.map((item, index) => (
                        <PackageItem key={index} item={item}/>
                    ))}
                </ItemsContainer>
                <Description>
                    {description}
                </Description>
            {/* </GoghNowContainer> */}
        </PackageContainer>
    )
}
export default Package;

const PackageContainer = styled.div`
display: flex;
flex-direction: column;
max-width:100%;
margin: 10px 10px;
// justify-content: space-around;
background-color: #CCCCCC20;
min-height: 50%;
`

// const GoghNowContainer = styled.div`
// display: flex;
// flex-direction:column;
// justify-content: space-around;
// // background-color: #CCCCCC20;
// min-height: 50%;
// `
const ItemsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Three columns, each 1 fraction of the container width */
    min-height:33vh;
    width: 100%;
    // padding: ${SECTION_PADDING};
    overflow-x : scroll;
    gap: 15px;
    align-items: start;
    justify-content: center;
    // background-color: white;
    // box-shadow: 0 2px 5px -1px #CCCCCC80; /* Shadow at the bottom only */
`

const ImageContainer =  styled.div`
    width: 100%; /* Fills available space within the grid item */
    aspect-ratio: 1 / 1; /* Sets aspect ratio to 1:1 (consider browser support) */
    overflow: hidden; /* Hides overflowing content (optional) */

    // img {
    //     display: block;
    //     width:100%;
    //     height:100%;
    //     background-color: #00000040;
    // }
`


const Title = styled.h2`
text-align: left;
color: #CCC;
`
const Description = styled.p`
font-size: 12px;
font-weight: 100;
color: #AAAAAA;
`