import styled from '@emotion/styled'
import { useState } from "react"
import PhoneInputContainer from "components/Inputs/PhoneInputContainer";
import SubmitButton from "components/Buttons/SubmitButton";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';

import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

import { useScreenContext } from 'context/AppContext/ScreenContext';

import PasswordTextField from 'components/Inputs/PasswordTextField';
import OrSeparator from 'components/Dividers/OrSeparator';

import Panel from 'components/Containers/Panel';


const Login = (props) => {

    // const [email ,setEmail] = useState("")
    // const [password ,setPassword] = useState("")

    const {isMobile, isMedium, isLarge} = useScreenContext()


    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    // const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    //   event.preventDefault();
    // };



    function onSubmit(e){
        e.preventDefault()
        console.log('information',{data})
    }

    const [data, setData] = useState({})
    
    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [id]: value,
        }));

        console.log('input changing:', value);

    };

    const handelSignUp = () => {
        console.log('signup button pressed')
    }
    return (
    <Container>
        <Content $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}> 

            <Panel $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                <LogoContainer><img src='/images/cta-logo-one.svg' alt="GoghNow" /></LogoContainer>
                <LoginForm onSubmit={onSubmit}>
                        <PhoneInputContainer id="phone" label="" value={data.phone} onChange={handleInputChange} placeholder="Phone Number"></PhoneInputContainer>

                        <PasswordTextField
                            id="password"
                            label="Password"
                            variant="outlined"
                            value={data.password || ''}
                            onChange={handleInputChange}
                            fullWidth
                            size="small"
                            margin="normal"
                            required
                        />
                        {/* <TextInputContainer id="password" label="password" value={data.password} onChange={handleInputChange} placeholder="Enter password"></TextInputContainer> */}
                        <SubmitButtonCustom text="Login" type="submit"/>
                </LoginForm>
                <SeparatorOR/>
                <SignupButtonCustom text="Signup" onClick={handelSignUp}/>
            </Panel> 

            <BgImage/>
            <BgImageOverlay/>

        </Content>
    </Container>
    );
};


const Container = styled.section`
overflow: hidden;
display: flex;
flex-direction: column;
text-align: left;
min-height: 100vh;
min-height: 100dvh;
`;

const Content = styled.div`
width: 100%;
position: relative;
min-height: 100vh;
min-height: 100dvh;
box-sizing: border-box;
display: flex;
padding: ${props => {
    if (props.$isMobile) return '0px';
    if (props.$isMedium) return '100px 40px';
    if (props.$isLarge) return '100px 40px';
    return 'px'; // Default to large if none match
}};
justify-content: ${props => {
    if (props.$isMobile) return 'none';
    if (props.$isMedium) return 'center';
    if (props.$isLarge) return 'center';
    return 'center'; // Default to large if none match
}}; 
// justify-content: center;
align-items: center;
flex-direction: column;
height: 100%;
`;

const BgImage = styled.div`
background-image: url("/images/web-gradient-100.jpg");
height: 100%;
background-position: top;
background-size: cover;
background-repeat: no-repeat;
position: fixed;
top: 0;
right: 0;
left: 0;
z-index: -1;
`;
const BgImageOverlay = styled.div`
height: 100%;
opacity: 0.5;
background-color: white;
background-position: top;
background-size: cover;
background-repeat: no-repeat;
position: fixed;
top: 0;
right: 0;
left: 0;
z-index: -1;
`;



const LogoContainer = styled.a`
padding: 0px;
width: 150px;
margin-top: 36px;
margin-bottom: 36px;
max-height: 70px;
font-size: 0px;
display: inline-block;

img {
    display: block;
    width:100%;
}
`

const MainText = styled.h1`
color: black;
font-weight: 800;
margin-top: 0px;
width:100%;
`


const Description = styled.p`
color: #00000080;
font-size : 11px;
margin: 0 0 24px;
line-height: 1.5em;
letter-spacing: 1.5px;
// max-width: 400px;
`

const LoginForm = styled.form`
display:flex;
flex-direction: column;
width: 100%;
background-color: #ffffff;
border-radius: 50px;
gap: 0px;
`

const LoginFormContainer = styled.div`
display: flex;
flex-direction: column;
gap: 0px;
width: 100%;
align-items: center;
`

const SubmitButtonCustom = styled(SubmitButton)`
  margin-top: 18px;
//   background-color: black;
`;

const SignupButtonCustom = styled(SubmitButton)`
  margin-top: 18px;
  background-color: #AAA;
  border: solid 1px #CCC;
  width: 100%;
  color: white;

  &:hover {
        background-color: #CCC;
   }
`;


const SeparatorOR = styled(OrSeparator)`
padding: 36px 0px;
`

export default Login;