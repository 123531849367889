import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuthContext } from './AuthContext';

import GoogleMapsFunctions from 'utility/googleMapsFunctions';

const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({children}) => {


const { isLoggedIn, loggedInWithCredentials, loggedInWithAddress, logout, resetAllContexts } = useAuthContext();

const [user, setUser] = useState({
  phone:'',
  email:'',
  name:''
});
const [userLocation, setUserLocation] = useState({})

const updateUser = (id , value) => {

  console.log("updateUser!!")

  // console.log('updateuser id:',id," value: ",value)
  // console.log('current: ', user)
  setUser(prevState => ({
    ...prevState,
    [id]:value,
  }));

  // console.log('currentAFTER UPDATE: ', user)

}


//Hook when user logs out
useEffect(() => {
    if (!isLoggedIn) {
      setUser({}); //clear user data when one logs out
    }
  }, [isLoggedIn]); //isLoggedIn is a dependency hook, it runs when one of these changes


  const loginAsGuestWithAddress = (address) => {
    
    console.log('loginAsGuestWithAddress!!!')
    loggedInWithAddress()

    //DELETE THIS
    GoogleMapsFunctions.returnPlaceFromAddress(address, (place) => {
      if (place) {
        console.log("FOUND PLACE and UPDATE USERLOCATION: ", place)
        setUserLocation(place);
      } else {
        console.log("No place found");
        //maybe through error to denote no location found
      }
    });

  };

  const loginAsGuestWithSelectedLocation = (location) => {

    loggedInWithAddress()
    setUserLocation(location)
  }

const loginWithCredentials = (username, password) => {

        // Fetch user data from your backend or authentication service
    // For example:
    // const userData = authenticateUser(username, password);
    // setUser(userData);
    // setUser({ username });


    // setUser({ username });
    updateUser('username',username)
    loggedInWithCredentials()
};



  //Reset Context
  const resetContext = () => {
    setUser({
      phone:'',
      email:'',
      name:''
    });
}

useEffect(() => {
  if (resetAllContexts) {
    resetContext();
  }
}, [resetAllContexts]);




return (
    <UserContext.Provider value={{ user, updateUser, loginAsGuestWithAddress, loginAsGuestWithSelectedLocation, loginWithCredentials, userLocation, setUserLocation }}>
      {children}
    </UserContext.Provider>
  );

}
