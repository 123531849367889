import { css, cx } from '@emotion/css';
import styled from '@emotion/styled'
import { useState,useRef } from 'react';
import { NAVBAR_HEIGHT } from "components/constants";
import ContactComponent from './ContactComponent';
//standard action button
import { ActionButton } from 'components/standard';
//Popsups
import CheckoutSuccess from './checkoutSuccess';
import CheckoutError from './checkoutError';



const Embedded = (props) => {

const [isOpen , setIsOpen] = useState(false)

const openModal = () => {
    console.log('openModal')
        setIsOpen(true)

}

const closeModal = () => {
        console.log('closeModal')
        setIsOpen(false)
}

const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const [info, setInfo] = useState({
    phone:'',
    email:'',
    name:''
  });


    //FORM ITEMS
    const [invalidFields, setInvalidFields] = useState(new Set());
    const [submitting, setSubmitting] = useState(false);

    //FORM CHANGES
    const handleInputChange = (e, newValue) => {
        const { id, value } = e.target;
        const newValueOrEvent = newValue !== undefined ? newValue : value;

        
        setInfo((prevState) => ({
            ...prevState,
            [id]: newValueOrEvent,
        }));
    };

    //FORM SUBMIT
    const formRef = useRef(null)
    const onSubmit = async (event) => {

        event.preventDefault();
        const isFormValid = formRef.current.checkValidity();
        if (isFormValid) {
        // Do something when the form is valid
        console.log('Form is valid! Submitting...');
        setSubmitting(true); // Set submitting state to true
        try {
            await handleCreateRequest();
          } catch (error) {
            setSubmitting(false); // Reset submitting state on error
          }
        } else {
        // Do something when the form is not valid
        const invalidFields = getInvalidFields(formRef);
        showErrorPopup(`Invalid form fields: ${invalidFields.join(', ')}\nPlease check your inputs.`);
    }
    };

    const getInvalidFields = (formRef) => {
        const formElements = formRef.current.elements;
        const invalidFields = [];
    
        for (let i = 0; i < formElements.length; i++) {
            const field = formElements[i];
            if (!field.checkValidity()) {
                const fieldName = field.name || field.id;
                if (fieldName) {
                    invalidFields.push(fieldName);
                }
            }
        }
    
        return invalidFields;
    };


    const handleCreateRequest = async () => {
        try{
            // const result = await createVenueRequestHandler(info);
            const result = '{success: true}'
            console.log('Request successful:', result);
            setShowSuccessPopup(true);
        }
        catch (error){
            console.error('Request failed:', error);
            // Show popup or any other error handling logic
            showErrorPopup(error.message);
        } finally {
            setSubmitting(false)
        }
      };


    //error Shit
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [isErrorPopupVisible, setErrorPopupVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const showErrorPopup = (message) => {
        setErrorMessage(message);
        setErrorPopupVisible(true);
      };
    
      const closeErrorPopup = () => {
        setErrorPopupVisible(false);
        setErrorMessage('');
      };



    return (
    <>
      <ButtonContainer $isOpen = {!isOpen}>
        <ButtonToOpenForm onClick={openModal}>Book a Space</ButtonToOpenForm>
      </ButtonContainer>
      <FullScreenModal $isOpen={isOpen} onClick={closeModal}>
        <FormContainer onClick={stopPropagation}>
            <HeaderPanel>
                <button onClick={closeModal}>x</button>
            </HeaderPanel>
          <h2>Book a Space</h2>
          <form id="FormID" ref={formRef} onSubmit={onSubmit}>
            <ContactComponent user={info} handleInputChange={handleInputChange}/>
            <CheckOutButton
                onClick={onSubmit}
                disabled={submitting}
                style={{ width: '100% important!' }}>
                {submitting ? 'Submitting...' : `Submit Request`}
            </CheckOutButton>
          </form>
        </FormContainer>
      </FullScreenModal>

      {(showSuccessPopup) && 
            <CheckoutSuccess/>
        }
        {isErrorPopupVisible && (
        <CheckoutError
          message={errorMessage}
          onClose={closeErrorPopup}>
        </CheckoutError>
      )}

    </>
    );


};
export default Embedded
const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    height: ${NAVBAR_HEIGHT};
    justify-content: center;
    align-items: center

`
const ButtonToOpenForm = styled.button`

    display: flex;
    padding: 2.5px 15px;
    // padding: 10px 20px;
    background-color: #FBAE62;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    color: white;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    height: 80%;

    &:hover {
        background-color: #FBAE6260;
   }
`

const FullScreenModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  overflow-y: auto;
  z-index: 5000;
  padding: 20px;
  box-sizing: border-box;
  color : #000000;
`;

const FormContainer = styled.div`
  background: white;
  margin: 50px auto;
  padding: 20px;
  max-width: 600px;
  border-radius: 8px;
  overflow-y: scroll;
`;

const HeaderPanel = styled.div`
display: flex;
width: 100%;
height: ${NAVBAR_HEIGHT};
justify-content: center;
align-items: center;

  button{
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    font-weight: 600;
    font-size: 1.5em;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    background-color: #CCCCCC60;

    &:hover{
        background-color: #CCCCCC;
    }
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
`;


const CheckOutButton = styled(ActionButton)`
width: 100% !important;
padding: 0px !important;
margin: 0px !important;
justify-content: center !important;
`
