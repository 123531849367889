import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

//import container is needed brother
import InputContainer from "components/Inputs/InputContainer"
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css';
// import { PhoneNumberUtil } from 'google-libphonenumber';
import { useTheme } from '@mui/material/styles';

// const phoneUtil = PhoneNumberUtil.getInstance();



const PhoneInputContainer = ({ id, label, value, onChange, placeholder, sx }) => {

    const theme = useTheme(); // Access MUI theme

        // Function to handle input changes and ensure only valid phone number characters are entered

    const  handleChange = (phoneNumber) => {
        console.log('phone number :', {value})
        
    
        const syntheticEvent = {
            target: {
                id,
                value: phoneNumber,
            }
        };
        onChange (syntheticEvent)
    }

    // const isPhoneValid = (phone) => {
    //     try {
    //       return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    //     } catch (error) {
    //       return false;
    //     }
    //   };

    return (
        <InputContainer>
            {/* {label && <Label htmlFor={id}>{label}</Label>} */}
            <PhoneInputStyled
                label= {label || ''}
                id = {id}
                defaultCountry='us'
                value={value || ""}
                onChange = {handleChange}
                placeholder='Enter phone number'
                sx={sx}
                theme={theme}
            />
        </InputContainer>
    );
};

PhoneInputContainer.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),//.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    sx: PropTypes.object, // Add sx to prop types
};

export default PhoneInputContainer;

// const InputContainer = styled.div`
//     margin-bottom: 15px;
// `;

const PhoneInputStyled = styled(PhoneInput)`
    width: 100%;
    button {
        background-color: transparent !important;
    }
    input {
        border: none !important;
        font-family: ${props => props.theme.typography.fontFamily} !important; // Set font family from MUI theme
        font-size: ${props => props.theme.typography.body1.fontSize} !important; // Set font size from MUI theme
        font-weight: ${props => props.theme.typography.fontWeightRegular} !important; // Set font weight from MUI theme
    }

    .react-international-phone-country-selector-button{
        border: 0px solid black;
    }
    
`
const Label = styled.label`
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
`;