import { ObjectUserProfileClass } from "model/objectUserProfile";
import { USER_UPDATE_ITEMS, USER_SAVE_NEW } from "../actionTypes";

//#################################
//#############Initial States############
//#################################

// const user : ObjectUserProfile = {};
const user = new ObjectUserProfileClass().toDict()
//#################################
//#############REDUCERS############
//#################################

const userReducer = (state = user, action : any) => {

    switch(action.type){

        case USER_UPDATE_ITEMS:{
            console.log('action.payload | ',action.payload)
            return{
                ...state,
                ...action.payload
            }      
        }
        break

        case USER_SAVE_NEW:{
            const newUser = new ObjectUserProfileClass(action.payload)
            return newUser.toDict()
        }
        break
 
        default: 
            return state
        
    }


}

export default userReducer
