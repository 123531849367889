import { useUserActions } from 'third_party/redux/user/userAction';
import { useAuthActions } from 'third_party/redux/auth/authActions';
import { useSearchActions } from 'third_party/redux/search/searchActions';
import { useNavigate } from 'react-router-dom';



// export const METHOD_LOGIN_AS_GUEST_WITH_PLACE = (place) => {

//     //NEW USE & AUTH
//     const {USER_UPDATE} = useUserActions()
//     const {AUTH_LOGIN_GUEST} = useAuthActions()
//     const {SEARCH_UPDATE} = useSearchActions()
    
//     const userUpdate = {lat:place.lat, lng:place.lng}
//     USER_UPDATE(userUpdate)
//     SEARCH_UPDATE({location:place})
//     AUTH_LOGIN_GUEST() //logs in
// }

// This is a custom hook where hooks are allowed
//METHOD_LOGIN_AS_GUEST_WITH_PLACE
export const useGuestLoginWithPlace = () => {
    
    //Navigate
    const navigate = useNavigate();

    //Redux Items to Update
    const { USER_UPDATE } = useUserActions();
    const { AUTH_LOGIN_GUEST } = useAuthActions();
    const { SEARCH_UPDATE } = useSearchActions();
    
    const loginAsGuestWithPlace = (place) => {
        const userUpdate = { lat: place.lat, lng: place.lng };

        USER_UPDATE(userUpdate); // updates user info
        SEARCH_UPDATE({ location: place }); // updates search info
        AUTH_LOGIN_GUEST(); // logs in as guest
        navigate('/s');
    };

    return loginAsGuestWithPlace; // return the function for use in components
};