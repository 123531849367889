//Hemlet
import { Helmet } from 'react-helmet';


import UserAppContent from "./UserAppComponents/ContentSections/UserAppContent";
import NavBarLogin from  "apps/UserAppComponents/NavBarComponents/NavBarLogin";

import { HomeURLProvider } from "context/URL/HomeURLContext";
import { AppNavBarManagerProvider } from "context/AppNavBarContext";
import { useAuth } from "third_party/redux/selectors";

import { PageContainer } from "components/Page/Elements";

const UserApp = (props) => {

    const { isLoggedIn } = useAuth()

//GeneralSignUpMenuProvider provides context for a popup menu that goes over Homeontent
    return (
            <>
                <Helmet>
                    <title>GoghNow | Explore Event Services Near You</title>
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="GoghNow | The Future of Events" />
                    <meta name="description" content="GoghNow is a one-stop-shop for event services" />
                    <meta property="og:image" content="images/cta-logo-one.png" />
                </Helmet>
                <HomeURLProvider>  {/*updates url and gets data from url*/}
                <AppNavBarManagerProvider>

                        <PageContainer className="page-container">
                          <NavBarLogin/>
                            {/* {isLoggedIn ? ( */}
                            <UserAppContent />
                            {/* ) : ( */}
                                console.log('not logged in')
                            {/* )} */}
                        </PageContainer>
                </AppNavBarManagerProvider>
                </HomeURLProvider>
            </>
    );
};


export default UserApp;