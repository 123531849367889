import styled from 'styled-components';

// Import css files
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CartButton from './CartButton'
// import { toggleCartItem } from 'third_party/redux/cart/cartActions';
const  EventServiceImageContainer = ({item, addToCart, removeFromCart, cartItems, toggleCartItem}) => {

  const isSelected = cartItems.some(cartItem => cartItem.id === item.id);

  const settings = {
        dots: true, // Add navigation dots for paging
        infinite: true, // Enable continuous sliding
        slidesToShow: 1, // Number of images visible per slide (adjust as needed)
        slidesToScroll: 1, // Number of images to slide on user interaction
        responsive: [
          // Optional responsive settings for different screen sizes
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    return (
        <Container>
            <ImageSection>
                {item.images.length > 1 ? (
                    <InnerImageCarousel {...settings} prevArrow={<PrevArrow className="slick-prev"/>} nextArrow={<NextArrow className="slick-next"/>}>
                        {item.images.map((imageItem, index) => (
                          <ImageContainer key={index}>
                              <img src={imageItem.image} alt={imageItem.alt} />
                          </ImageContainer>
                        ))}
                    </InnerImageCarousel>
                ) : (
                    <ImageContainer>
                        <img src={item.images[0].image} alt={item.images[0].alt} />
                    </ImageContainer>
                )}
            </ImageSection>
            <CartButton item={item}
            addToCart={addToCart}
            removeFromCart={removeFromCart}
            cartItems={cartItems}
            toggleCartItem={toggleCartItem}/>
        </Container> 
    );
    
};

export default EventServiceImageContainer;

// const CartButton = styled.button `
// height: 35px;
// width: 35px;
// background-color: rgba(251, 174, 98, 0.6);
// color: black;
// border-radius: 50%;
// top : 0px; 
// right: 0px;
// display: flex;
// position: absolute;
// align-items: center;
// justify-content: center;
// border: 1px solid transparent;
// box-shadow: 0 0 10px rgba(251, 174, 98, 0.5);
// cursor: pointer;
// margin: 10px;

// &:hover{
//     background-color: rgba(251, 174, 98, 1);
// }
// `

const Container =  styled.div`
    display: flex;
    height: 100%; /* Fills available space within the grid item */
    position: relative;
    cursor: pointer;
    `

const ImageSection =  styled.div`
    display: flex;
    height: 100%; /* Fills available space within the grid item */
    width: 100%; /*added*/
    // aspect-ratio: 1 / 1; /* Sets aspect ratio to 1:1 (consider browser support) */
    // overflow-x: scroll;
    position: relative;
    cursor: pointer;

    `
const InnerImageCarousel =  styled(Slider)`

// display: flex;
height: 100%;
width: 100%;
border-radius: 15px;
overflow: hidden;
.slick-dots {
    position: absolute; /* Make dots absolute within the carousel */
    bottom: 10px; /* Adjust the bottom position as needed */
    left: 50%; /* Center the dots horizontally */
    transform: translateX(-50%); /* Offset to compensate for centering */
    display: flex; /* Ensure horizontal alignment */
    justify-content: center; /* Center dots within the carousel width */

    li {
        margin: -5px;
    }
  }

  &:hover .prev-button,
  &:hover .next-button {
    opacity: 1;
    color: black;
  }
`
const ImageContainer = styled.div`
height: 100%;
aspect-ratio: 1 / 1;
overflow: hidden;

img {
    display: block;
    width: 100%;  /* Fill container horizontally */
    height: 100%;  /* Fill container vertically (important change) */
    object-fit: cover;  /* Cover the container while maintaining aspect ratio */
    object-position: center; /* Center the image within the container */
    background-color: #00000040;
}
`

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 0;
  
//   display: none;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
    width: 40px;
    height: 40px;
    box-shadow: 0 0 10px rgba(251, 174, 98, 0.5);

  }

  font-weight: 600;
`;

const PrevArrowButton = styled(ArrowButton)`
  left: 10px;
`;

const NextArrowButton = styled(ArrowButton)`
  right: 10px;
`;

const PrevArrow = ({ onClick }) => (
  <PrevArrowButton className='prev-button' type="button" onClick={(e) => { e.stopPropagation(); onClick(e); }}>
     ←
  </PrevArrowButton>
);

const NextArrow = ({ onClick }) => (
  <NextArrowButton className='next-button' type="button" onClick={(e) => { e.stopPropagation(); onClick(e); }}>
     →
  </NextArrowButton>
);