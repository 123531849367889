import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';

//context
import {useState, useEffect, useRef } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { useScreenContext } from 'context/AppContext/ScreenContext';
import { useBusinessServiceContext } from 'api/Business/BusinessService/BusinessServiceAPIContext';


import NavBarLogout from 'pages/business/header/NavBarLogout';

//Common Components
import { PageContainer } from "components/Page/Elements";
import {PageContentContainer} from "components/Page/Elements"

//Items
import { NAVBAR_HEIGHT } from "components/constants";
import { CALLTOACTION, SECTION, STANDARDBUTTON } from "components/standard";
import HoverImage from "components/HoverImage/HoverImage";

import {mobileDisplayContentVertical,mobileDisplayContentHorizontal, mobileSizeDisplayNone, mobileSizeDisplayBlock, mobileSizeDisplayFlex} from 'components/emotionStyles'

import PopUpInfo from 'components/HoverImage/PopUpInfo';
import FAQManager from "components/FAQComponents/FAQManager";

//SIGNUP
import BusinessSignup from './signup/BusinessSignup';


//IMPORT SLICK CAROUSEL
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//components
import CheckoutError from 'pages/book/checkoutcomponents/checkoutError';
import SignUpSuccess from './signup/SignUpSuccess';

const Starter = {name:'Starter', price:'FREE', description:'A great way to give your clients access to GoghNow. Start earning for free.'};
const Pro = {name:'Pro (COMING SOON)', price:"$29.99", description:'Automated event booking solution. Ideal for businesses seeking an out-of-the-box solution to monetize their private spaces.'};
const Enterprise = {name:'Enterprise', price:"$99.99", description:'Custom automated event solution for enterprises. Designed for large businesses with complex processes, offering seamless integration and advanced features.'};



const trustby_images = [
    {alt:'CBRE',image: '/images/Business/logos/cbre.png'},
    {alt:'Greystar',image: '/images/Business/logos/greystar.png'},
    {alt:'Hoppin', image: '/images/Business/logos/hoppin.png'},
    {alt:'Crescent',image: '/images/Business/logos/crescent.png'},
    {alt:'Insperity',image: '/images/Business/logos/insperity.png'},
    {alt:'Kingsmen',image: '/images/Business/logos/kingsmen.png'},

]

const Business = (props) => {


const {isMobile, isMedium, isLarge} = useScreenContext()
const {serviceSignup} = useBusinessServiceContext()



// useEffect(() => {
//     const handleMouseMove = (e) => {
//       setMousePosition({ x: e.pageX, y: e.pageY });
//     };
  
//     // Attach event listener when highlightedItem changes
//     document.addEventListener('mousemove', handleMouseMove);
  
//     // Clean up event listener
//     return () => {
//       document.removeEventListener('mousemove', handleMouseMove);
//     };
//   }, [highlightedItem]); // Depend on highlightedItem to update mouse position when it changes
 

    
const servicesSectionRef = useRef(null);
const goToSignUpOfferings = () => {
    console.log("CLICKED!!!")
    servicesSectionRef.current.scrollIntoView({ behavior: 'smooth' });
}

    const itemIsHighlighted = useState(null)


    //KEEP
    const EventVenues = {
        x: 2048/4096,
        y: 750/4096,
        title: "Event Venues & Unique Spaces",
        description: "Become the premier venue for private events by offering a seamless, all-in-one booking experience.",
        ideal: ["Streamline Bookings","All-in-One Event Services","Expand Service Options","Increase Event Bookings","Earn from Partner Services"],
        image: "/images/Business/private.png"
    };

     //Keep
     const Multifamily = {
        x: 2048/4096,
        y: 750/4096,
        title: "Multifamily Communities",
        description: "Management can set up any style of event on-site, while residents enjoy easy access to diverse event services within private spaces.",
        ideal: ["Boost Revenue", "Engage Residents", "Access Event Services"],
        image: "/images/Business/apartment.jpeg"
    };

    //KEEP
    const Restaurants = {
        x: 2048/4096,
        y: 750/4096,
        title: "Bars, Breweries, & Restaurants",
        description: "Easily host themed events or offer clients a one-stop shop for private events and partner services—all without extra overhead.",
        ideal: ["Drive Private Events","Simplify Bookings","Keep Revenue In-House","One-Stop Event Hub","Increase Event Spend"],
        image: "/images/Business/restaurant.jpg"
    };

    const Commercial = {
        x: 2048/4096,
        y: 750/4096,
        title: "Commercial Properties",
        description: "Become the property that tenants flock to with effortless, unique event experiences.",
        ideal: ["Easy Event Setup", "Flexible Themed Spaces", "Maximize Event Revenue", "Easily Earn From Private Spaces"],
        image: "/images/Business/hotels.png"
    };

    const questionsAndAnswers = [
        { question: 'Own an event space?', answer: 'We simplify the booking process, making it easy for your clients to book your spaces directly through you.' },
        { question: 'Do you provide your own event services?', answer: 'We make it easier for your clients to seamlessly book your services. You always get 100% of the dollars earned from those booked services.' },
        { question: 'Do you want to offer more event services?', answer: 'Your clients have direct access to our network of local providers to expand your offerings. Our service providers fulfill those requests while you earn a portion of the revenue — with no added effort or overhead.' },
        // Add more questions and answers as needed
      ];
    
    
    


     const carouselSettings = {
        dots: false, // Add navigation dots for paging
        infinite: true, // Enable continuous sliding
        slidesToShow: 4.75, // Number of images visible per slide (adjust as needed)
        slidesToScroll: 1, // Number of images to slide on user interaction
        responsive: [
          // Optional responsive settings for different screen sizes
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2.75,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.75,
              slidesToScroll: 1,
            },
          },
        ],
      };

      //VENU TYPE
      const [highlightedItem, setHighlightedItem] = useState(null);
    //   const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    const handleMouseEnter = (data) => {
        console.log('handleMouseEnter: ',data)
        if (data.title !== highlightedItem?.title) {
          setHighlightedItem(data);
        }
      };
    
      const handleMouseLeave = () => {
        setHighlightedItem(null);
      };
    
      //THIS IS WHAT'S NEEDED FOR SIGNUP SHEET
      const [isSignupOpen, setSignupOpen] = useState(false);
      const [serviceType, setserviceType] = useState('');
      const formRef = useRef(null)
      const [invalidFields, setInvalidFields] = useState(new Set());
      const [submitting, setSubmitting] = useState(false);
      const [isErrorPopupVisible, setErrorPopupVisible] = useState(false);
      const [showSuccessPopup, setShowSuccessPopup] = useState(false);

      const [errorMessage, setErrorMessage] = useState('');
      const showErrorPopup = (message) => {
          setErrorMessage(message);
          setErrorPopupVisible(true);
        };
      
        const closeErrorPopup = () => {
          setErrorPopupVisible(false);
          setErrorMessage('');
        };

        

      const handleOpenSignup = (type) => {
        setSignupOpen(true);
        setserviceType(type)
        console.log(type)
      };
    
      const handleCloseSignup = () => {
        setShowSuccessPopup(false)
        setSignupOpen(false);
        //TODO: CLEAR FORM
      };

      const handleSuccessCloseSignup = () => {
        setShowSuccessPopup(false)
        setSignupOpen(false);
        //TODO: CLEAR FORM
        formRef.current.reset();
      };
    

      const handleSubmit = async (userData) => {

        // event.preventDefault();
        const isFormValid = formRef.current.checkValidity();
        if (isFormValid) {
        console.log('Form is valid! Submitting...');
        setSubmitting(true); // Set submitting state to true
        try {
            await handleCreateRequest(userData);
          } catch (error) {
            setSubmitting(false); // Reset submitting state on error
            console.error('Error submitting form:', error);
          }
        } else {
        // Do something when the form is not valid
        const invalidFields = getInvalidFields(formRef);
        showErrorPopup(`Invalid form fields: ${invalidFields.join(', ')}\nPlease check your inputs.`);
    }
    };

    const getInvalidFields = (formRef) => {
        const formElements = formRef.current.elements;
        const invalidFields = [];
    
        for (let i = 0; i < formElements.length; i++) {
            const field = formElements[i];
            if (!field.checkValidity()) {
                const fieldName = field.name || field.id;
                if (fieldName) {
                    invalidFields.push(fieldName);
                }
            }
        }
    
        return invalidFields;
    };
    const handleCreateRequest = async (data) => {
        try{
            const result = await serviceSignup(data);
            console.log('Request successful:', result);
            setShowSuccessPopup(true);
        }
        catch (error){
            console.error('Request failed:', error);
            // Show popup or any other error handling logic
            showErrorPopup(error.message);
        } finally {
            setSubmitting(false)
        }
      };

      const scrollToTop = () =>{
        console.log("this is working?")
        // setTimeout(() => contentRef.current?.scrollTo({ top: 0, behavior: 'smooth' }), 10);
    } 

return (

    <>
    <PageContainer className="page-container">
    <NavBarLogout handleNavigateHome={scrollToTop}/>
    <PageContentContainer className="content">
        <Content>
            
            <HeroSection>
                <HeroFlexContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                    <HeroFlexItem1 $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        <CTA>
                            <HeroDescription><b>GOGHNOW FOR BUSINESS</b></HeroDescription>
                            <HeroText>Earn more from your spaces</HeroText>
                            <HeroTextSubDescription><i>GoghNow for business</i> booking your private spaces made easy.</HeroTextSubDescription>
                            {/* Enjoy profit sharing and increased revenue, all managed from an easy-to-use dashboard. Sign up for a free business account today! */}
                            <SignUpButton onClick={goToSignUpOfferings} >Sign up for free!</SignUpButton>
                            {/* <ActionButton>Sign In</ActionButton> */}
                        </CTA>
                    </HeroFlexItem1>
                    <HeroFlexItem2 $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        <ImageContainer>
                            <img src='/images/space2.jpeg' alt="space" />
                        </ImageContainer>
                    </HeroFlexItem2>
                </HeroFlexContainer>
            </HeroSection>
            <BenefitsSection>
                <BenefitsHeader>How companies leverage GoghNow for Business</BenefitsHeader>
                <OfferingContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                    <Offering>
                        <OfferingImageContainer>
                            <img src='/images/Business/simplify.png' alt="provider" />
                        </OfferingImageContainer>
                        <OfferingContentContainer>
                            <OfferingHeader>Streamline Bookings</OfferingHeader>
                            <OfferingDescription>Effortlessly manage and book your private spaces.</OfferingDescription>
                            {/* <OfferingLink>Sign up today →</OfferingLink> */}
                        </OfferingContentContainer>
                    </Offering>
                    <Offering>
                        <OfferingImageContainer>
                            <img src='/images/Business/integrate.png' alt="provider" />
                        </OfferingImageContainer>
                        <OfferingContentContainer>
                            <OfferingHeader>Expand Offerings</OfferingHeader>
                            <OfferingDescription>Instantly offer a wider range of event services with ZERO overhead.</OfferingDescription>
                            {/* <OfferingLink>Start earning →</OfferingLink> */}
                        </OfferingContentContainer>

                    </Offering>
                    <Offering>
                        <OfferingImageContainer>
                            <img src='/images/Business/profit.png' alt="provider" />
                        </OfferingImageContainer>

                        <OfferingContentContainer>
                            <OfferingHeader>Increase Revenue</OfferingHeader>
                            <OfferingDescription>Earn 100% of services you can provide and earn a portion of the services that are provided by our partners.</OfferingDescription>
                            {/* <OfferingLink>Sign up your business →</OfferingLink> */}
                        </OfferingContentContainer>

                    </Offering>
                </OfferingContainer>
            </BenefitsSection>


            <VenuesSection>
                <VenuesHeader>How we power industries</VenuesHeader>
                    <VenueGridContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        <VenueGridItem>
                            <VenueGridItemContainer>
                                <HoverImage
                                    isHighlighted={highlightedItem?.title === EventVenues.title}
                                    data={EventVenues}
                                    onMouseEnter={() => handleMouseEnter(EventVenues)}
                                    onMouseLeave={handleMouseLeave}
                                />
                            </VenueGridItemContainer>
                        </VenueGridItem>

                        <VenueGridItem>
                            <VenueGridItemContainer>
                                <HoverImage 
                                isHighlighted={highlightedItem?.title === Multifamily.title}
                                data={Multifamily}
                                onMouseEnter={() => handleMouseEnter(Multifamily)}
                                onMouseLeave={handleMouseLeave}
                                />
                            </VenueGridItemContainer>
                        </VenueGridItem>

                        <VenueGridItem>
                            <VenueGridItemContainer>
                                <HoverImage 
                                isHighlighted={highlightedItem?.title === Restaurants.title}
                                data={Restaurants}
                                onMouseEnter={() => handleMouseEnter(Restaurants)}
                                onMouseLeave={handleMouseLeave}
                                />
                            </VenueGridItemContainer>
                        </VenueGridItem>

                        <VenueGridItem>
                            <VenueGridItemContainer>
                                <HoverImage 
                                isHighlighted={highlightedItem?.title === Commercial.title}
                                data={Commercial}
                                onMouseEnter={() => handleMouseEnter(Commercial)}
                                onMouseLeave={handleMouseLeave}
                                />
                            </VenueGridItemContainer>                    
                        </VenueGridItem>

                        {/* <VenueGridItem className="lg" $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                            <VenueGridItemContainer>
                                <HoverImage 
                                isHighlighted={highlightedItem?.title === Private.title}
                                data={Private}
                                onMouseEnter={() => handleMouseEnter(Private)}
                                onMouseLeave={handleMouseLeave}
                                />
                            </VenueGridItemContainer>                 
                        </VenueGridItem>

                        <VenueGridItem className="sm" $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                            <VenueGridItemContainer>
                                <HoverImage 
                                isHighlighted={highlightedItem?.title === Clubhouses.title}
                                data={Clubhouses}
                                onMouseEnter={() => handleMouseEnter(Clubhouses)}
                                onMouseLeave={handleMouseLeave}
                                />
                            </VenueGridItemContainer>                    
                        </VenueGridItem>

                        <VenueGridItem className="sm" $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                            <VenueGridItemContainer>
                                <HoverImage
                                isHighlighted={highlightedItem?.title === Communities.title}
                                data={Communities}
                                onMouseEnter={() => handleMouseEnter(Communities)}
                                onMouseLeave={handleMouseLeave}
                                />
                            </VenueGridItemContainer>                    
                        </VenueGridItem>

                        <VenueGridItem className="md" $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                            <VenueGridItemContainer>
                                <HoverImage
                                isHighlighted={highlightedItem?.title === Museum.title}
                                data={Museum}
                                onMouseEnter={() => handleMouseEnter(Museum)}
                                onMouseLeave={handleMouseLeave}
                                />
                            </VenueGridItemContainer>                    
                        </VenueGridItem> */}

                    </VenueGridContainer>
            </VenuesSection>



            <TrustedBySection>
                <TrustedByHeader>Trusted By</TrustedByHeader>
                <ContainerCarousel {...carouselSettings} prevArrow={<PrevArrow className="slick-prev"/>} nextArrow={<NextArrow className="slick-next"/>}>
                    {trustby_images.map((imageItem, index) => (
                        <ImageContainerCarousel key={index}>
                            <img src={imageItem.image} alt={imageItem.alt} />
                        </ImageContainerCarousel>
                    ))}
                </ContainerCarousel>

            </TrustedBySection>




            <UnsureSection>
                <UnsureHeader>Not quite ready to get started?</UnsureHeader>

                <UnsureSubHeader>Check out the following Frequently Asked Questions to learn more about how GoghNow for Business can help support your company.</UnsureSubHeader>

                <FAQManagerSpecial questionsAndAnswers={questionsAndAnswers} title = {''} />
            </UnsureSection>

            <ServicesSection ref={servicesSectionRef}>
                {/* <ServicesHeader>Choose a Plan</ServicesHeader>
                <ServicesSubHeader>Get access to event services and start earning for free. Upgrade for more features.</ServicesSubHeader> */}
                <ServicesFlexContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                    {(isLarge) &&
                    <ServicesItemLeft>
                        <ServicesHeader>Questions about GoghNow for Business? We're happy to help.</ServicesHeader>
                        {/* <ServicesSubHeader>Get access to event services and start earning for free. Upgrade for more features.</ServicesSubHeader> */}
                        <ImageContainer>
                            <img src='/images/Home/image1.png' alt="space" />
                        </ImageContainer>
                    </ServicesItemLeft>
                    }
                    <ServicesItemRight>
                        <ServicesHeaderRight $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>Get in touch with us</ServicesHeaderRight>


                        <BusinessSignup 
                            isOpen={true} 
                            onClose={handleCloseSignup} 
                            onSubmit={handleSubmit}
                            formRef={formRef}
                            submitting={submitting}
                            // name= {serviceType.name}
                            // description = {serviceType.description}
                        />

                    </ServicesItemRight>

                    {/* <ServicesFlexItem1 $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}> 
                            <ServiceCategory>STARTER</ServiceCategory>
                            <InternalFlexContainer>
                                    <FlexItemTitle>FREE</FlexItemTitle>
                            </InternalFlexContainer>
                            <FlexItemOverview $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>A great way to give your clients access to GoghNow. Start earning for free.</FlexItemOverview>
                            <SignUpButton onClick={() => handleOpenSignup(Starter)} >Request Access</SignUpButton>
                            <p><b>What's included:</b></p>
                            <FlexItemList>
                                <FlexItemAttributeContainer>
                                    <FlexItemListImgContainer><img src='/images/Business/checkmark-grn.png' alt="provider" /></FlexItemListImgContainer>
                                    <p>Earn with every booking</p>
                                </FlexItemAttributeContainer>
                                <FlexItemAttributeContainer>
                                    <FlexItemListImgContainer><img src='/images/Business/checkmark-grn.png' alt="provider" /></FlexItemListImgContainer>
                                    <p>Dedicated event services selection link</p>
                                </FlexItemAttributeContainer>
                                <FlexItemAttributeContainer>
                                    <FlexItemListImgContainer><img src='/images/Business/checkmark-grn.png' alt="provider" /></FlexItemListImgContainer>
                                    <p>Basic support</p>
                                </FlexItemAttributeContainer>
                            </FlexItemList>
                    </ServicesFlexItem1> */}

                    {/* <ServicesFlexItem2 $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                            <ServiceCategory>PRO (COMING SOON)</ServiceCategory>
                            <InternalFlexContainer>
                                <FlexItemTitle>$29.99</FlexItemTitle>
                                <FlexItemTitleMetric> / per month</FlexItemTitleMetric>
                            </InternalFlexContainer>
                            <FlexItemOverview $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>Automated event booking solution. Ideal for businesses seeking an out-of-the-box solution to monetize their private spaces.</FlexItemOverview>
                            <SignUpButton onClick={() => handleOpenSignup(Pro)} >Coming Soon!</SignUpButton>
                            <p><b>Everything in FREE plus:</b></p>
                            <FlexItemList>
                                <FlexItemAttributeContainer>
                                    <FlexItemListImgContainer><img src='/images/Business/checkmark-grn.png' alt="provider" /></FlexItemListImgContainer>
                                    <p>Seamless booking forms</p>
                                </FlexItemAttributeContainer>
                                <FlexItemAttributeContainer>
                                    <FlexItemListImgContainer><img src='/images/Business/checkmark-grn.png' alt="provider" /></FlexItemListImgContainer>
                                    <p>Automated and branded communications</p>
                                </FlexItemAttributeContainer>
                                <FlexItemAttributeContainer>
                                    <FlexItemListImgContainer><img src='/images/Business/checkmark-grn.png' alt="provider" /></FlexItemListImgContainer>
                                    <p>Automated event services link for each client event</p>
                                </FlexItemAttributeContainer>
                                <FlexItemAttributeContainer>
                                    <FlexItemListImgContainer><img src='/images/Business/checkmark-grn.png' alt="provider" /></FlexItemListImgContainer>
                                    <p>Dashboard tracking events & services needs</p>
                                </FlexItemAttributeContainer>
                                <FlexItemAttributeContainer>
                                    <FlexItemListImgContainer><img src='/images/Business/checkmark-grn.png' alt="provider" /></FlexItemListImgContainer>
                                    <p>Priority support</p>
                                </FlexItemAttributeContainer>
                            </FlexItemList>
                    </ServicesFlexItem2> */}


                    {/* <ServicesFlexItem3 $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        
                            <ServiceCategory>ENTERPRISE</ServiceCategory>
                            <FlexItemTitleMetric> ( starting at )</FlexItemTitleMetric>
                            <InternalFlexContainer>
                                <FlexItemTitle>$99.99</FlexItemTitle>
                                <FlexItemTitleMetric> / per month</FlexItemTitleMetric>
                            </InternalFlexContainer>
                            <FlexItemOverview $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>Custom automated event solution for enterprises. Designed for large businesses with complex processes, offering seamless integration and advanced features.</FlexItemOverview>
                            <SignUpButton onClick={() => handleOpenSignup(Enterprise)} >Get it Now</SignUpButton>
                            <p><b>Everything in PRO plus:</b></p>
                            <FlexItemList>
                                <FlexItemAttributeContainer>
                                    <FlexItemListImgContainer><img src='/images/Business/checkmark-grn.png' alt="provider" /></FlexItemListImgContainer>
                                    <p>Customize the entire GoghNow booking experience</p>
                                </FlexItemAttributeContainer>
                                <FlexItemAttributeContainer>
                                    <FlexItemListImgContainer><img src='/images/Business/checkmark-grn.png' alt="provider" /></FlexItemListImgContainer>
                                    <p>Customize GoghNow process workflows</p>
                                </FlexItemAttributeContainer>
                                <FlexItemAttributeContainer>
                                    <FlexItemListImgContainer><img src='/images/Business/checkmark-grn.png' alt="provider" /></FlexItemListImgContainer>
                                    <p>Dedicated account manager</p>
                                </FlexItemAttributeContainer>
                            </FlexItemList>
                    </ServicesFlexItem3> */}


                </ServicesFlexContainer>
            </ServicesSection>


            {/* Popup Info */}
            {highlightedItem && (
                <div onMouseEnter={(e)=>{e.stopPropagation()}} onMouseLeave={(e)=>{e.stopPropagation()}}>
                    <PopUpInfo
                        noBackground={!isMobile}
                        image={highlightedItem.image}
                        title={highlightedItem.title}
                        description={highlightedItem.description}
                        // Appear={true}
                        SlideUp={true} // Add this prop to enable slide-up animation
                        list={highlightedItem.ideal}
                    />
                </div>
            )}
        </Content>
    </PageContentContainer>
    {/* <BusinessSignup 
        isOpen={isSignupOpen} 
        onClose={handleCloseSignup} 
        onSubmit={handleSubmit}
        formRef={formRef}
        submitting={submitting}
        name= {serviceType.name}
        description = {serviceType.description}
    /> */}
        {showSuccessPopup && 
            <SignUpSuccess onClose={handleSuccessCloseSignup}/>
        }
        {isErrorPopupVisible && (
        <CheckoutError
          message={errorMessage}
          onClose={closeErrorPopup}>
        </CheckoutError>
      )}
     
    </PageContainer>
    </>

)

}

export default Business;

const Content = styled.div`
width: 100%;
height: 100%;
`
const HeroSection = styled(SECTION)`
background-color: black;

`;

const HeroFlexContainer = styled.div`

${mobileDisplayContentVertical};
    display: flex;
    width: 100%;
    // padding: 15px 36px;
    // max-width: 1280px;
    gap: 36px;

`
const HeroFlexItem1 = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: left;
    justify-content: start;
    align-items: center;
    width: ${props => {
        if (props.$isMobile) return '100%';
        if (props.$isMedium) return '100%';
        if (props.$isLarge) return '50%';
        return '50%'; // Default to large if none match
    }}; 
    height: auto !important;
`
const HeroFlexItem2 = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: ${props => {
        if (props.$isMobile) return '100%';
        if (props.$isMedium) return '100%';
        if (props.$isLarge) return '50%';
        return '50%'; // Default to large if none match
    }}; 
    // height: 50vh !important;

`
const ImageContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    // position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
//Container joint
const CTA = styled(CALLTOACTION)`
// padding-bottom: calc(${NAVBAR_HEIGHT} + ${NAVBAR_HEIGHT});
gap: 15px;
align-items: start !important;
p, h1{
margin: 0px;
}
`;

const SignUpButton = styled.div`
color: #FFFFFF;
font-weight: 700;
font-size: 22px;
cursor: pointer;
background-color: #FBAE6290;
padding: 15px;
border-radius: 10px;

&:hover{
color: #FFFFFF;
background-color: #FBAE62;
}
`
const HeroText = styled.h1`
color: #FFFFFF;
// font-size: 3.5em;
// font-weight: 700;
  font-size: 4em;
  font-weight: 600;
margin-top: 0px;
// line-height: 60px;
`
const HeroDescription = styled.p`
color: #FBAE62;
font-size : .8em;
margin: 0 0 24px;
line-height: 1.5em;
// letter-spacing: .75px;
max-width: 400px;
margin-bottom: 0px;
padding-bottom: 0px;
font-weight: 400;
// border-radius: 15px;
// background-color: white;
padding: 15px;
`

const HeroTextSubDescription = styled.h3`
color: #FFFFFF;
margin: 0 0 24px;
// line-height: 1.5em;
// letter-spacing: 1.5px;
font-weight: 200;
max-width: 500px;
margin-bottom: 25px;
`




//BENEFITS SECTION
const BenefitsSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 36px;
  color: black;
  gap: 20px;
  min-height: 50vh; /* Ensures the div is at least 50% of the viewport height */
  box-sizing: border-box; /* Ensures padding is included in the height calculation */
  padding: 50px 36px;
  align-items: center;
  justify-content: center;
`;

const BenefitsHeader = styled.h2`
//   color: #3F3F3F;
//   font-size: 3em;
//   font-weight: 600;
//   margin-top: 0px;
//   line-height: 95%;
//   width: 100%;
//   text-align: center;
`;

const OfferingContainer = styled.div`

${mobileDisplayContentVertical};
display: grid;
grid-template-columns:${props => {
    if (props.$isMobile) return 'repeat(1, 1fr)';
    if (props.$isMedium) return 'repeat(2, 1fr)';
    if (props.$isLarge) return 'repeat(3, 1fr)';
    return 'repeat(3, 1fr)'; // Default to large if none match
  }};
grid-auto-rows: 1fr;
width: 100%;
gap: 30px;
justify-content: space-around;
padding: 25px 0px;

@media (max-width: 769px) {
    gap: 20px;
    // padding: 20px 0px;
}
`

const Offering = styled.div`
${mobileDisplayContentHorizontal};

display: flex;
// flex: 1 1 calc(33.3% - 25px);
flex-direction: column;
gap:15px;
justify-content: center;
align-items: start;

// @media (max-width: 769px) {
//     gap: 20px;
//     padding: 20px 0px;
// }
`

const OfferingImageContainer = styled.div`
margin-bottom: 0px;

width: 20%;
aspect-ratio: 1 / 1;
font-size: 0px;
display: flex;

img {
    display: block;
    width:100%;
}

@media (max-width: 769px) {
    flex-grow: 0;
    width: 50%;
    
}

@media (max-width: 450px) {
    flex-grow: 0;
    width: 25%;
    
}
`

const OfferingContentContainer = styled.div`
display: flex;
flex-direction:column;
width: 100%;
padding: 0px 0px;
 @media (max-width: 769px) {
    text-align: left;
    padding-left: 10px;
    flex-grow: 1;

        p{
    margin: 0px;
    }
}
`

const OfferingHeader = styled.h3`
color:black;
font-weight: 700;
// line-height: 90%;
margin-bottom: 0px;
margin-top: 0px;
padding-bottom: 0px;
`
const OfferingDescription = styled.h4`
padding-top: 0px;
margin-top: 0px;
color:black;
// font-size: 18px;
font-weight: 200;
`

//This was the link error
const OfferingLink = styled.div`
color: #FBAE62;
font-weight: 700;
font-size: 18px;
cursor: pointer;
`


//GOGHNOW POWERS ALL VENUES
const VenuesSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 36px;
background-color: #000000;
  color: black;
  height: auto;
  align-items: center;
  justify-content: center;
//   box-sizing: border-box; /* Ensures padding is included in the height calculation */
  min-height: 100vh;
`
const VenuesHeader = styled.h2`
// font-size: 2.5em;
//   text-align: center;
//   color: #FFFFFF;
// letter-spacing: .10px;
// font-weight: 700;
//   line-height: 95%;
  padding: 50px 0px;
  color: #FFFFFF;
  width: 100%;


`;

const VenueGridContainer = styled.div `

${mobileDisplayContentVertical};
display: grid;
grid-template-columns:${props => {
    if (props.$isMobile) return 'repeat(2, 1fr)';
    if (props.$isMedium) return 'repeat(2, 1fr)';
    if (props.$isLarge) return 'repeat(4, 1fr)';
    return 'repeat(4, 1fr)'; // Default to large if none match
  }};
grid-auto-rows: 1fr;
width: 100%;
gap: 15px;
justify-content: space-around;
padding: 15px 0px;



@media (max-width: 769px) {
    gap: 0px;
    padding: 20px 0px;
}

@media (max-width: 450px) {
    gap: 0px;
    padding: 0px 0px;
}
`

const VenueGridItem = styled.div `

    // background-color: #00000040;
    // border: 1px solid #CCCCCC;

    
    ${mobileDisplayContentVertical};

display: flex;
flex: 1 1 calc(33.3% - 25px);
flex-direction: column;
justify-content: center;
align-items: center;
background-color: #EEE;
border: 1px solid #CCC;
// padding: 15px;
aspect-ratio: 1/1;
grid-row: span 1;
grid-column: span 1;

@media (max-width: 769px) {
    // gap: 20px;
    // padding: 20px 0px;
}
    `

const VenueGridItemContainer = styled.div`
//   position: absolute; /* Added to position the container absolutely */
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
  display: flex;
//   justify-content: center;
//   align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

const VenueGridItemContainerHorizontal = styled.div`
display: flex;
width: 100%;
overflow: hidden;
align-items: center;
justify-content: center;
aspect-ratio: 2 / 1;
`


//Trusted By Section
const TrustedBySection = styled.div`
display: flex;
flex-direction: column;
padding: 36px 36px;
margin: 0vh 0px 5vh 0px;
height: auto;
// background-color: purple;
`

const TrustedByHeader = styled.h2`
// font-size: 2.5em;
// color: black;
// letter-spacing: .10px;
// font-weight: 1000;
// line-height: 95%;
text-align: center;
padding: 50px 0px;
`

const ContainerCarousel =  styled(Slider)`

display: flex !important;
width: 100%;
overflow: hidden;

/* the slides */
  .slick-slide {
      padding: 0px 25px 0px 15px;
  }

  /* the parent */
  .slick-list {
      margin: 0 -25px;
  }
.slick-dots {
    position: absolute; /* Make dots absolute within the carousel */
    bottom: 10px; /* Adjust the bottom position as needed */
    left: 50%; /* Center the dots horizontally */
    transform: translateX(-50%); /* Offset to compensate for centering */
    display: flex; /* Ensure horizontal alignment */
    justify-content: center; /* Center dots within the carousel width */

    li {
        margin: -5px;
    }
  }

  &:hover .prev-button,
  &:hover .next-button {
    opacity: 1;
    color: black;
  }
`
const ImageContainerCarousel = styled.div`
height: auto;
aspect-ratio: 2 / 1;
overflow: hidden;
// padding: 15px;
// margin: 15px;
max-width: 100%;
display:flex !important;
justify-content: center;
align-items: center;
border: solid 1px white;
background-color: white;
img {
    display: flex;
   max-width: 100%;  /* Fill container horizontally */
}
`

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 0;
  
//   display: none;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
    width: 40px;
    height: 40px;
    box-shadow: 0 0 10px rgba(251, 174, 98, 0.5);

  }

  font-weight: 600;
`;

const PrevArrowButton = styled(ArrowButton)`
  left: 10px;
`;

const NextArrowButton = styled(ArrowButton)`
  right: 10px;
`;

const PrevArrow = ({ onClick }) => (
  <PrevArrowButton className='prev-button' type="button" onClick={(e) => { e.stopPropagation(); onClick(e); }}>
     ←
  </PrevArrowButton>
);

const NextArrow = ({ onClick }) => (
  <NextArrowButton className='next-button' type="button" onClick={(e) => { e.stopPropagation(); onClick(e); }}>
     →
  </NextArrowButton>
);






//Services By Section
const ServicesSection = styled.div`
display: flex;
flex-direction: column;
padding: 36px 36px;
// margin: 0vh 0px 25vh 0px;
height: auto;
background-color: white;
align-items: center;
`

const ServicesHeader = styled.h2`
// font-size: 2.5em;
// text-align: center;
// color: black;
// letter-spacing: .10px;
// font-weight: 1000;
// line-height: 95%;
padding: 50px 0px 0px;
`

const ServicesHeaderRight = styled.h3`
color:black;
// font-weight: 700;
// line-height: 90%;
margin-bottom: 0px;
margin-top: 0px;
padding-bottom: 0px;
width: 100%;
text-align: ${props => {
    if (props.$isMobile) return 'center';
    if (props.$isMedium) return 'center';
    if (props.$isLarge) return 'left';
    return 'left'; // Default to large if none match
}}; 
padding: 25px 0px;
font-size: 22px;
`
const ServicesSubHeader = styled.p`
// font-size: 2.5em;
text-align: center;
color: black;
letter-spacing: .10px;
font-weight: 1000;
line-height: 95%;
margin: 0px;
`

const ServicesFlexContainer = styled.div`

${mobileDisplayContentVertical};
    display: flex;
    grid-template-columns:${props => {
    if (props.$isMobile) return 'repeat(1, 1fr)';
    if (props.$isMedium) return 'repeat(1, 1fr)';
    if (props.$isLarge) return 'repeat(2, 1fr)';
    return 'repeat(2, 1fr)'; // Default to large if none match
  }};
    grid-auto-rows: 1fr;
    width: 100%;
    padding: 36px 36px;
    // max-width: 1280px;
    gap: 50px;
    // flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 769px) {
    gap: 25px;
    padding: 20px 0px;
    }

    @media (max-width: 450px) {
        gap: 0px;
        padding: 0px 0px;
    }
`

const ServicesItemLeft = styled.div`
${mobileDisplayContentVertical};

display: flex;
flex: 1 1 calc(33.3% - 25px);
flex-direction: column;
justify-content: center;
align-items: center;
// background-color: #EEE;
// border: 1px solid #CCC;
padding: 15px;
gap:25px;

@media (max-width: 769px) {
    // gap: 20px;
    padding: 20px 0px;
}
`

const ServicesItemRight = styled.div`
${mobileDisplayContentVertical};

display: flex;
flex: 1 1 calc(33.3% - 25px);
flex-direction: column;
justify-content: center;
align-items: center;
background-color: #EEE;
// border: 1px solid #CCC;
padding: 15px;

@media (max-width: 769px) {
    // gap: 20px;
    padding: 20px 15px;
}
`


const ServicesFlexItem1 = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: left;
    justify-content: start;
    align-items: center;
    width: ${props => {
        if (props.$isMobile) return 'calc(100% - 15px)';
        if (props.$isMedium) return 'calc(100% - 15px)';
        if (props.$isLarge) return 'calc(33.3% - 15px)';
        return '50%'; // Default to large if none match
    }}; 
    height: auto !important;
    background-color: #3F3F3F;
    padding: ${props => {
        if (props.$isMobile) return '25px';
        if (props.$isMedium) return '36px';
        if (props.$isLarge) return '36px';
        return '50%'; // Default to large if none match
    }}; 
    border-radius: 20px;

`
const ServicesFlexItem2 = styled.div`
    opacity: 0.75;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: ${props => {
        if (props.$isMobile) return 'calc(100% - 15px)';
        if (props.$isMedium) return 'calc(100% - 15px)';
        if (props.$isLarge) return 'calc(33.3% - 15px)';
        return '50%'; // Default to large if none match
    }}; 
    // height: 50vh !important;
    background-color: #3F3F3F60;
    padding: ${props => {
        if (props.$isMobile) return '25px';
        if (props.$isMedium) return '36px';
        if (props.$isLarge) return '36px';
        return '50%'; // Default to large if none match
    }}; 
    border-radius: 20px;
    border: 4px dotted #00000060;

`

const ServicesFlexItem3 = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: ${props => {
        if (props.$isMobile) return 'calc(100% - 15px)';
        if (props.$isMedium) return 'calc(50% - 15px)';
        if (props.$isLarge) return 'calc(33.3% - 15px)';
        return '50%'; // Default to large if none match
    }}; 
    // height: 50vh !important;
    background-color: #3F3F3F;
    padding: ${props => {
        if (props.$isMobile) return '25px';
        if (props.$isMedium) return '36px';
        if (props.$isLarge) return '36px';
        return '50%'; // Default to large if none match
    }}; 
    border-radius: 20px;
`



const InternalFlexContainer = styled.div`
display: flex;
align-items: end;
margin-bottom: 10px;
`

const FlexItemOverview = styled.p`
min-height: ${props => {
    if (props.$isMobile) return '0px';
    if (props.$isMedium) return '0px';
    if (props.$isLarge) return '6.5em';
    return '0px'; // Default to large if none match
}}; 
`
const FlexItemTitle = styled.h1`
color: #FFFFFF;
font-size: 3.5em;
font-weight: 700;
margin-top: 0px;
line-height: 50px;
margin: 0px;
`

const FlexItemTitleMetric = styled.p`
color: #FFFFFF80;
font-size : .8em;
line-height: 1.5em;
max-width: 400px;
font-weight: 400;
border-radius: 15px;
margin: 0px;

`

const ServiceCategory = styled.p`
font-weight: bold;
`

const FlexItemList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin-bottom: 20px;

    p {
    margin: 0px;
    }
`;

const FlexItemAttributeContainer = styled.li`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: start;
`;

const FlexItemListImgContainer = styled.div`
    display: flex;
    height: 32px;
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    img {
    display: block;
    width:100%;
}
`;








//Services By Section
const UnsureSection = styled.div`
display: flex;
flex-direction: column;
padding: 36px 36px;
margin: 0vh 0px 0px 0px;
height: auto;
background-color: #CCC;
align-items: center;
`

const UnsureHeader = styled.h2`
// font-size: 2.5em;
// text-align: center;
// color: black;
// letter-spacing: .10px;
// font-weight: 1000;
// line-height: 95%;
padding: 50px 0px 0px 0px;
// padding-bottom: 0px;
margin-bottom: 0px;
`
const UnsureSubHeader = styled.p`
// font-size: 2.5em;
text-align: center;
color: black;
// letter-spacing: .10px;
// font-weight: 1000;
// line-height: 95%;
// margin: 0px;
padding-bottom: 0px;
margin-bottom: 0px;
`

const FAQManagerSpecial = styled(FAQManager)`
// padding-top: 25px;
// margin-bottom: 0px;
padding: 0px;
margin:0px;

`
