// import styled from 'styled-components';
import styled from 'styled-components';
import {AddButton} from 'components/standard'


const CartMenuItem = ({ item, cartItems, removeFromCart }) => {

    const handleClick = () => {
            removeFromCart(item);
    };


return (
    <Container>
        <ImageSection>
          <img src={item.images[0].image} alt={item.images[0].alt} />
        </ImageSection>
        <DetailsSection>
            <h1>{item.title}</h1>
            {item.description && <p>{item.description}</p>}
        </DetailsSection>
        <RemoveSection>
            <RemoveButton onClick={handleClick}>-</RemoveButton>
        </RemoveSection>
    </Container>
    );
};

export default CartMenuItem;

const Container = styled.div`
padding: 7.5px 0px;
position: relative;
display: flex;
width: 100%;
gap: 10px;
border-bottom: 1px solid black;
&:hover{
    background-color: #00000010;
}
`

const ImageSection = styled.div`
display: flex;
aspect-ratio: 1 / 1;
height: 75px;
background-color: #CCC;
width: auto;
img {
    display: block;
    width: 100%;  /* Fill container horizontally */
    height: 100%;  /* Fill container vertically (important change) */
    object-fit: cover;  /* Cover the container while maintaining aspect ratio */
    object-position: center; /* Center the image within the container */
    background-color: #00000040;
}
`
const DetailsSection = styled.div`
display: flex;
  flex-direction: column;
  flex: 1 1 calc(50% - 50px);
  padding-bottom: 1em;


  h1 {
    color:black;
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  p {
    /* Default color, overridden by enabled/disabled styles */
    color:#00000060;
    margin: 0px;
  }

`;

const RemoveSection = styled.div`
display: flex;
align-items: center;
justify-content: center;
aspect-ratio: 1 / 1;
height: 75px;
background-color: transparent;
width: auto;
`
const RemoveButton = styled(AddButton)`
position: relative;
&:hover{
    background-color: orange !important;
}
`



//   ${(props) =>
//     props.enabled
//       ? css`
//           cursor: pointer;

//           &:hover {
//             opacity: 0.60;
//           }

//           h1 {
//             color: black;
//           }

//           p {
//             color: #00000060;
//           }
//         `
//       : css`
//           cursor: default;

//           &:hover {
//             opacity: 1.0;
//           }

//           h1 {
//             color: #ccc;
//           }

//           p {
//             color: #ccc;
//           }
//         `}
