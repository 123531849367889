import { FILTER_BY_CATEGORY, FILTER_RESET } from "../actionTypes";

//#################################
//#############Initial States############
//#################################
//dictionary key shows us what to do with the filter item
//key within the dictionary is what property to do it on
const initialSearchFilter = {
    stringFilters: {
      category: 'all'
    },
    rangeFilters: {
      // unitPrice: {min:0, max:1000}
    },
    booleanFilters: {
      // canTravel: false
    }
  };



const searchFilterReducer = (state = initialSearchFilter, action) => {

    switch(action.type){

        case FILTER_BY_CATEGORY: {
            return {
                ...state,
                stringFilters:{
                    ...state.stringFilters, // Spread the existing tempSearchCriteria
                    category: action.payload
                }   
            }

        }
        break

        case FILTER_RESET:{
            return initialSearchFilter
        }

        default:
            return state
    }
}

export default searchFilterReducer