import { BaseModel } from "./base";

export interface ObjectUserProfile {
    id?: string;
    //PROFILE EDITABLE
    first_name?: string;
    last_name?: string;
    details?: string; // aboutMe information
    cover?: string; // cover if added
    picture?: string; // Image string of profile picture
    origin?: string;
    dob?: Date;
    invite_code?: string;
    //ACCOUNT STUFF
    email?: string;
    phone?:string;
    //TRACKING
    rating?: number; // rating displayed as a float
    verifiedItems?: string[]; // list of string items that are verified (email, phone number, etc)
    created?: Date;
    //Location
    lat?: number;
    lng?: number;
    membership_type?: string;
    //STRIPE
    stripe_customer_id?: string;
    //FIREBASE
    firebase_user_id?: string;
  

    //Hold Anything , i dont think i need this
    [key: string]: any;
  }
  
  export class ObjectUserProfileClass extends BaseModel implements ObjectUserProfile {
    id?: string;
    //PROFILE EDITABLE
    first_name?: string;
    last_name?: string;
    details?: string; // aboutMe information
    cover?: string; // cover if added
    picture?: string; // Image string of profile picture
    origin?: string;
    dob?: Date;
    invite_code?: string;
    //ACCOUNT STUFF
    email?: string;
    phone_number?:string;
    //TRACKING
    rating?: number; // rating displayed as a float
    verifiedItems?: string[] = []; // list of string items that are verified (email, phone number, etc)
    created?: Date;
    membership_type?: string;
    //Location
    lat?: number;
    lng?: number;
    //STRIPE
    stripe_customer_id?: string;
    //FIREBASE
    firebase_user_id?: string;
  
    constructor(data: Partial<ObjectUserProfile> = {}) {
      super(data); // Call the constructor of BaseModel with 'data'
      //PROFILE EDITABLE
      this.id = data.id ?? crypto.randomUUID(); // UUID for id
      this.first_name = data.first_name ?? "";
      this.last_name = data.last_name ?? "";
      this.details = data.details ?? "";
      this.cover = data.cover ?? "";
      this.picture = data.picture ?? "";
      this.origin= data.origin ?? "";
      this.dob=data.dob; //?? new Date();
      this.invite_code = data.invite_code ?? "";
      //ACCOUNT STUFF
      this.email = data.email ?? "";
      this.phone_number = data.phone_number ?? ""
      //TRACKING
      this.rating = data.rating ?? 0;
      this.verifiedItems = data.verifiedItems ?? [];
      this.created = data.created;
      this.membership_type = data.membership_type;
      //LOCATION
      this.lat = data.lat;
      this.lng = data.lng;
      //STRIPE
      this.stripe_customer_id = data.stripe_customer_id;
      //FIREBASE
      this.firebase_user_id = data.firebase_user_id;
    }
  
    // Computed property: Full Name
    get displayFullName(): string {
      return [this.first_name, this.last_name].filter(Boolean).join(" ");
    }
  
    // Computed property: Member Since
    get displayMemberSince(): string {
      if (this.created) {
        const year = this.created.getFullYear();
        return `Member since ${year}`;
      }
      return "Member since -";
    }
  
    // Computed property: Rating display
    get displayRatingDisplay(): string {
      return this.rating && this.rating > 0 ? `${this.rating}★` : "★";
    }
  
    // Computed property: Picture URL
    get displayPictureURL(): string {
      return this.picture ?? "";
    }
  
    // Computed property: Cover URL
    get displayCoverURL(): string {
      return this.cover ?? "";
    }
  
    // Computed property: Origin
    get displayOrigin(): string {
      return this.origin ?? "";
    }
  
    // Computed property: About Details
    get displayDetails(): string {
      return this.details ?? "";
    }
  
    // Computed property: Verified Items
    get displayVerifiedItems(): string[] {
      return this.verifiedItems ?? [];
    }
  
    // Computed property: Time Since Created
    get displayTimeSinceCreated(): string {
      if (!this.created) {
        return "NEW";
      }
  
      const now = new Date();
      const created = this.created;
      const diffTime = now.getTime() - created.getTime();
      const diffYears = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));
      const diffMonths = Math.floor((diffTime % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
      const diffWeeks = Math.floor((diffTime % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24 * 7));
  
      if (diffYears > 0) {
        return `${diffYears} yr${diffYears > 1 ? "s" : ""}`;
      } else if (diffMonths > 0) {
        return diffMonths === 1 ? "NEW" : `${diffMonths} month${diffMonths > 1 ? "s" : ""}`;
      } else if (diffWeeks > 0) {
        return `${diffWeeks} week${diffWeeks > 1 ? "s" : ""}`;
      } else {
        return "NEW";
      }
    }
  }
  