import React, { useState } from 'react';
import styled from 'styled-components';

const InputContainer = ({ children }) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleInputFocus = () => {
        setIsFocused(true);
    };

    const handleInputBlur = () => {
        setIsFocused(false);
    };

    // Clone the children to pass the onFocus and onBlur props to them
    const clonedChildren = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                onFocus: handleInputFocus,
                onBlur: handleInputBlur,
            });
        }
        return child;
    });

    return (
        <Container className={isFocused ? 'inputActive' : 'inputInactive'}>
            {clonedChildren}
        </Container>
    );
};

export default InputContainer;


const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 60px;
    border-radius: 5px;
    cursor: pointer;
    background-color: white;
    border: 1px solid #CCC;
    align-items: center;
    gap: 10px;
    transition: border 0.3s, box-shadow 0.3s;
    padding: 0px 15px;

    &.inputActive {
        border: 1px solid #FBAE62;
        box-shadow: 0 0 10px rgba(251, 174, 98, 0.5);
    }

    input {
        height: 60px;
        border-radius: 20px;
        font-size: 18px;
        width: 100%;
        border: 1px solid transparent;
        padding-left: 25px;
        outline: none;
        transition: border 0.3s;
    }

    // button {
    //     color: black;
    //     background-color: #FBAE6260;
    //     width: auto !important;
    //     height: 50px !important;
    //     padding: 0px 15px;
    //     border-radius: 25px;
    //     margin: 10px;
    //     font-size: 18px;
    //     border: 1px solid transparent;
    //     cursor: pointer;

    //     &:hover {
    //         background-color: #FBAE62;
    //         color: white;
    //     }
    }
`;