import { BaseModel } from "./base";




// const initialState = {
//     isLoggedIn: false,
//     token: false,
//     user_id: null,
//     user_type: null,
// }

export interface AuthObject {
    isLoggedIn?: boolean;
    token?: string;
    user_id?: string;
    user_type?: string;
    expires_at?: Date;
    last_login?: Date;
    [key: string]: any; // Hold anything else
}



export class AuthObjectClass extends BaseModel implements AuthObject
{
    isLoggedIn?: boolean;
    token?: string;
    user_id?: string;
    user_type?: string;
    expires_at?: Date;
    last_login?: Date;

    constructor(data: Partial<AuthObject> = {}) {
        super(data); // Call the constructor of BaseModel with 'data'

        this.isLoggedIn = data.isLoggedIn ?? false;
        this.token = data.token ?? "";
        this.user_id = data.user_id ?? "";
        this.user_type = data.user_type ?? "";
        this.expires_at = data.expires_at;
        this.last_login = data.last_login ?? new Date();
    }

    // Computed property: Is Authenticated
    get displayIsLoggedIn(): boolean {
        return this.isLoggedIn ?? false
    }

    // Computed property: Display User ID
    get displayUserId(): string {
        return this.user_id ?? "";
    }

    get isLoggedInAsUser(): boolean {
        if(this.isLoggedIn==true && this.user_type == 'user')
        {
            return true;
        }
        return false;
    }

    // Computed property: Display Token Expiry
    get displayTokenExpiry(): string {
        if (this.expires_at) {
            const expiryDate = this.expires_at.toLocaleString();
            return `Token expires at ${expiryDate}`;
        }
        return "No expiry date";
    }

    // Computed property: Last Login Time
    get displayLastLogin(): string {
        if (this.last_login) {
            return `Last login: ${this.last_login.toLocaleString()}`;
        }
        return "No login history";
    }

}