import { combineReducers } from 'redux';

import cartReducer from 'third_party/redux/cart/cartReducer'
import signupReducer from 'third_party/redux/signup/signupReducer';
import searchReducer from './search/searchReducer';
import searchFilterReducer from './searchFilter/searchFilterReducer';
import userReducer from './user/userReducer';
import authReducer from './auth/authReducer';

const rootReducer = combineReducers ({
    cart: cartReducer,
    signup: signupReducer,
    search: searchReducer,
    searchFilter: searchFilterReducer,
    user: userReducer,
    auth: authReducer,
})

export default rootReducer