

import styled from 'styled-components';
import { NAVBAR_HEIGHT } from 'components/constants';

const IconNavBarItem = ({isSelected, item, onClick}) => {

    return (
        <IconContainer $isSelected={isSelected} onClick={onClick}>
            <IconImage src={item.image} alt={item.text} />
            <IconText>{item.text}</IconText>
        </IconContainer>
    )
}

export default IconNavBarItem

const IconContainer = styled.div`
display: inline-flex; /* Makes sure each icon container stays inline */
flex-direction: column;
justify-content: center;
align-items: center;
cursor: pointer;
opacity: ${(props) => (props.$isSelected ? 1 : 0.45)};
border-bottom: ${(props) => (props.$isSelected ? '1px solid black' : 'none')};
min-height: calc(NAVBAR_HEIGHT / 2);

&:hover {
    border-bottom: 1px solid #CCC;
}

`

const IconImage = styled.img`
height: 35px;
width: 35px;
padding: 5px;
background-color: transparent;
// opacity: ${(props) => (props.$isSelected ? 1 : 0.50)};

img {
    display: block;
    width: 100%;
}
`
const IconText = styled.p`
font-size: 12px;
font-weight: 100;
color: #000000;
margin: 0px 0px; /* Added margin for spacing */
min-height: 20px;
// opacity: ${(props) => (props.$isSelected ? 1 : 0.50)};

`