
import styled from 'styled-components';
import { NAVBAR_HEIGHT, SECTION_PADDING } from 'components/constants';
import Package from './Package'


const packages = [
    { title: 'Graduation Celebration', items: [{image: 'image1',alt:'image1',text:'test'},{image: 'image1',alt:'image1',text:'test'},{image: 'image1',alt:'image1',text:'test'},{image: 'image1',alt:'image1',text:'test'}], description: 'celebrate your graduation in style'},
    { title: 'Casual Gathering', items: [{image: 'image1',alt:'image1',text:'test'},{image: 'image1',alt:'image1',text:'test'},{image: 'image1',alt:'image1',text:'test'}], description: 'celebrate with friends'},
    { title: 'House Warming', items: [{image: 'image1',alt:'image1',text:'test'},{image: 'image1',alt:'image1',text:'test'}], description: 'Enjoy your house warming'},
    { title: 'Community Event', items: [{image: 'image1',alt:'image1',text:'test'}], description: 'celebrate your community'},
];

const TrendyPackageComponent = (props) => {

return (
    <Container>
        <h1>Trending Packages</h1>
        <PackageContainer>
        {packages.map((item, index) => (
                    <Package key={index} title={item.title} description={item.description} items={item.items} />
        ))}
        </PackageContainer>
    </Container>
    )
}
export default TrendyPackageComponent;


const Container = styled.div`
display: flex;
flex-direction: column;
margin: ${SECTION_PADDING};
max-width:100%;

h1{
    color: black;
    text-align: left;
}
`
const PackageContainer = styled.div`

display: grid;
grid-template-columns: repeat(4, 1fr); /* Three columns, each 1 fraction of the container width */
width: 100%;
overflow-x : scroll;
align-items: start;
justify-content: center;
`

