import styled, {css, keyframes} from 'styled-components';
import { NAVBAR_HEIGHT } from 'components/constants';
import { useAppNavBarContext } from "context/AppNavBarContext"



import SearchFunctions from 'utility/searchFunctions';
import { useSearch } from 'third_party/redux/selectors';


const SearchBarCompact = (props) => {

    const searchCriteria = useSearch().current
    const {openExpandedSearch, isCompactSearchBarVisible } = useAppNavBarContext()
    
    // console.log("InputBarCompact | searchCriteria: ", searchCriteria)
    return (
        <>

        <ExpandedContainer $isvisible={isCompactSearchBarVisible}>
            
            <LeftContainer onClick={() => openExpandedSearch({ location: true })}>
              <SearchElementP>{SearchFunctions.getLine1FromPlace(searchCriteria.location)}</SearchElementP>
            </LeftContainer>

            <CenterContainer onClick={() => openExpandedSearch({when:true})}>
              <SearchElementP>{SearchFunctions.displayAbbreviatedMonthDayTime(searchCriteria.date)}</SearchElementP>
            </CenterContainer>

            <RightContainer>

                <DurationContainer onClick={() => openExpandedSearch({duration:true})}>
                  <SearchElementP>{SearchFunctions.displayDuration(searchCriteria.duration)}</SearchElementP>
                </DurationContainer>

                <SearchButtonContainer>
                    <SearchButton onClick={() => openExpandedSearch()}>
                      <img src="/images/searchicon.png" alt="searchicon" />
                    </SearchButton>
                </SearchButtonContainer>

            </RightContainer>

        </ExpandedContainer>
        </>
    )
}

export default SearchBarCompact

const ExpandedContainer = styled.div`
display: flex;
width: 100%;
animation: ${props => props.$isvisible ? css`${fadeIn} 0.3s forwards` : css`${fadeOut} 0.3s forwards`};
box-shadow: 0 0 1.5px #00000080;
border-radius: 25px;
cursor: pointer;
flex-grow:1;
height: 40px;
max-width: 650px;

> *:not(:last-child) {
  position: relative;
  flex: 1; // Ensure the children expand to fill the container

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 50%;
    width: 1px;
    background-color: #CCCCCC60;
  }
}
&:hover {
    // box-shadow: 0 0 10px rgba(251, 174, 98, 0.5);
    box-shadow: 0 0 5px #00000080;

}
`

const LeftContainer = styled.div`
display: flex;
flex-direction: column;
min-width: calc(100% / 6 * 2);
height: 100%; /*calc(${NAVBAR_HEIGHT});*/
padding: 0px 10px;
justify-content: center;
align-items: center;

`

const CenterContainer = styled.div`
display: flex;
flex-direction: column;
min-width: calc(100% / 6 * 2);
height: 100%; /*calc(${NAVBAR_HEIGHT});*/
padding: 0px 10px;
align-items: center;
justify-content: center;

`

const RightContainer = styled.div`
display: flex;
flex-direction: row;
min-width: calc(100% / 6 * 2);
padding: 0px 10px;
align-items: center;
justify-content: center;
height:100%; /*calc(${NAVBAR_HEIGHT});*/
`

const DurationContainer = styled.div`
display: flex;
flex-direction: column;
min-width: 50%;
align-items: center;
justify-content: center;
flex-grow: 1;
`

const SearchButtonContainer = styled.div`
display: flex;
flex-direction: column;
min-width: 50%;
cursor: pointer;
border-radius: 25px;
align-items: end;
justify-content: center;
height:100%;
flex-shrink: 1;
`

const SearchButton = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: calc(${NAVBAR_HEIGHT}/3*2);
width: calc(${NAVBAR_HEIGHT}/3*2);
background-color: rgba(251, 174, 98, 1.0);
border-radius: 50%;
border: none;

img {
  display: block;
  width:50%;
}
`

const SearchElementP = styled.p`
color: black;
font-size: .875em;
font-weight: 525;
`


const fadeIn = keyframes`
  from {
    opacity: 0;
    display: flex;
    height: calc(${NAVBAR_HEIGHT}/4*3); //was 0 initially
  }
  to {
    opacity: 1;
    height: calc(${NAVBAR_HEIGHT}/4*3);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    height: calc(${NAVBAR_HEIGHT}/4*3);
  }
  to {
    opacity: 0;
    // height: 0;
    display: none;
  }
`;
// animation: ${props => props.$isvisible ? css`${fadeIn} 0.3s forwards` : css`${fadeOut} 0.3s forwards`};