import React, { forwardRef, useState } from 'react';
import styled, {css, keyframes} from "styled-components";

import MyDatePicker from 'components/Inputs/Calendar/InputCalendar';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MultiSectionDigitalClock, DigitalClock } from '@mui/x-date-pickers'; //TimeField


import {useScreenContext } from 'context/AppContext/ScreenContext';

import { format, parse } from 'date-fns';


//ADDED
import { useSearchActions } from 'third_party/redux/search/searchActions';
import { useSearch } from 'third_party/redux/selectors';
import SearchFunctions from 'utility/searchFunctions';


// import { DigitalClock } from '@mui/x-date-pickers';
//https://mui.com/x/react-date-pickers/digital-clock/

import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';


const DateTimeInput = ({isOpen, ...props}) => {



  const {isMobile, isMedium, isLarge} = useScreenContext()

    const tempSearchCriteria = useSearch().temp
    const {SEARCH_TIME, SEARCH_DATE} = useSearchActions()

    const date = tempSearchCriteria.date || new Date()

    // const [selected, setSelected] = useState(date)//useState(new Date());
    const [selectedTime, setSelectedTime] = useState();

    const [isDateSelected, setIsDateSelected]=useState(true)
    const [isTimeSelected, setIsTimeSelected]= useState(false)

    const handleDateSegmentToggled = (e) => {
      e.stopPropagation()
      if(isDateSelected){
        //close
        setIsDateSelected(false)
      }
      else
      {
        //open date
        setIsDateSelected(true)
        setIsTimeSelected(false)
      }
    }

    const handleTimeSegmentToggled = (e) => {
      e.stopPropagation()
      if(isTimeSelected){
        //close
        setIsTimeSelected(false)
      }
      else
      {
        //open date
        setIsTimeSelected(true)
        setIsDateSelected(false)
      }
    }


    const handleDateInputChange = (newDate) => {
      console.log('selectedDate: ',newDate)
      // setSelected(newDate);
      SEARCH_DATE(newDate);
    };

    const handleTimeInputChange = (newTime) =>{
      const parsedTime = parse(newTime, 'h:mm a', new Date());
      setSelectedTime(parsedTime);
      SEARCH_TIME(parsedTime);
    }

    const handleMobileTimeInputChange =(newTime) => {

      console.log("HANDLEMobile TIME INPUT CHANGE: ",newTime)

      // Format the time using dayjs
      const initialFormattedTime = dayjs(newTime).format('hh:mm a');
      const parsedTime = parse(initialFormattedTime, 'h:mm a', new Date());
      setSelectedTime(parsedTime);
      SEARCH_TIME(parsedTime);
    }

    const handleTimeError = (reason, value) => {
      if (reason === 'invalidDate') {
        console.error('Invalid time input');
        // You can handle the error here, e.g., show a notification or set an error state
      }
    };


    const times = SearchFunctions.generateTimes(date);


    return (
        <>
            <Container $isOpen={isOpen} {...props}>
                {/* <h1>HOWDY</h1> */}
              <ToggleSegmentContainer>
                <ToggleSegmentChildDate onClick={(e) => handleDateSegmentToggled(e)} $isDateSelected={isDateSelected}>
                  <p>{SearchFunctions.displayDate(tempSearchCriteria.date)}</p>
                </ToggleSegmentChildDate>
                <ToggleSegmentChildTime  onClick={(e) => handleTimeSegmentToggled(e)} $isTimeSelected={isTimeSelected}>
                  <p>{SearchFunctions.displayTime(tempSearchCriteria.date)}</p>
                </ToggleSegmentChildTime>
              </ToggleSegmentContainer>

              <InputContainer>
              {isDateSelected && 
                <MyDatePicker
                selected={tempSearchCriteria.date}
                setSelected={handleDateInputChange}/>
              } 
              
              {isTimeSelected && 
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {!isMobile &&
                    <TimeContainer>
                      <TimeInnerContainer>
                        {times.map((time, index) => (
                            <TimeContainerChild
                              key={index}
                              $isSelected={time === SearchFunctions.formatTimeToString(tempSearchCriteria.date)}
                              onClick={() => handleTimeInputChange(time)}
                            >
                              {time}
                            </TimeContainerChild>
                          ))}
                        </TimeInnerContainer>
                    </TimeContainer>


                    // <TimePicker 
                    //     defaultValue={dayjs(date)}
                    //     onChange={handleTimeInputChange}
                    //     format = 'hh:mm a'
                    //     onError={handleTimeError}
                    //     onAccept={handleTimeAccept}
                    //     />

                    }
                    {isMobile &&
                    <DisplayedDigitalClock
                    value={dayjs(date)}
                    onChange={handleMobileTimeInputChange}
                    format = 'hh:mm a'
                    onError={handleTimeError}
                    // onAccept={handleTimeAccept}
                    // minutesStep={15}
                    timeSteps={{minutes:30}}
                    />
                    }
                </LocalizationProvider>
              }
              
              </InputContainer>
            </Container>
        </>
    )
}
 export default DateTimeInput


const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: start;
    width: 100%;
    min-height: 75%;
    max-height: 100%;
    background-color: white;
    border-radius: 25px;
    transform: ${(props) => (props.$isOpen ? 'translateX(0)' : 'translateX(100vw)')};  // Update the transform property
    transition: transform 0.3s ease-in-out;
    color: black;
    padding: 10px 10px;
    overflow-y: scroll;
    h1 {
      font-size: 1.5em;
      margin: 0px;
      padding: 0px;
    }
      p{
      margin: 0px;
      padding: 0px;
      }

    ${(props) =>
        props.$isOpen
          ? css`
              animation: ${fadeIn} 0.5s forwards;
              display:flex;
            `
          : css`
              animation: ${fadeOut} 0.3s forwards;
              animation-delay: 0s; /* No delay for fade out */
              display: none;
              opacity:0;
            `}   
`

// KEYFRAMES
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;


const Stack = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px; /* This is equivalent to spacing={2} where 1 spacing unit is 8px */
  margin-top: 24px; /* This is equivalent to mt={3} where 1 margin unit is 8px */
`;

const InputContainer = styled.div`
display: flex;
flex: 1 1;
width: 100%;
height: auto;
justify-content: center;
overflow-y: scroll;
`
const ToggleSegmentContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
width: 100%;
gap: 15px;
margin: 15px 0px;
`

const ToggleSegmentChildDate = styled.div`
padding: 15px 20px;
background-color: ${(props) => (props.$isDateSelected ? '#CCCCCC' : '#CCCCCC60')};  // Update the transform property
color: ${(props) => (props.$isDateSelected ? 'black' : '#00000060')};  // Update the transform property
box-shadow: ${(props) => (props.$isDateSelected ? '0 2px 5px -1px rgba(251, 174, 98, .60)' : 'none')};  // Update the transform property

cursor: pointer;
border-radius: 15px;
font-size: 1.em;

`

const ToggleSegmentChildTime = styled.div`
box-shadow: 0 2px 5px -1px #CCCCCC40;
padding: 15px 20px;
background-color:  #CCCCCC60;
cursor: pointer;
border-radius: 15px;
font-size: 1.0em;
background-color: ${(props) => (props.$isTimeSelected ? '#CCCCCC' : '#CCCCCC60')};  // Update the transform property
color: ${(props) => (props.$isTimeSelected ? 'black' : '#00000060')};  // Update the transform property
box-shadow: ${(props) => (props.$isTimeSelected ? '0 2px 5px -1px rgba(251, 174, 98, .60)' : 'none')};  // Update the transform property

`

const DisplayedDigitalClock = styled(MultiSectionDigitalClock)`
width: auto !important;
`


const TimeContainer = styled.div`
display: flex;
flex-direction: row;
// align-items: center;
// justify-content: center;
width: 100%;
padding: 0px 10% 0px 10%;
height:100%;
padding-bottom: 24px;
`

const TimeInnerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);  // Create 3 equal-width columns
  gap: 15px;  // Space between grid items
  width: 100%;
`;

const TimeContainerChild = styled.div`
  padding: 15px 20px;
  background-color: ${(props) => (props.$isSelected ? 'rgba(251, 174, 98, 1.0)' : 'transparent')};
  color: ${(props) => (props.$isSelected ? 'white' : '#00000060')};
  box-shadow: ${(props) => (props.$isSelected ? '0 2px 5px -1px rgba(251, 174, 98, .60)' : 'none')};
  border: 1px solid #CCC;
  cursor: pointer;
  border-radius: 15px;
  font-size: 1em;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;  // Ensure content inside the box is centered
`;